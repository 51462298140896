/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    arrayOf,
    object, string, shape,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import { useUIDSeed } from 'react-uid';
import LinkOrATag from '../../LinkOrATag/LinkOrATag';

const styles = (theme) => ({
    mainMediaLogoConatiner: {
        width: '100%',
        padding: '0px 0px 30px 0px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    mediaLogo: {
        width: '20%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down(769)]: {
            width: 'auto',
        },
    },

});

const NewsMediaLogo = ({
    classes, blockData,
}) => {
    const mediaLogoData = blockData?.media_group_logo || [];
    const seed = useUIDSeed();

    // renderLogo image if Url return link else only image
    const renaderLogoImage = (logoData) => (
        logoData?.link?.href ? (
            <LinkOrATag href={logoData?.link?.href}>
                <img src={logoData?.image?.url} alt={logoData?.image?.title} />
            </LinkOrATag>
        ) : <img src={logoData?.image?.url} alt={logoData?.image?.title} />
    );

    return (
        <Grid container>
            <Grid
                item
                xs={12}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.mainMediaLogoConatiner}>
                            {mediaLogoData?.map((logo) => (
                                <div key={seed(logo)} className={classes.mediaLogo}>
                                    {renaderLogoImage(logo)}
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

NewsMediaLogo.propTypes = {
    classes: object.isRequired,
    blockData: shape({
        title: string,
        media_group_logo: arrayOf(shape({
            link: shape({
                href: string,
                title: string,
            }),
            image: shape({
                url: string,
                title: string,
            }),
        })),
    }),
};
NewsMediaLogo.defaultProps = {
    blockData: {},
};
export default withStyles(styles)(NewsMediaLogo);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    productDetailsTabPanelRoot: {
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        backgroundColor: '#F7F7F7',
    },
    productDetailsCopy: {
        maxWidth: '75%',
        margin: '0 auto',
        padding: '5% 0',
        textAlign: 'center',
        fontFamily: 'PlayfairDisplayRegular, serif',
        fontSize: '1em',
        color: '#000',
        listStylePosition: 'inside',
        [theme.breakpoints.down(700)]: {
            padding: '10% 0',
        },
        '& ul': {
            margin: '10px 0px',
            padding: 'revert',
        },
    },
    productDetailsPartNumber: {
        fontFamily: 'PlayfairDisplayBold, serif',
    },
}));

const ProductDetailsTabPanel = ({ data }) => {
    const classes = useStyles();

    const { longDescription, shortDescription } = data;

    return (
        <div className={classes.productDetailsTabPanelRoot}>
            <div className={classes.productDetailsCopy}>
                <ReactMarkdown
                    source={shortDescription}
                    escapeHtml={false}
                />
                {data?.partNumber && (
                    <div className={classes.productDetailsPartNumber}>
                        {`Product Details: ${data?.partNumber}`}
                    </div>
                )}
                <ReactMarkdown
                    source={longDescription}
                    escapeHtml={false}
                />
            </div>
        </div>
    );
};

ProductDetailsTabPanel.propTypes = {
    data: object,
};

ProductDetailsTabPanel.defaultProps = {
    data: {},
};

export default ProductDetailsTabPanel;

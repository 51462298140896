/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    object, number, array,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import ProductDetailsTabPanel from './GraphqlProductDetailsUIMarketplaceTabPanels/ProductDetailsTabPanel';
import FAQsTabPanel from './GraphqlProductDetailsUIMarketplaceTabPanels/FAQsTabPanel';
import MeetYourSellerTabPanel from './GraphqlProductDetailsUIMarketplaceTabPanels/MeetYourSellerTabPanel';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1440px',
        height: 'auto',
        margin: '0 auto',
    },
    tabContainerDesktop: {
        maxWidth: '100%',
        height: 'auto',
        margin: '0 auto',
    },
    tabContainerMobile: {
        maxWidth: '700px',
        height: 'auto',
        margin: '0 auto',
    },
    tabTitles: {
        width: '100%',
        height: 'auto',
        justifyContent: 'space-between',
        '& button': {
            backgroundColor: '#E3E3E3',
            opacity: '1',
            minHeight: '60px',
            maxWidth: '100%',
            flex: '1 0 0',
            marginRight: '2px',
            fontWeight: 'bold',
        },
        '& button:last-child': {
            marginRight: '0',
        },
        '& .MuiTab-textColorInherit.Mui-selected': {
            backgroundColor: '#F7F7F7',
        },
    },
    tabIndicator: {
        height: '9px',
        top: 0,
        backgroundColor: theme.palette.common.blue || '#371A6E',
    },
}));

function TabPanel(props) {
    const {
        value, index, tab,
    } = props;

    if (value !== index) {
        return null;
    }

    const tabTitle = tab?.title;

    if (tabTitle === 'product details') {
        return (
            <ProductDetailsTabPanel data={tab?.data} />
        );
    }
    if (tabTitle === 'faqs') {
        return (
            <FAQsTabPanel data={tab?.data} />
        );
    }
    if (tabTitle === 'meet your seller') {
        return (
            <MeetYourSellerTabPanel recommendedProducts={tab?.data?.recommendedProducts} sellerShopData={tab?.data?.sellerShopData} sellerTabContent={tab?.data?.sellerTabContent} />
        );
    }

    return null;
}

TabPanel.propTypes = {
    value: number.isRequired,
    index: number.isRequired,
    tab: object.isRequired,
};

const GraphqlProductDetailsUIMarketplace = ({
    faqBlock, product, recommendedProducts, productBaseContent,
}) => {
    const classes = useStyles();
    const seed = useUIDSeed();

    const isPdpTabMYSEnabled = useSelector((state) => getFeatureFlag('is-about-seller-tab-enabled')(state));
    const isPdpTabFAQEnabled = useSelector((state) => getFeatureFlag('is-pdp-tab-faq-enabled')(state));

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // meet your seller details
    // product.productSkus[0].marketplaceShopDetails: {
    //     shopName: string,
    //     description: string,
    //     banner: string,
    // }
    const mysData = {
        recommendedProducts: (recommendedProducts && recommendedProducts.length) ? recommendedProducts : [],
        sellerShopData: product?.productSkus?.[0]?.marketplaceShopDetails || {},
        sellerTabContent: productBaseContent?.entries?.[0]?.marketplace_tab_panel_data?.meet_your_seller || {},
    };

    const  allTabsData = [
        {
            title: 'product details',
            data: product,
        },
        {
            title: 'meet your seller',
            data: (isPdpTabMYSEnabled && (mysData?.recommendedProducts || mysData?.sellerShopData || mysData?.sellerTabContent)) ? mysData : {},
        },
        {
            title: 'faqs',
            data: isPdpTabFAQEnabled ? faqBlock : {},
        },
    ];

    const allTabsDataFiltered = allTabsData.filter((tab) => Object.keys(tab.data).length > 0);

    return (
        <div className={classes.root}>
            <div className={classes.tabContainerDesktop}>
                <Tabs value={value} onChange={handleChange} classes={{ flexContainer: classes.tabTitles, indicator: classes.tabIndicator }}>
                    {allTabsDataFiltered.map((tab) => (<Tab key={seed(tab)} label={tab.title} />))}
                </Tabs>
                {allTabsDataFiltered.map((tab, idx) => (
                    <TabPanel key={seed(tab)} value={value} index={idx} tab={tab} />
                ))}
            </div>
        </div>
    );
};

GraphqlProductDetailsUIMarketplace.defaultProps = {
    faqBlock: {},
    product: {},
    recommendedProducts: [],
    productBaseContent: {},
};

GraphqlProductDetailsUIMarketplace.propTypes = {
    faqBlock: object,
    product: object,
    recommendedProducts: array,
    productBaseContent: object,
};

export default GraphqlProductDetailsUIMarketplace;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const GraphqlContentPageBodyStyle = ({ palette, breakpoints }) => ({
    content: {
        flexGrow: 1,
        backgroundColor: (palette.white || '#fff'),
        '& blockquote': {
            borderLeft: `solid 4px ${(palette.colorDisabled || '#ccc')}`,
            marginLeft: '.3em',
            paddingLeft: '1.3em',
        },
        // heading
        '& h1': {
            fontSize: '38px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& h6': {
            fontSize: '18px',
            fontWeight: '600',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '& h5': {
            fontSize: '24px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: '600',
        },
        '& ul': {
            marginLeft: '40px',
        },
        '& li': {
            fontSize: '16px',
        },
        '& li:first-letter': {
            textTransform: 'capitalize',
        },
        '& ol': {
            marginLeft: '40px',
        },
        maxWidth: '1400px',
        margin: '10px auto',
    },
    leftBlock: {
        margin: '14px',
    },
    centerBlock: {
        margin: '14px',
    },
    rightBlock: {
        margin: '14px 5px',
    },
    imocContainer: {
        margin: '0 auto',
        padding: '0 15px',
        maxWidth: '1400px',
    },
    marketPlaceImocContainer: {
        margin: '0 auto',
        padding: '0px',
        maxWidth: '1600px',
    },
    [breakpoints?.down('md')]: {
        content: {
            '& h1': {
                fontSize: '28px',
            },
            '& h5': {
                fontSize: '16px',
                fontWeight: '600',
            },
            padding: '10px',
            margin: '15px auto',
        },
        leftBlock: {
            margin: '0px 3px',
        },
        centerBlock: {
            margin: '0px 3px',
        },
        rightBlock: {
            margin: '0px 3px',
        },
    },
    [breakpoints?.only('xs')]: {
        content: {
            padding: '10px 0',
        },
    },
    setPaddingBreadcrumb: {
        padding: '0 15px',
        margin: '15px auto 0px auto',
        [breakpoints?.only('xs')]: {
            paddingLeft: '5px',
            paddingRight: '5px',
        },
    },
    containerStyle: {
        [breakpoints?.down('xs')]: {
            padding: 0,
            margin: 0,
        },
    },
    marketPlaceBreadCrumb: {
        '& $content': {
            padding: '0px 3px',
            maxWidth: '1600px',
        },
    },
});

export default GraphqlContentPageBodyStyle;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const MuiTabMenuStyle = ({ palette, breakpoints }) => ({
    headTitle: {
        padding: '8px 12px',
        fontSize: '16px',
        backgroundColor: (palette.cms?.leftNavigationHeaderBgColor || palette.primary?.main || '#2f2f2f'),
        color: (palette.common?.white || '#fff'),
        fontWeight: '800',
        margin: '0px 3px 4px 0px',
    },
    tabs: {
        '& button': {
            maxWidth: '98.7% !important',
            '&.Mui-selected': {
                backgroundColor: (palette?.cms?.['Dark Green'] || '#5f7664'),
                color: (palette.cms?.leftSelectedNavFontColor || '#fff'),
                '& span': {
                    fontWeight: '800',
                },
            },
            '& span': {
                fontSize: '14px',
                alignItems: 'start',
            },
            backgroundColor: (palette?.cms?.['Light Beige'] || '#f5f4ee'),
            color: (palette.cta1 || '#2f2f2f'),
            opacity: 1,
            marginBottom: '2px',
            textTransform: 'none',
            '&:not(.Mui-selected):hover': {
                backgroundColor: (palette.grey8 || '#cccccc'),
            },
        },
        '& .MuiTabs-indicator': {
            backgroundColor: (palette?.cms?.['Dark Green'] || '#5f7664'),
        },
    },
    [breakpoints?.down('md')]: {
        tabs: {
            '& .MuiTabs-indicator': {
                display: 'none',
            },
            '& button': {
                maxWidth: '100% !important',
            },
        },
        headTitle: {
            margin: '0px 0px 4px 0px',
        },
    },
});

export default MuiTabMenuStyle;

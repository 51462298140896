/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import { string } from 'prop-types';

import mbpLogger from 'mbp-logger';
// import { Link } from 'react-router-dom/cjs/react-router-dom';
import { GRAPHQL_ENV } from '../../../gql';
import Eventbrite from './EventBrite';

const useStyles = makeStyles(() => ({
    body: {
        background: '#3c3c35',
        width: '100%',
        maxWidth: '1280px',
        margin: '1em auto 5rem',
        padding: '5px',
    },
    row: {

    },
    mainImage: {
        height: 'fit-content',
        width: '50%',
        aspectRatio: '63/74',
        '@media screen and (max-width: 1080px)': {
            display: 'none',
        },
    },
    eventInfo: {
        color: '#ebebdf',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        padding: '2rem',
        width: '100%',
        textAlign: 'center',
        '@media screen and (max-width: 1080px)': {
            padding: '1rem',
        },
    },
    eventInfoBottom: {
        color: '#ebebdf',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        padding: '0 2rem',
        textAlign: 'center',
        width: '100%',
        fontSize: '1.5rem',
        '& a': {
            color: '#ebebdf',
        },

    },
    eventInfoContainer: {
        display: 'flex',
        alignContent: 'center',
        padding: '0 0 0.3rem',
        '& h1': {
            margin: '1rem auto 0',
        },
        '& p': {
            margin: '0.4rem auto',
        },

    },
    eventInfoContainerBottom: {
        display: 'flex',
        alignContent: 'center',
        '& p': {
            margin: '0.4rem auto',
        },
        '@media screen and (max-width: 900px)': {
            flexDirection: 'column',
        },
    },
    venueLocation: {
        lineHeight: '1.1em',
        margin: '0 0 1em 0',
    },
    venueLogoPair: {
        display: 'flex',
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        '& div': {
            display: 'block',
            borderLeft: 'solid 1px #ebebdf',
            width: '1px',
            height: '80px',
            margin: '0 30px',
        },
        '& img': {
            width: '100%',
            maxWidth: '150px',
        },
    },
    headingBreak: {
        backgroundColor: '#ebebdf',
        color: '#3c3c35',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '0.3rem 0',
        fontSize: '1.4rem',
    },
    eventbrite: {
        height: '520px',
        margin: '5px auto',
        backgroundColor: '#59594f',
    },
    finePrint: {
        display: 'block',
        textAlign: 'center',
        padding: '0.3rem 0',
        fontSize: '.7rem',
        color: '#ebebdf',
    },
    fullWidth: {
        width: '100%',
    },
    menu: {
        display: 'block',
        width: '65%',
        margin: '1em auto',
        '@media screen and (max-width: 1024.5px)': {
            width: '85%',
        },
        '@media screen and (max-width: 768.5px)': {
            width: '100%',
        },
    },
}));

const Event = ({ path }) => {
    const classes = useStyles();

    const HOSTEDDINNER_QUERY = gql`
    query findContentPage {
        findContentPage(brand: "harryanddavid", contentType: "hosted_dinners_event", environment: "${GRAPHQL_ENV}", locale: "en-us", url: "${path}") {
            content
        }
    }
`;
    return (
        <Query query={HOSTEDDINNER_QUERY}>
            {({
                loading, error, data,
            }) => {
                if (loading) {
                    return <></>; // skeleton component
                }
                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: HOSTEDDINNER_QUERY,
                        component: 'Event.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });

                    return null;
                }
                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: HOSTEDDINNER_QUERY,
                        component: 'Event.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                const eventData = data.findContentPage.content.entries?.[0];
                return (
                    <div className={classes.body}>
                        <div className={classes.row}>
                            <div className={classes.eventInfoContainer}>
                                {eventData.images.main.url && <img className={classes.mainImage} src={eventData.images.main.url} alt="Example dish" />}
                                <div className={classes.eventInfo}>
                                    <div className={classes.venueLogoPair}><img src="https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/bltdf6728c89a818e50/HD_hostedinner_white.svg" alt="Hosted Dinner Logo" />{ eventData.images.logo?.url && <><div vertline /><img src={eventData.images.logo.url} alt={`Harry and David Hosted Dinners at ${eventData.venue.venue_name}`} /></>}</div>
                                    <h1>{eventData.venue.venue_name}</h1>
                                    <address className={classes.venueLocation}>
                                        {eventData.venue.venue_space}<br />
                                        {eventData.venue.venue_street}<br />
                                        {eventData.venue.venue_city_state}
                                    </address>
                                    {(eventData.event_information.event_date && eventData.event_information.event_time) && (
                                        <p className={classes.eventDateTime}>
                                            {eventData.event_information.event_date}<br />
                                            {eventData.event_information.event_time}
                                        </p>
                                    )}
                                    {ReactHtmlParser(eventData.event_information.event_description)}
                                </div>
                            </div>
                        </div>
                        { eventData.video?.video?.url?.length && (
                            <div className={classes.row}>
                                <video data-testid="video-box" poster={eventData.video.placeholder_image?.url} autoPlay controls loop width="100%" height="100%" className={classes.objectFit}>
                                    <source src={eventData.video.video?.url} type={eventData.video.video?.content_type} preload="metadata" />
                                    <track kind="captions" />
                                </video>
                            </div>
                        )}
                        {eventData.event_information.eventbrite_information?.length && (
                            <div className={classes.row}>
                                <div className={classes.headingBreak}>RESERVE TICKETS HERE</div>
                                <div id="eventbrite" data-ce-key="1508" className={classes.eventbrite}><Eventbrite url={eventData.event_information.eventbrite_information} /></div>
                            </div>
                        )}
                        { (eventData.images.menu?.url.length || eventData.images.recipe_card?.url.length) && (
                            <div className={classes.row}>
                                <div className={classes.headingBreak}>MENU BY {eventData.event_information.chefs_name}</div>
                                {eventData.images.menu?.url.length && <img src={eventData.images.menu.url} alt="Event Menu" className={classes.menu} />}
                                {eventData.images.recipe_card?.url.length && <img src={eventData.images.recipe_card.url} alt="Event Recipe Card" className={classes.fullWidth} />}
                            </div>
                        )}
                        { eventData.images.location?.url && (
                            <div className={classes.row}>
                                <div className={classes.eventInfoContainerBottom}>
                                    <img className={classes.venueImage} src={eventData.images.location.url} alt="Venue Image" />
                                    <div className={classes.eventInfoBottom}>
                                        <p className={classes.eventDateTime}>
                                            {eventData.event_information.event_date}<br />
                                            {eventData.event_information.event_time}
                                        </p>
                                        {/* <p><Link to="#eventbrite">RESERVE NOW &gt;</Link></p> */}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={classes.row}>
                            <span className={classes.finePrint}>
                                {eventData.event_information.fine_print}
                            </span>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

Event.propTypes = {
    path: string.isRequired,
};

export default Event;

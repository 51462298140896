/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, func, bool,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import Copy from '../../Copy';
// import parseMarkdown from '../../../../../../helpers/contentstack/parseMarkdown';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';

const preStyle = {
    test: {
        transition: 'all .3s',
        '&:hover': {
            opacity: '.9',
        },
    },
};

const styles = (theme) => ({
    test: preStyle.test,
    threeUp: {
        margin: '2-0px auto',
        display: 'flex',
        justifyContent: 'space-between',
    },
    panel: {
        height: '500px',
        width: '24.75%',
        textDecoration: 'none',
    },
    headline: {
        margin: `0 auto ${theme.spacing(3)}px auto`,
        color: theme.palette.common.black, // TODO: Font color should come from CMS
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    '25/25/50': {
        '& > a:last-of-type': {
            width: '49.75%',
        },
    },
    '25/50/25': {
        '& > a:nth-child(even)': {
            width: '49.75%',
        },
    },
    '50/25/25': {
        '& > a:first-of-type': {
            width: '49.75%',
        },
    },
    Even: {
        '& > a': {
            width: '32.75%',
        },
    },
    Standard: {},
    Stacked: {
        height: '670px',
        maxWidth: '1440px',
        minWidth: '980px',
        margin: '10px auto',
        flexDirection: 'column',
        flexWrap: 'wrap',
        '& > a:first-of-type': {
            flex: '1',
            alignSelf: 'flex-start',
            marginBottom: '.65%',
        },
        '& > a': {
            width: '49.35%',
            height: '670px',
        },
        '& > a:nth-child(-n+2)': {
            height: '330px',
        },
        '& > a:last-of-type': {
            marginLeft: '.35%',
            width: '49%',
        },
    },
    'Stacked-Reverse': {
        height: '670px',
        maxWidth: '1440px',
        minWidth: '980px',
        margin: '10px auto',
        flexDirection: 'column',
        flexWrap: 'wrap',
        '& > a:first-of-type': {
            alignSelf: 'flex-start',
        },
        '& > a': {
            width: '49.75%',
            height: '670px',
        },
        '& > a:nth-child(n+2)': {
            height: '330px',
        },
    },
});

const ThreeUpDesktop = ({
    classes, data, trackEvent, isBot,
}) => (

    <div className={`${classes.threeUp} ${classes[data.reference[0].panel_widths]} ${classes[data.reference[0].layout_type]}`}>
        {
            data.reference[0].panel_row[0].panel.reference.map((i, index) => (

                <Link
                    key={i?.linking?.link?.href}
                    to={i?.linking?.link?.href}
                    title={i?.linking?.link?.title}
                    className={`${classes.panel} ${classes.test}`}
                    data-ga-category={i?.linking?.tracking_event_category || ''}
                    data-ga-action={i?.linking?.tracking_event_action || ''}
                    data-ga-label={i?.linking?.tracking_event_label || ''}
                    id={`ThreeUp_Spot-${index + 1}`}
                    style={{
                        backgroundImage: !isBot && `url(${i?.image?.url}?auto=webp)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        padding: '20px',
                        boxSizing: 'border-box',
                    }}
                    onClick={() => {
                        trackEvent({
                            eventCategory: i?.linking?.tracking_event_category || '',
                            eventAction: i?.linking?.tracking_event_action || '',
                            eventLabel: i?.linking?.tracking_event_label || '',
                        });
                    }}
                >
                    {isBot && <img src={`${i?.image?.url}?auto=webp`} alt={i?.image?.title} />}
                    <Copy className={classes.headline} data={i?.message_collection} styling={i.message_styling !== undefined ? i.message_styling : ['']} />
                </Link>
            ))
        }
    </div>
);

ThreeUpDesktop.propTypes = {
    data: object.isRequired,
    classes: object.isRequired,
    trackEvent: func.isRequired,
    isBot: bool.isRequired,
};
const mapStateToProps = (state) => ({
    isBot: getIsBot(state),
});
const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(ThreeUpDesktop));

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { object, string, shape } from 'prop-types';
import ReactMarkdown from 'react-markdown';

const styles = (theme) => ({
    footerContainer: {
        backgroundColor: theme.palette?.pdp?.subscription?.subtable || '#f7eed7',
        maxWidth: '1400px',
        margin: '0',
        fontSize: '16px',
        width: '100%',
    },
    innerContainer: {
        margin: '5px auto',
    },
    logo: {
        '& img': {
            maxWidth: '300px',
            margin: '0 auto',
        },
        textAlign: 'center',
        width: '100%',
    },
    footerCopy: {
        textAlign: 'center',
        margin: '10px',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px',
    },
    socialLink: {
        margin: '0 5px',
    },
    icon: {
        width: '50px',
        height: '50px',
        transition: 'all .3s',
        '&:hover': {
            filter: 'drop-shadow(0 3px 3px #9d8751)',
        },
    },
});

const ShareMoreFooter = ({ data, classes }) => {
    const footerParagraph = data?.copy || null;
    const socialLinks = data.social_links_share_more || '';
    return (
        <div className={classes.footerContainer}>
            <div className={classes.innerContainer}>
                <div className={classes.footerCopy}>
                    <ReactMarkdown source={footerParagraph} escapeHtml={false} />
                </div>
                <div className={classes.iconContainer}>
                    {socialLinks && socialLinks.social_link_group.map((socLink) => {
                        const getLinkName = socLink.url?.title;
                        return (
                            <a className={classes.socialLink} href={socLink.url.href} target="_blank" rel="noreferrer" key={getLinkName}>
                                <img className={classes.icon} src={socLink.icon.url} alt={`Social Icon ${getLinkName}`} />
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

ShareMoreFooter.propTypes = {
    classes: object.isRequired,
    data: shape({
        copy: string,
        social_links_share_more: object,
    }),
};
ShareMoreFooter.defaultProps = {
    data: {},
};
export default withStyles(styles)(ShareMoreFooter);

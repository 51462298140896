/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, shape } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';

import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    alertMessageContainer: (alertData) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        color: theme.palette.primary,
        backgroundColor: alertData?.background_color || '#CCC',
        marginBottom: '15px',
    }),
    dateCopy: {
        // date styling
        margin: 0,
        fontSize: '18px',
        [theme.breakpoints.down(500)]: {
            fontSize: '16px',
        },
    },
    alertIconContainer: {
        marginRight: '40px',
        '& img': {
            width: '100px',
            display: 'block',
        },
        [theme.breakpoints.down(500)]: {
            display: 'none',
        },
    },
    alertMessageCopy: (alertData) => ({
        color: alertData?.text_color || '#000',
        '& h1,& h2,& h3,& h4,& h5,& h6,& p': {
            margin: 0,
            fontSize: '18px',
            fontWeight: 'normal',
            [theme.breakpoints.down(500)]: {
                fontSize: '16px',
            },
        },
        '& ul': {
            paddingLeft: 0,
            marginTop: '10px',
            marginBottom: 0,
            [theme.breakpoints.down(500)]: {
                paddingLeft: '16px',
            },
        },
        '& ul > li': {
            fontSize: '14px',
            marginBottom: '3px',
        },
    }),
    alertLink: (alertData) => ({
        color: alertData?.text_color,
        marginTop: '10px',
        display: 'block',
        fontWeight: 'bold',
        fontSize: '14px',
    }),
}));

const AlertMessageUI = ({ alertData }) => {
    const classes = useStyles(alertData);
    const today = new Date();
    const dateCopy = format(today, 'EEEE, MMMM do');
    return (
        <div className={classes.alertMessageContainer}>
            <div className={classes.alertIconContainer}>
                {alertData?.alert_icon ? <img src={alertData.alert_icon?.url} alt="" /> : <InfoIcon />}
            </div>
            <div>
                {alertData?.show_date && <h2 className={classes.dateCopy}>{dateCopy}</h2>}
                <ReactMarkdown
                    source={alertData?.alert_copy}
                    className={classes.alertMessageCopy}
                    escapeHtml={false}
                />
                {alertData?.link?.title && alertData?.link?.href && (
                    <Link to={alertData.link.href} className={classes.alertLink}>{alertData.link.title}</Link>
                )}
            </div>
        </div>
    );
};

AlertMessageUI.propTypes = {
    alertData: shape({
        alert_icon: object.isRequired,
        link: object.isRequired,
    }).isRequired,

};

export default AlertMessageUI;

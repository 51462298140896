/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, bool, number, shape, string,
} from 'prop-types';
import { withRouter } from 'react-router';
import { useUIDSeed } from 'react-uid';
import NewsandMediaBlock from './NewsandMediaBlock';

const NewsMediaPage = ({ data }) => {
    const newsMediaData = data?.reference?.[0]?.modular_blocks || [];
    const { media_block }  = newsMediaData?.find((item) => item?.media_block) || {};
    const { news_media_banner } = newsMediaData?.find((item) => item?.news_media_banner) || {};
    const seed = useUIDSeed();
    return (
        newsMediaData
            ? newsMediaData?.map((blockData) => (
                <NewsandMediaBlock
                    block={blockData}
                    key={seed(blockData)}
                    videoBlock={media_block}
                    bannerLeftText={news_media_banner}
                />
            ))
            : null
    );
};

NewsMediaPage.propTypes = {
    data: shape({
        reference: arrayOf(shape({
            title: string,
            modular_blocks: arrayOf(shape({
                news_media_banner: shape({
                    title: string,
                    news_block: shape({
                        copy: string,
                        font_family: string,
                    }),
                }),
                media_block: shape({
                    autoplay: bool,
                    heading: bool,
                    height: number,
                    video_references: arrayOf(shape({
                        show_controls: bool,
                        video_id: string,
                    })),
                }),
            })),
        })),
    }),
};

NewsMediaPage.defaultProps = {
    data: {},
};

export default withRouter(NewsMediaPage);

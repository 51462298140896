/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-props-no-spreading */

// TODO: Add option for modal player 'play_location === "modal"'

import React, { useState } from 'react';
import {
    string, object, bool, shape,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import VideoModalBlock from './Partials/VideoModal';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = () => ({
    container: {
        position: 'relative',
        margin: '7px auto',
    },
    container_no_play: {
        position: 'relative',
        margin: '7px 0px',
        '&::after': {
            content: '"\u25B6"',
            position: 'absolute',
            color: 'inherit',
            top: 'calc(50% - 46px)',
            left: '0',
            right: '0',
            margin: '0 auto',
            width: '92px',
            height: '92px',
            border: 'solid 7px',
            borderRadius: '50%',
            fontWeight: '700',
            fontSize: '60px',
            paddingLeft: '19px',
        },
        color: 'rgba(247, 238, 215, .8)',
        '&:hover': {
            color: 'rgba(247, 238, 215, 1) !important',
        },
    },
    playButton: {
        position: 'absolute',
        color: 'rgba(255,255,255,.9)',
        margin: '18% 43%',
        fontSize: '150px',
    },
    playButtonShadow: {
        position: 'absolute',
        color: 'rgba(0,0,0,.5)',
        margin: '18.15% 43.15%',
        fontSize: '150px',
    },
    player: {
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    no_fullscreen: {
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        '&::-webkit-media-controls-fullscreen-button': {
            display: 'none !important',
        },
    },
});

export function HTML5Video({
    classes, data, autoplay, width, height, play_location, tracking,
}) {
    const {
        image,
        video_id,
    } = data;
    const dispatch = useDispatch();
    const posterUrl = image?.url || '';
    const ieBrowser = /* @cc_on!@ */false || (typeof document !== 'undefined' ? !!document.documentMode : false);
    const [IsPlaying, setIsPlaying] = useState(autoplay);
    const toggle = (vid) => {
        if (vid.paused) {
            setIsPlaying(true);
            vid.play();
        } else {
            setIsPlaying(false);
            vid.pause();
        }
    };
    const onVideoEnd = (vid) => {
        if (vid.ended) {
            setIsPlaying(false);
        }
    };

    const checkPlay = () => {
        let retClass = classes.container;
        if (!IsPlaying) {
            retClass = classes.container_no_play;
        }
        return retClass;
    };
    return (
        video_id ? (
            <div style={{
                position: 'relative',
                overflow: 'hidden',
                maxWidth: `${width}px`,
                maxHeight: `${height}px`,
                cursor: 'pointer',
            }}
            >
                <div className={!data?.show_controls && play_location.toLowerCase() === 'in place' ? (checkPlay()) : (classes.container)}>
                    {play_location.toLowerCase() === 'in place'
                        ? (
                            <video
                                key={video_id}
                                controls={data?.show_controls}
                                autoPlay={autoplay}
                                poster={posterUrl}
                                className={data?.allow_fullscreen ? classes.player : classes.no_fullscreen}
                                onClick={ieBrowser || data?.show_controls === false ? ({ target: video }) => (toggle(video)) : null}
                                onEnded={({ target: video }) => (onVideoEnd(video))}
                                onPlay={() => {
                                    dispatch(trackEvent({
                                        eventCategory: tracking?.tracking_event_category || '',
                                        eventAction: tracking?.tracking_event_action || '',
                                        eventLabel: tracking?.tracking_event_label || '',
                                    }));
                                }}
                            >
                                <source src={video_id} type="video/mp4" preload="metadata" />
                                <source src={video_id} codecs="H.264/MPEG-4" preload="metadata" />
                            </video>
                        )
                        : (
                            <VideoModalBlock image={data?.image}>
                                <video
                                    key={video_id}
                                    controls={data?.show_controls}
                                    autoPlay={autoplay}
                                    poster={posterUrl}
                                    className={data?.allow_fullscreen ? classes.player : classes.no_fullscreen}
                                    onClick={ieBrowser || data?.show_controls === false  ? ({ target: video }) => (video.paused ? video.play() : video.pause()) : null}
                                    onPlay={() => {
                                        dispatch(trackEvent({
                                            eventCategory: tracking?.tracking_event_category || '',
                                            eventAction: tracking?.tracking_event_action || '',
                                            eventLabel: tracking?.tracking_event_label || '',
                                        }));
                                    }}
                                >
                                    <source src={video_id} type="video/mp4" preload="metadata" />
                                    <source src={video_id} codecs="H.264/MPEG-4" preload="metadata" />
                                </video>
                            </VideoModalBlock>
                        )}
                </div>
            </div>
        ) : <>Missing Video Source</>
    );
}

HTML5Video.propTypes = {
    data: shape({
        allow_fullscreen: bool,
        show_controls: bool,
        type: string,
        video_id: string,
        image: shape({
            url: string,
            title: string,
        }),
    }).isRequired,
    play_location: string,
    autoplay: bool,
    width: string,
    height: string,
    classes: object.isRequired,
    tracking: shape({
        tracking_event_category: string,
        tracking_event_action: string,
    }),
};

HTML5Video.defaultProps = {
    play_location: 'In Place',
    autoplay: false,
    width: '100%',
    height: '100%',
    tracking: {},
};

export default withStyles(styles)(HTML5Video);

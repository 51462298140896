/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ThreeUpMobile from './Mobile/ThreeUpMobile';
import ThreeUpDesktop from './Desktop/ThreeUpDesktop';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import uniqueId from '../../../helpers/contentstack/uniqueId';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';

const styles  = (theme) => ({
    block: {
        position: 'relative',
        margin: '0 auto 32px',
        [theme.breakpoints.down(1024)]: {
            margin: '0 auto 16px',
        },
    },
});

const ThreeUpRow = ({ data: blockData, classes }) => {
    const { data, loading } = useUIDQueryConditionally({
        data: blockData,
        includeRefs: ['panel_row.panel.reference'],
    });

    const deviceType = data.reference?.[0]?.device;
    if (data.reference?.[0].panel_row?.[0].panel?.reference?.[0]) {
        const dataUniqueId = uniqueId(data.reference[0]?.uid || data.reference[0]?.unique_selector);
        const cmsuid = data.reference[0]?.uid || null;

        return (
            <div className={`threeup_block ${classes.block}`} data-uniqueid={dataUniqueId} cmsuid={cmsuid}>
                <DesktopMobile
                    desktop={() => {
                        if ((loading) && blockData?.desktop_skeleton) {
                            return <GenericSkeleton height={blockData?.skeleton_height} />;
                        }
                        return ((deviceType === 'Desktop' || deviceType ===  'Both') ? <ThreeUpDesktop data={data} /> : null);
                    }}
                    mobile={() => {
                        if ((loading) && blockData?.mobile_skeleton) {
                            return <GenericSkeleton height={blockData?.mobile_skeleton_height || blockData?.skeleton_height} />;
                        }
                        return ((deviceType === 'Mobile' || deviceType === 'Both') ? <ThreeUpMobile data={data} /> : null);
                    }}
                />
            </div>
        );
    }
    return <></>;
};

ThreeUpRow.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
};

export default withStyles(styles)(ThreeUpRow);

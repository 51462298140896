/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const ContentPageBlockStyle = ({ palette, breakpoints }) => ({
    // button
    button: {
        fontWeight: '600',
        '& span': {
            padding: '3px',
        },
    },
    buttonCenter: {
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    markdown: {
        width: '100%',
        '& p': {
            margin: '10px 0px',
        },
        '& a': {
            color: (palette.primary?.main || '#2f2f2f'),
        },
        '& h1, h2, h3, h4, h5, h6': {
            margin: '5px 0px',
        },
        '& hr': {
            marginBottom: '18px',
        },
    },
    markdownUl: {
        '& ul': {
            margin: '10px',
            lineHeight: '24px',
            paddingLeft: '24px',
            '& li': {
                margin: '10px 0px',
                fontSize: '16px',
            },
        },
        '& a': {
            color: (palette.primary?.main || '#2f2f2f'),
        },
        '& img': {
            width: '100%',
        },
    },
    markdownTable: {
        '&': {
            overflowX: 'auto',
        },
        '& h1, h2, h3, h4, h5, h6': {
            margin: '10px 0px',
        },
        '& table, th, td': {
            border: `1px solid ${(palette.grey4 || '#7c7c70')}`,
            padding: '16px',
        },
        '& table': {
            width: '100%',
            borderSpacing: '0',
            borderCollapse: 'collapse',
            '& thead': {
                '& tr': {
                    backgroundColor: (palette.cms?.tableHeaderBgColor || palette.primary?.main || '#2f2f2f'),
                    '& th': {
                        color: (palette.cms?.tableHeaderFontColor || palette.primary?.contrastText || '#ffffff'),
                        textAlign: 'center',
                        fontSize: '14px',
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        '&:first-child': {
                            minWidth: '15vw',
                        },
                    },
                },
            },
            '& tbody': {
                padding: '14px 0 7px 0',
                textAlign: 'center',
                '& tr': {
                    '&:nth-of-type(even)': {
                        backgroundColor: (palette.colorNeutral30 || '#eee'),
                    },
                    '& th': {
                        textAlign: 'center',
                        padding: '14px 0 9px 0',
                    },
                    '& td': {
                        fontSize: '14px',
                        textAlign: 'center',
                        padding: '14px 0 9px 0',
                    },
                },
            },
        },
    },
    borderT: {
        borderTop: `1px solid ${(palette.common?.black || '#000')}`,
        paddingTop: '23px',
    },
    pageBreak: {
        marginBottom: '20px',
    },
    imocMainContainer: {
        padding: '15px 0px',
        '& h1': {
            fontSize: '24px !important', // Override CSS to contentpage for IMOC
        },
    },
    [breakpoints?.down('md')]: {
        button: {
            width: '100%',
            margin: '12px 0px',
            padding: '4px 11px',
        },
        borderT: {
            borderTop: `1px solid ${(palette.text?.disabled || '#B7B7B7')}`,
            padding: '28px 0px 19px 0px',
        },
        markdown: {
            '& hr': {
                margin: '12px 0px 18px 0px',
            },
        },
        pageBreak: {
            marginBottom: '10px',
        },
    },
    [breakpoints?.up('sm')]: {
        button: {
            fontSize: '16px',
            minWidth: '161px',
        },
    },
    tableTitle: {
        fontWeight: 900,
        fontSize: '0.67em',
    },
    table: {
        border: `1px solid ${(palette.grey4 || '#7c7c70')}`,
    },
    tableBody: {
        '&:nth-of-type(even)': {
            backgroundColor: (palette.colorNeutral30 || '#eee'),
        },
    },
    tableHead: {
        backgroundColor: (palette.cms?.tableHeaderBgColor || palette.primary?.main || '#2f2f2f'),
    },
    tableCellHead: {
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: '700',
        textAlign: 'center',
        color: (palette.cms?.tableHeaderFontColor || palette.primary?.contrastText || '#ffffff'),
        border: `1px solid ${(palette.grey4 || '#7c7c70')}`,
    },
    tableCell: {
        fontSize: '14px',
        textAlign: 'center',
        padding: '14px 0 7px 0',
        border: `1px solid ${(palette.grey4 || '#7c7c70')}`,
    },
    setMainImageConatiner: {
        padding: '0px 0px 10px 0px',
    },
    [breakpoints?.down('xs')]: {
        imageStyle: {
            width: '100% !important',
        },
    },
    mainCopyContainer: {
        width: '100%',
        '& p': {
            margin: '10px 0px',
        },
        '& a': {
            color: (palette.primary?.main || '#2f2f2f'),
        },
        '& h1, h2, h3, h4, h5, h6': {
            margin: '5px 0px',
        },
        '& hr': {
            marginBottom: '18px',
        },
    },
    liveChat: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px',
        gap: '15px',
        width: '100%',
        height: '80px',
        background: '#FFFFFF',
        border: '1px solid #7C7D72',
        textDecoration: 'none',
        color: 'black',
        boxShadow: '0 3px 3px rgba(0, 0, 0, 0.2)',
        fontSize: '15px',
        '& svg': {
            fill: `${palette.bgChatMessage}`,
        },
        '& img': {
            width: '40px',
            height: '40px',
            [breakpoints.down('xs')]: {
                width: '30px',
                height: '30px',
            },
        },
        '& div': {
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    arrow: {
        right: '0',
        color: palette.cms?.primary || palette.colorPrimary,
        fontSize: '1.125rem',
        [breakpoints.down('xs')]: {
            bottom: 0,
        },
    },
    chatIcon: {
        flex: 'none',
        order: 0,
        flexGrow: 0,
        top: '1.78px',
        position: 'relative',
    },
});

export default ContentPageBlockStyle;

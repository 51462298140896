/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { GRAPHQL_ENV } from '../../../../gql';
import GraphqlProductDetailsUIMarketplace from './GraphqlProductDetailsUIMarketplace';
import findProductsByPartNumber from '../../../../gql/queries/findProductsByPartNumber';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const MarketplaceProductDetailsQuery = ({
    faqBlock, product, brand, productBaseContent,
}) => {
    if (product.recommendedMarketplaceProducts && product.recommendedMarketplaceProducts.length) {
        const MARKETPLACE_RECOMMENDED_PRODUCTS_QUERY = findProductsByPartNumber(
            brand.domain,
            product.recommendedMarketplaceProducts,
        );

        return (
            <Query
                query={MARKETPLACE_RECOMMENDED_PRODUCTS_QUERY}
            >
                {({ loading, error, data }) => {
                    if (loading) {
                        return null;
                    }

                    if (error) {
                        mbpLogger.logError({
                            query: MARKETPLACE_RECOMMENDED_PRODUCTS_QUERY,
                            component: 'MarketplaceProductDetailsQuery.js',
                            message: 'Error loading data from Graphql',
                            env: GRAPHQL_ENV,
                            error,
                        });
                        return null;
                    }

                    if (!data || !data.findProductByPartNumbers || !Array.isArray(data.findProductByPartNumbers)) {
                        mbpLogger.logError({
                            query: MARKETPLACE_RECOMMENDED_PRODUCTS_QUERY,
                            component: 'MarketplaceProductDetailsQuery.js',
                            message: 'No data returned for query',
                            env: GRAPHQL_ENV,
                        });
                        return null;
                    }

                    return (
                        <GraphqlProductDetailsUIMarketplace faqBlock={faqBlock} product={product} recommendedProducts={data.findProductByPartNumbers} productBaseContent={productBaseContent} />
                    );
                }}
            </Query>
        );
    }

    return (
        <GraphqlProductDetailsUIMarketplace faqBlock={faqBlock} product={product} />
    );
};

MarketplaceProductDetailsQuery.defaultProps = {
    faqBlock: {},
    product: {},
    productBaseContent: {},
};

MarketplaceProductDetailsQuery.propTypes = {
    faqBlock: object,
    product: object,
    brand: object.isRequired,
    productBaseContent: object,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

export default connect(mapStateToProps, null)(MarketplaceProductDetailsQuery);

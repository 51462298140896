/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    arrayOf, func, object, shape, string,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { useUIDSeed } from 'react-uid';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';

const styles = (theme) => ({
    mainContainerBanner: {
        marginTop: 20,
    },
    bannerLeftImage: {
        position: 'relative',
        '& img': {
            width: '100%',
            display: 'block',
        },
    },
    leftText: {
        position: 'absolute',
        color: theme.palette.cms?.white || '#fff',
        width: '70%',
        left: 0,
        margin: '0 auto',
        right: 0,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
        '& h2,h3,h4': {
            fontSize: 30,
            marginBottom: 0,
        },
        '& p': {
            marginTop: 0,
        },
    },
});

const HostedDinnerBanners = ({
    classes, bannerData, ssrDeviceType, setFontFamily,
}) => {
    if (!bannerData) {
        return null;
    }
    // Use for to check the deviceType
    const checkDeviceType = ssrDeviceType === 'mobile';
    const seed = useUIDSeed();

    return (
        <Grid container className={classes.mainContainerBanner}>
            {bannerData?.map((item) => {
                const setStyleForText = {};
                if (item?.position === 'Top') {
                    setStyleForText.top = 15;
                    if (checkDeviceType) {
                        setStyleForText.top = 30;
                    }
                }
                if (item?.position === 'Bottom') {
                    setStyleForText.bottom = 70;
                    if (checkDeviceType) {
                        setStyleForText.bottom = 40;
                    }
                }
                return (
                    <Grid key={seed(item)} item xs={12} lg={6}>
                        <div className={classes.leftBanner} style={setFontFamily(item?.font_family)}>
                            <div className={classes.bannerLeftImage}>
                                <div className={classes.leftImage}>
                                    <img src={item?.banner_image?.url} alt={item?.heading} />
                                </div>
                                <div className={classes.leftText} style={setStyleForText}>
                                    <ReactMarkdown source={item?.heading} escapeHtml={false} />
                                    <ReactMarkdown source={item?.sub_heading} escapeHtml={false} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                );
            })}

        </Grid>
    );
};

HostedDinnerBanners.propTypes = {
    classes: object.isRequired,
    setFontFamily: func.isRequired,
    bannerData: arrayOf(shape({
        heading: string,
        sub_heading: string,
        position: string,
        banner_image: shape({
            url: string,
            title: string,
        }),
        link: shape({
            link: shape({
                href: string,
                title: string,
            }),
        }),
    })),
    ssrDeviceType: string.isRequired,
};
HostedDinnerBanners.defaultProps = {
    bannerData: [],
};
const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),

});
export default connect(mapStateToProps)(withStyles(styles)(HostedDinnerBanners));

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    string, bool, shape,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { getSSRDeviceType } from '../../../../../../../state/ducks/App/App-Selectors';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

const useStyles = makeStyles((theme) => ({
    hwsWrapper: {
        maxWidth: '1600px',
        margin: '0 auto',
    },
    responsiveHeroImage: {
        width: '100%',
        display: 'block',
    },
    hwsGridContainer: {
        margin: '0px auto',
        display: 'block',
        position: 'relative',
    },
    hwsCopyContainer: ({ styles }) => ({
        textAlign: 'center',
        transform: 'translate(-50%, -50%)',
        top: styles.positionTop || '50%',
        left: '50%',
        position: 'absolute',
        width: '55%',
        '& h2': {
            margin: '0 auto',
        },
        [theme.breakpoints.down(600)]: {
            width: '90%',
        },
    }),
    hwsCopyTitle: ({ styles }) => ({
        minWidth: '300px',
        color: styles.titleColor || '#000000',
        fontSize: styles.titleFontSize || '2.2em',
        fontFamily: 'Playfair, serif',
        textTransform: 'uppercase',
        letterSpacing: styles.titleLetterSpacing || '1px',
        '& p': {
            margin: '10px auto',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '1.2em',
        },
    }),
    hwsCopySubtitle: ({ styles }) => ({
        minWidth: '300px',
        width: '30%',
        color: styles.subtitleColor || '#000000',
        fontSize: styles.subtitleFontSize || '0.9em',
        margin: '0 auto',
        [theme.breakpoints.down(1000)]: {
            width: '45%',
        },
        [theme.breakpoints.down(750)]: {
            width: '75%',
        },
        [theme.breakpoints.down(600)]: {
            width: '100%',
        },
    }),
    hwsCopyCTA: ({ styles }) => ({
        display: 'inline-block',
        backgroundColor: styles.ctaBgColor || '#ffffff',
        color: styles.ctaCopyColor || '#000000',
        padding: '1% 5%',
        margin: '10px auto 0',
        fontWeight: '600',
        letterSpacing: '2px',
        textDecoration: styles.ctaTextDecoration || 'none',
        textTransform: styles.ctaTextTransform || 'uppercase',
    }),
}));

const HeroWithSvg = ({ data, ssrDeviceType }) => {
    if (!data) {
        return null;
    }
    const isMobile = ssrDeviceType === 'mobile';

    const styles = {
        positionTop: data?.main_copy?.position_top,
        titleColor: data?.main_copy?.title_color?.color,
        titleFontSize: data?.main_copy?.title_font_size,
        titleLetterSpacing: data?.main_copy?.title_letter_spacing,
        subtitleColor: data?.main_copy?.subtitle_color?.color,
        subtitleFontSize: data?.main_copy?.subtitle_font_size,
        ctaCopyColor: data?.main_copy?.cta?.copy_color?.color,
        ctaBgColor: data?.main_copy?.cta?.background_color?.color,
        ctaTextDecoration: data?.main_copy?.cta?.text_decoration,
        ctaTextTransform: data?.main_copy?.cta?.text_transform,
    };
    const classes = useStyles({ styles });

    const imageData = isMobile ? data?.main_images.mobile_image : data?.main_images?.image;
    const svgImageData = isMobile ? data?.main_copy.mobile_svg : data?.main_copy?.svg;

    const copyData = data?.main_copy;
    const CTAData = data?.main_copy?.cta;
    return (
        <div className={classes.hwsWrapper}>
            <Grid container className={classes.hwsGridContainer}>
                {imageData && (
                    <ResponsiveImage
                        className={classes.responsiveHeroImage}
                        path={imageData?.url}
                        alt={copyData?.title || 'Hero With Svg Banner'}
                    />
                )}
                {copyData?.title && (
                    <div className={classes.hwsCopyContainer}>
                        {(svgImageData?.url) &&  (
                            <Grid item xs={12}>
                                <ResponsiveImage
                                    className={classes.responsiveHeroImage}
                                    path={svgImageData?.url}
                                    alt={copyData?.title || 'Hero With Svg Banner'}
                                />
                            </Grid>
                        )}
                        <ReactMarkdown className={classes.hwsCopyTitle} source={copyData?.title} escapeHtml={false} />
                        <h3 className={classes.hwsCopySubtitle}>{copyData?.subtitle}</h3>
                        {CTAData?.link?.title && <span className={classes.hwsCopyCTA}>{CTAData?.link?.title}</span>}
                    </div>
                )}
            </Grid>
        </div>
    );
};

HeroWithSvg.propTypes = {
    data: shape({
        main_images: {
            image: {
                url: string,
                title: string,
            },
            mobile_image: {
                url: string,
                title: string,
            },
        },
        main_copy: {
            copy_background_color: {
                color: string,
            },
            svg: {
                url: string,
                title: string,
            },
            mobile_svg: {
                url: string,
                title: string,
            },
            position_top: string,
            title: string,
            title_font_size: string,
            title_color: shape({
                color: string,
            }),
            title_letter_spacing: string,
            subtitle: string,
            subtitle_font_size: string,
            subtitle_color: shape({
                color: string,
            }),
            cta: shape({
                is_ga_trackevent_enabled: bool,
                link: shape({
                    title: string,
                    href: string,
                }),
                font_size: string,
                font_weight: string,
                text_decoration: string,
                text_transform: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
            }),
        },
    }),
    ssrDeviceType: string.isRequired,
};

HeroWithSvg.defaultProps = {
    data: {},
};

const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
});

export default connect(mapStateToProps, null)(HeroWithSvg);

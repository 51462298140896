/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    string, shape,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import {
    Button,
    FormControl, FormHelperText, Input, InputLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Joi from 'joi-browser';
import mbpUtil from 'mbp-api-util';
import ReCAPTCHA from 'react-google-recaptcha';
import EventDispatcher from '../../../../../pages/Account/state/reducers/EventDispatcher';
import { hideLabelWithAssistiveTech } from '../../../../../helpers/common/helper';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { isEmpty } from '../../../../../pages/Account/utils/object';
import { formObj, schema } from '../../helper';
import configENV from '../../../../../pages/Account/config';

const APP_GOOGLE_RECAPCHA_KEY = mbpUtil.getEnv('APP_GOOGLE_RECAPCHA_KEY') ? mbpUtil.getEnv('APP_GOOGLE_RECAPCHA_KEY') : '6Lc9wiEUAAAAAFqvKqm7Etmli7bL5rT_HscAOBAU';
const { PERSIST_KEY } = configENV;
const callBackUrl = '/sellers-form';

const useStyles = makeStyles((theme) => ({

    formContainer: (formBackgroundColor) => ({
        width: '100%',
        margin: '30px auto 0',
        backgroundColor: formBackgroundColor.color || '#EFEBF3',

        [theme.breakpoints.down(600)]: {
            maxWidth: '600px',
            margin: '0 auto',
            padding: '20px 0 30px 0',
        },
    }),
    formWrapper: {
        maxWidth: '950px',
        padding: '3% 0 8% 0',
        display: 'flex',
        margin: '0 auto',

        [theme.breakpoints.down(600)]: {
            maxWidth: '90%',
            display: 'block',
            padding: '0',
        },
    },

    formHeaderContainer: {
        flex: '0 0 30%',
        paddingRight: '5%',
        textAlign: 'left',

        [theme.breakpoints.down(600)]: {
            padding: '0 0 15px',
            textAlign: 'center',
        },
    },
    formTitle: {
        fontFamily: 'PlayfairDisplayBold, serif',
        fontSize: '2em',
        marginBottom: '15px',
    },
    formDescription: {
        fontFamily: 'latolight, serif',
        fontSize: '1em',
    },

    formFieldsContainer: {
        flex: '0 0 70%',
        textAlign: 'left',

        '& .Mui-disabled': {
            backgroundColor: theme.palette.grey?.['300'] || '#E0E0E0',
        },
    },
    formFields: {
        width: '100%',
    },

    formControl: {
        width: '100%',
        '& input': {
            border: 'none',
            minHeight: '35px',
            backgroundColor: theme.palette.cms?.white || '#ffffff',
            padding: '0',
        },
        [theme.breakpoints.down(600)]: {
            minHeight: '50px',
        },
    },

    fieldsLabel: {
        fontFamily: 'latolight, serif',
        margin: '13px auto 5px 0',
        '& p': {
            margin: '0 auto',
        },
        '& > div': {
            backgroundColor: theme.palette.cms?.white || '#ffffff',
        },
    },
    fieldsLabelBottom: {
        fontFamily: 'latolight, serif',
        margin: '5px auto',

    },
    recaptcha: {
        margin: '13px auto 5px 0',
    },
    nameContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '& div:nth-child(1)': {
            flex: '0 0 45%',
        },
        '& div:nth-child(2)': {
            flex: '0 0 45%',
        },

        [theme.breakpoints.down(600)]: {
            display: 'block',
        },
    },
    nameField: {
        width: '100%',
    },

    numberOfEmployees: {
        width: '10%',
        backgroundColor: theme.palette.cms?.white || '#ffffff',

        [theme.breakpoints.down(600)]: {
            width: '18%',
        },
    },
    location: {
        width: '45%',
        [theme.breakpoints.down(600)]: {
            width: '60%',
        },
    },
    howDidYouHearAboutUs: {
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        padding: '0',
        '& textarea': {
            minHeight: '80px ',
        },
    },
    whatProductsAreYouBestKnownFor: {
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        padding: '0',
        '& textarea': {
            minHeight: '80px ',
        },
    },
    doYouCurrentlyDropShip: {
        width: '9%',
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        marginBottom: '25px',
        '& input': {
            minHeight: '20px ',
        },
        [theme.breakpoints.down(600)]: {
            width: '15%',
        },
    },
    tellUsYourStoryAndALittleBitMoreAboutYourCompany: {
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        padding: '0',
        '& textarea': {
            minHeight: '80px',
        },
    },

    ctaButton: {
        width: '15%',
        backgroundColor: theme.palette.cms?.black || '#000000',
        color: theme.palette.cms?.white || '#ffffff',
        margin: '20px auto 0',
        letterSpacing: '1px',
        borderRadius: '0',

        [theme.breakpoints.down(600)]: {
            width: '100%',
            margin: '30px auto 0',
        },
        '&:hover': {
            backgroundColor: theme.palette.cms?.black || '#000000',
        },
    },
    results: {
        width: '100%',
        margin: '0 auto 0 0',
    },
    failedSubmission: {
        textTransform: 'capitalize',
        color: theme.palette.colorAlert,
    },
    successfulSubmission: {
        textTransform: 'capitalize',
        color: theme.palette.colorSuccess,
    },
    ariaInvisible: () => hideLabelWithAssistiveTech(),
}));

const MarketplaceFormBlock = ({
    data,
}) => {
    const isSellersFormCaptchaEnabled = useSelector(getFeatureFlag('is-sellers-form-captcha-enabled'));
    const marketPlaceStatus = useSelector((state) => (state[PERSIST_KEY] ? state[PERSIST_KEY]?.marketplaceStatus : state?.marketplaceStatus));

    const {
        background_color: formBackgroundColor, header, description, form_fields,
    } = data;

    useEffect(() => {
        // add recaptcha if is-sellers-form-captcha-enabled true
        if (isSellersFormCaptchaEnabled) {
            formObj.recaptcha = null;
            schema.recaptcha = Joi.string().required().label('Recaptcha');
        }
    }, [isSellersFormCaptchaEnabled]);

    const [formData, setFormData] = useState(formObj);
    const [errors, setErrors] = useState({});
    const [formApplication, setFormApplication] = useState(false);

    const dispatch = useDispatch();
    const fetchMarketplaceFormData = (formdata) => dispatch(EventDispatcher.updateMarketplaceFormData(formdata));

    const validateCaptcha = (value, callBackURL) => dispatch(EventDispatcher.validateCaptcha(value, callBackURL));

    const classes = useStyles(formBackgroundColor);

    const validateProperty = ({ name, value }) => {
        // name propety is required to validate the schema
        if (!name) return null;
        const obj = { [name]: value };
        const localSchema = { [name]: schema[name] };
        // eslint-disable-next-line no-underscore-dangle
        const { _refs } = localSchema[name];
        if (_refs && data[_refs[0]] === value) {
            return null;
        }
        const { error } = Joi.validate(obj, localSchema);
        return error ? error.details[0].message : null;
    };

    const handleChangeRecap = (value) => {
        // validate captcha token server side
        try {
            validateCaptcha(value, callBackUrl);
        } catch (ex) {
            // error handling
        }
        const newInput = {};
        newInput.name = 'recaptcha';
        newInput.value = value;

        const newErrors = { ...errors };
        const errorMessage = validateProperty(newInput);
        if (errorMessage) {
            newErrors[newInput.name] = errorMessage;
        } else {
            delete newErrors[newInput.name];
        }
        setErrors(newErrors);
        setFormData((prevState) => ({ ...prevState, ...{ [newInput.name]: value } }));
    };

    const handleFormUpdate = (field, event) => {
        const newInput = {};
        newInput.name = field;
        newInput.value = event;

        const newErrors = { ...errors };
        const errorMessage = validateProperty(newInput);
        if (errorMessage) {
            newErrors[newInput.name] = errorMessage;
        } else {
            delete newErrors[newInput.name];
        }
        setErrors(newErrors);
        setFormData((prevState) => ({ ...prevState, ...{ [field]: event } }));
    };

    const missingRequiredFields = () => {
        let hasMissingFieldsStatus = false;

        for (let i = 0; i < form_fields.length; i += 1) {
            if (form_fields[i].field.required_field) {
                if (formData[`${form_fields[i].field.field_key}`]?.length === 0) {
                    hasMissingFieldsStatus = true;
                    const newErr = {};
                    newErr[form_fields[i].field.field_key] = 'This field is required';
                    setErrors((prevState) =>  ({
                        ...prevState,
                        ...newErr,
                    }));
                }
            }
        }
        return hasMissingFieldsStatus;
    };

    const submitApplication = (e) => {
        e.preventDefault();

        if (missingRequiredFields()) return;
        if (!isEmpty(errors)) return;

        // Handled recaptcha error after form input errors. More intuitive
        if (isEmpty(errors) && formData['recaptcha'] === null) {
            setErrors((prevState) =>  ({
                ...prevState,
                recaptcha: 'Please complete the Captcha to proceed.',
            }));
            return;
        }
        fetchMarketplaceFormData(formData);
        setFormApplication(true);
    };

    return (
        <div className={classes.formContainer}>
            <div className={classes.formWrapper}>
                <div className={classes.formHeaderContainer}>
                    <div className={classes.formTitle}>{header}</div>
                    <ReactMarkdown className={classes.formDescription} source={description} escapeHtml={false} />
                </div>

                <div className={classes.formFieldsContainer}>
                    <div className={classes.formFields}>
                        <FormControl className={classes.formControl}>
                            <div className={classes.nameContainer}>
                                {form_fields.slice(0, 2).map((elem) => {
                                    const {
                                        field_key, field_copy,
                                    } = elem?.field;
                                    return (
                                        <div key={field_copy}>
                                            {/* Form Part 1 - TODO: render 1, 2 in single map */}
                                            <ReactMarkdown className={classes.fieldsLabel} source={field_copy} escapeHtml={false} />
                                            <InputLabel
                                                htmlFor={field_key}
                                                className={classes.ariaInvisible}
                                                shrink
                                            >
                                                {field_key}
                                            </InputLabel>
                                            <Input
                                                disabled={formApplication}
                                                disableUnderline
                                                className={(field_key === 'firstName' || field_key === 'lastName') ? classes.nameField : ''}
                                                autoComplete="off"
                                                multiline={false}
                                                id={field_key}
                                                name={field_key}
                                                type="text"
                                                value={formData[field_key]}
                                                onChange={(e) => handleFormUpdate(`${field_key}`, e.target.value)}
                                            />
                                            {errors[field_key] ? (
                                                <FormHelperText className={`form-helper-text ${errors[field_key] ? 'Mui-error' : ''}`}>{errors[field_key]}</FormHelperText>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            {form_fields.slice(2, 19).map((elem) => {
                                const {
                                    field_key, field_copy, multiline_min_height_desktop, multiline_min_height_mobile,
                                } = elem?.field;
                                const specificFieldStyle = classes[field_key];

                                return (
                                    <>
                                        {/* Form Part 2 */}
                                        <ReactMarkdown className={classes.fieldsLabel} source={field_copy} escapeHtml={false} />
                                        <InputLabel
                                            htmlFor={field_key}
                                            className={classes.ariaInvisible}
                                            shrink
                                        >
                                            {field_key}
                                        </InputLabel>
                                        <Input
                                            disabled={formApplication}
                                            disableUnderline
                                            className={(field_key === 'firstName' || field_key === 'lastName') ? classes.nameField : specificFieldStyle}
                                            autoComplete="off"
                                            multiline={multiline_min_height_desktop || multiline_min_height_mobile}
                                            id={field_key}
                                            name={field_key}
                                            type="text"
                                            value={formData[field_key]}
                                            onChange={(e) => handleFormUpdate(`${field_key}`, e.target.value)}
                                        />
                                        {errors[field_key] ? (
                                            <FormHelperText className={`form-helper-text ${errors[field_key] ? 'Mui-error' : ''}`}>{errors[field_key]}</FormHelperText>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                );
                            })}
                            <div>
                                {
                                    isSellersFormCaptchaEnabled ? (
                                        <>
                                            <ReCAPTCHA
                                                defaultChecked
                                                sitekey={APP_GOOGLE_RECAPCHA_KEY}
                                                onChange={(value) => handleChangeRecap(value)}
                                                tabindex="0"
                                                size="normal"
                                                className={classes.recaptcha}
                                            />
                                            {errors['recaptcha'] ? (
                                                <FormHelperText className={`form-helper-text ${errors['recaptcha'] ? 'Mui-error' : ''}`}>{errors['recaptcha']}</FormHelperText>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ) : null
                                }
                            </div>
                        </FormControl>
                    </div>
                    <Button
                        disabled={formApplication}
                        className={classes.ctaButton}
                        type="submit"
                        onClick={submitApplication}
                    >
                        submit
                    </Button>

                    <div className={classes.results}>
                        {!isEmpty(errors) && (
                            <FormHelperText className="form-helper-text Mui-error">{errors['recaptcha'] ? '' : 'Please fill all required fields'}</FormHelperText>
                        )}
                        {formApplication && (
                            <div className={marketPlaceStatus?.responseCode === '200' ? classes.successfulSubmission : classes.failedSubmission}>
                                <p>{marketPlaceStatus?.responseMessage}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    );
};

MarketplaceFormBlock.propTypes = {
    data: shape({
        header: string.isRequired,
        description: string.isRequired,
    }).isRequired,
};

export default MarketplaceFormBlock;

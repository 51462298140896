/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    arrayOf,
    object,
    string,
    shape,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { useUIDSeed } from 'react-uid';

const styles = (theme) => ({
    mainNewsCopyConatiner: {
        width: '100%',
        padding: '30px 0px 30px 0px',
    },
    mainCopyHeading: {
        margin: '5px 0',
    },
    subCopyHeading: {
        maxWidth: 900,
        width: '100%',
        margin: '0 auto',
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: '5px 0',
        },
        '& a': {
            color: theme.palette.cms?.linkColor || '#dd3337',
        },
    },
});

const NewsMediaCopy = ({
    classes, blockData,
}) => {
    const seed = useUIDSeed();
    const copyContentData = blockData?.copy_block || [];
    let headingStyle = {};
    let subHeadingStyle = {};
    let copyBackGroundColor = {};

    // copy styling from CMS
    headingStyle = {
        fontSize: blockData?.heading_font_size || 38,
        color: blockData?.heading_color?.color || '#000000',
    };
    subHeadingStyle = {
        color: blockData?.sub_heading_font_color?.color || '#999999',
        fontSize: blockData?.sub_heading_font_size || 16,
    };
    copyBackGroundColor = {
        textAlign: blockData?.text_alignment || 'center',
        backgroundColor: blockData?.show_background ? blockData?.background_color?.color : 'transparent',
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <div style={copyBackGroundColor} className={classes.mainNewsCopyConatiner}>
                            {copyContentData?.map((item) => (
                                <div key={seed(item)}>
                                    <h3 style={headingStyle} className={classes.mainCopyHeading}>{item?.heading}</h3>
                                    <div style={subHeadingStyle}><ReactMarkdown className={classes.subCopyHeading} source={item?.sub_heading} escapeHtml={false} /></div>
                                </div>
                            ))}

                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

NewsMediaCopy.propTypes = {
    classes: object.isRequired,
    blockData: shape({
        background_color: shape({
            color: string,
        }),
        copy_block: arrayOf(shape({
            heading: string,
            sub_heading: string,
        })),
    }),
};
NewsMediaCopy.defaultProps = {
    blockData: {},
};
export default withStyles(styles)(NewsMediaCopy);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, func,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent as track } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { ifExternalUrl } from '../NUp/helper';

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const useStyles = makeStyles((theme) => ({
    block: {
        position: 'relative',
        margin: '0 auto 32px',
        [theme.breakpoints.down(1024)]: {
            margin: '0 auto 16px',
        },
    },
    headline: (styles) => ({
        fontFamily: parseFont(styles.message?.heading_font),
        width: 'max-content',
        marginBottom: 20,
        fontSize: 50,
        color: styles.message?.copy_color.color,
        '@media screen and (max-width: 1025px)': {
            width: '70%',
            marginBottom: 10,
            fontSize: 40,
        },
        '@media screen and (max-width: 350px)': {
            lineHeight: 1,
        },
    }),
    headlineShort: {
        '@media screen and (max-width: 1025px)': {
            width: 'max-content',
            fontSize: 40,
            marginBottom: 20,
        },
        '@media screen and (max-width: 601px)': {
            width: '70%',
            lineHeight: 1.2,
        },
    },
    copy: (styles) => ({
        width: 'max-content',
        whiteSpace: 'break-spaces',
        marginBottom: 20,
        fontFamily: parseFont(styles.message?.heading_font),
        color: styles.message?.copy_color.color,
    }),
    ctaLink: {
        textDecoration: 'none',
        padding: '12px 15px',
        backgroundColor: '#ffffffdd',
        width: 'max-content',
        color: '#1f1f1b',
    },
    contentSectionWrapper: {
        margin: '0 auto',
        height: 615,
        width: 'auto',
        '@media screen and (max-width: 601px)': {
            height: 'auto',
            width: '100%',
            minHeight: 380,
        },
    },
    contentSectionWrapperShort: {
        maxWidth: 1350,
        height: 400,
        '@media screen and (max-width: 601px)': {
            maxWidth: 380,
            minHeight: 350,
            height: 'auto',
            width: '100%',
        },
    },
    contentSection: {
        bottom: 100,
        position: 'absolute',
        zIndex: 2,
        marginLeft: 70,
        '@media screen and (max-width: 1025px)': {
            bottom: 60,
        },
        '@media screen and (max-width: 601px)': {
            marginLeft: 20,
            bottom: 20,
        },
        '@media screen and (max-width: 350px)': {
            marginLeft: 10,
        },
    },
    contentSectionShort: {
        bottom: 30,
        marginLeft: '5%',
    },
    tearSection: {
        margin: '0 auto',
        display: 'block',
        minHeight: 270,
    },
    tearSectionShort: {
        maxHeight: 320,
        '@media screen and (max-width: 601px)': {
            maxHeight: 'max-content',
        },
    },
    backgroundFiller: (styles) => ({
        height: '100%',
        '@media screen and (max-width: 601px)': {
            height: '70%',
        },
        backgroundColor: styles.background_accent_color.color,
        clipPath: 'url(#tear)',
    }),
    backgroundFillerShort: {
        height: '70%',
    },
    fixedMessageWidth: {
        maxWidth: '1440px',
    },
    bannerV2Root: {
        display: 'block',
        margin: '0 auto',
        zIndex: 1,
        position: 'relative',
        padding: '70px 70px 30px 0',
        '@media screen and (max-width: 601px)': {
            padding: '50px 30px 0 0',
        },
    },
    bannerV2RootShort: {
        padding: '60px 70px 0 0',
        '@media screen and (max-width: 601px)': {
            padding: '50px 30px 0 0',
        },
    },
    bannerV2Wrapper: {
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        maxWidth: 1350,
        maxHeight: 715,
        margin: '0 auto',
        '& > div': {
            position: 'absolute',
            marginLeft: 70,
            top: 0,
            height: '100%',
            width: '100%',
            '@media screen and (max-width: 601px)': {
                marginLeft: 0,
            },
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                '& > span': {
                    flexGrow: 1,
                },
            },
        },
        '@media screen and (max-width: 1025px)': {
            textDecoration: 'none',
        },
        '& span > div': {
            margin: '40px 0 25px',
        },
    },
    bannerV2WrapperShort: {
        maxWidth: 1440,
        '& > div': {
            marginLeft: 'auto',
            '@media screen and (max-width: 601px)': {
                marginLeft: 'auto',
            },
        },
        '@media screen and (max-width: 1025px)': {
            minHeight: 'auto',
        },
        '@media screen and (max-width: 601px)': {
            textDecoration: 'none',
        },
    },
    bannerImage: {
        height: 'auto',
        '& img': {
            height: '100%',
        },
    },
    tearTopStyle: {
        margin: 'auto',
        transform: 'rotate(180deg)',
        width: '100%',
        height: '7px',
        display: 'block',
    },
    tearBottomStyle: {
        margin: 'auto',
        width: '100%',
        height: '7px',
        display: 'block',
    },
    bannerV2Image: (styles) => ({
        backgroundImage: `url(${styles.background_image?.desktop?.url}?auto=webp)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        '@media screen and (max-width: 1024px)': {
            backgroundImage: `url(${styles.background_image?.tablet ? styles.background_image.tablet?.url : styles.background_image.desktop?.url}?auto=webp)`,
        },
        '@media screen and (max-width: 599px)': {
            backgroundImage: `url(${styles.background_image?.mobile ? styles.background_image.mobile?.url : styles.background_image.desktop?.url}?auto=webp)`,
        },
    }),
}));

const BannerV2 = ({
    data,
    trackEvent,
}) => {
    const banner = data?.reference?.[0];

    if (banner) {
        let short = true;
        if (banner.presentation_type === 'Variant A - Tall') {
            short = false;
        }

        const classes = useStyles(banner);

        const constructImage = (tearStyle) => (
            <svg className={tearStyle}>
                <clipPath id="tear" clipPathUnits="objectBoundingBox"><path d="M0.025,0.992 C0.017,0.993,0.008,0.997,0,1 L0,0 L1,0 L1,0.996 C0.996,0.994,1,0.984,0.998,0.99 C0.997,0.991,0.993,0.99,0.993,0.99 C0.987,0.99,0.982,0.983,0.976,0.983 C0.965,0.982,0.953,0.979,0.942,0.979 C0.932,0.979,0.921,0.985,0.911,0.985 C0.908,0.985,0.906,0.985,0.903,0.981 C0.902,0.98,0.901,0.979,0.9,0.979 C0.897,0.979,0.894,0.982,0.891,0.985 C0.884,0.994,0.873,0.992,0.865,0.992 C0.859,0.993,0.853,0.994,0.847,0.992 C0.841,0.99,0.835,0.989,0.829,0.993 C0.823,0.998,0.818,0.99,0.812,0.989 C0.803,0.987,0.794,0.987,0.785,0.987 C0.782,0.987,0.779,0.983,0.776,0.981 C0.772,0.979,0.77,0.986,0.767,0.987 C0.759,0.987,0.751,0.991,0.743,0.987 C0.735,0.984,0.728,0.983,0.72,0.983 C0.714,0.983,0.709,0.979,0.702,0.982 C0.696,0.986,0.689,0.989,0.683,0.995 C0.678,0.999,0.672,0.996,0.667,0.994 C0.654,0.99,0.64,0.993,0.626,0.987 C0.622,0.985,0.617,0.985,0.613,0.98 C0.609,0.975,0.604,0.979,0.6,0.979 C0.584,0.979,0.569,0.987,0.553,0.987 C0.545,0.987,0.537,0.989,0.529,0.991 C0.516,0.993,0.502,0.987,0.488,0.987 C0.471,0.987,0.454,0.982,0.436,0.986 C0.429,0.988,0.419,0.983,0.412,0.991 C0.409,0.995,0.402,0.99,0.399,0.99 C0.38,0.99,0.361,0.984,0.342,0.996 C0.334,1,0.326,1,0.317,0.997 C0.31,0.994,0.304,0.989,0.297,0.991 C0.286,0.993,0.275,0.991,0.265,0.988 C0.256,0.985,0.247,0.986,0.238,0.982 C0.231,0.98,0.224,0.983,0.217,0.985 C0.205,0.989,0.193,0.988,0.182,0.991 C0.169,0.993,0.157,0.994,0.145,0.996 C0.135,0.997,0.125,0.995,0.115,0.991 C0.104,0.987,0.092,0.992,0.08,0.995 C0.071,0.997,0.062,0.998,0.052,0.998 C0.043,0.998,0.034,0.992,0.025,0.992" /></clipPath>
            </svg>
        );

        const handleClick = (e) => {
            e.preventDefault();
            // TODO Perform additional actions here
        };

        const trackingEventCategory = banner.linking?.tracking_event_category || '';
        const trackingEventAction = banner.linking?.tracking_event_action || '';
        const trackingEventLabel = banner.linking?.tracking_event_label || '';

        return (
            <div className={`BannerV2_block ${classes.block}`}>
                <div className={`${classes.bannerV2Wrapper} ${short && classes.bannerV2WrapperShort}`}>
                    <Link
                        data-ga-category={trackingEventCategory}
                        data-ga-action={trackingEventAction}
                        data-ga-label={trackingEventLabel}
                        aria-hidden="true"
                        onClick={(e) => {
                            handleClick(e);
                            trackEvent({
                                eventCategory: trackingEventCategory,
                                eventAction: trackingEventAction,
                                eventLabel: trackingEventLabel,
                            });
                            ifExternalUrl(e, banner.linking?.link?.href, true);
                        }}
                        to={{ pathname: banner.linking?.link?.href }}
                        title={banner.linking?.link?.title}
                        className={`${classes.bannerV2Root} ${short && classes.bannerV2RootShort}`}
                        data-testid="banner-v2-block"
                    >
                        <div className={`${classes.contentSectionWrapper} ${classes.fixedMessageWidth} ${short && classes.contentSectionWrapperShort} ${classes.bannerV2Image}`}>
                            <div className={`${classes.contentSection} ${short && classes.contentSectionShort}`} data-testid="banner-v2-responsive">
                                <div className={`${classes.headline} ${short && classes.headlineShort}`}>
                                    {banner.message.heading}
                                </div>
                                <div className={classes.copy}>
                                    {banner.message.copy}
                                </div>
                                {banner.message.cta_copy !== '' && (
                                    <div className={classes.ctaLink}>
                                        {`${banner.message.cta_copy} >`}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Link>
                    <div className={`${classes.tearSection} ${short && classes.tearSectionShort}`}>
                        {banner.decorative_tear_edge.includes('Top') && (
                            <>
                                {constructImage(classes.tearTopStyle)}
                                <div className={`${classes.backgroundFiller} ${short && classes.backgroundFillerShort}`} />
                            </>
                        )}
                        {banner.decorative_tear_edge.includes('Bottom') && (
                            <>
                                <div className={`${classes.backgroundFiller} ${short && classes.backgroundFillerShort}`} />
                                {constructImage(classes.tearBottomStyle)}
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
    return <></>;
};

BannerV2.propTypes = {
    data: object.isRequired,
    trackEvent: func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(BannerV2);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import {
    shape, string,
} from 'prop-types';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import { parseFont } from '../../../../../../helpers/common/helper';
import getBackgroundColor from '../../../../../../helpers/getBackgroundColor';

const useStyles = makeStyles((theme) => ({
    mainListBlock: ({ styles }) => ({
        backgroundColor: getBackgroundColor(styles?.backgroundColor) || 'transparent',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: !styles.linkListPosition && '100px',
        right: styles.linkListPosition && '100px',
        padding: '15px 30px 10px 0px',
        '@media (max-width: 1430px)': {
            right: styles.linkListPosition && '10px',
            paddingRight: styles.linkListPosition && '10px',
        },
    }),
    listBlock: ({ styles }) => ({
        '& a': {
            textDecoration: 'none',
            display: 'block',
            color: styles.fontColor,
            fontFamily: parseFont(styles?.fontFamily),
            '&:hover': {
                color: styles.fontHoverColor,
            },
        },
        '& li': {
            listStyle: 'none',
            borderTop: `1px solid ${theme.palette.cms?.white || '#ffffff'}`,
            textAlign: 'center',
            padding: '0.25rem 0px',
            fontWeight: '600',
            fontSize: '1rem',
            '@media (max-width: 1200px)': {
                fontSize: styles.linkListPosition && '0.875rem',
            },
        },
    }),
}));
const DesktopHeroLinklist = ({ data }) => {
    const styles = {
        linkListPosition: data?.show_on_right,
        fontColor: data?.font_color?.color,
        fontHoverColor: data?.font_color_hover?.color,
        fontFamily: data?.font_family,
        backgroundColor: data?.background_color,
    };
    const classes = useStyles({ styles });
    const seed = useUIDSeed();
    return (
        <div className={classes.mainListBlock}>
            <div className={classes.listBlock} aria-hidden="true" tabIndex="-1">
                <ul>
                    {data?.link?.map((item) => (
                        <li key={seed(item)}>
                            <LinkOrATag
                                href={item?.href}
                                trackingEventAction={item?.href}
                                trackingEventCategory="Hero Content Page"
                                trackingEventLabel={item?.title}
                                isTrackEventEnabled
                                ariaLabel={item?.title}
                            >
                                {item?.title}
                            </LinkOrATag>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
DesktopHeroLinklist.propTypes = {
    data: shape({
        font_color: shape({
            color: string.isRequired,
        }),
        font_family: string.isRequired,
    }).isRequired,
};

export default DesktopHeroLinklist;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useEffect } from 'react';
import {
    object, shape, bool, string, node,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import mbpLogger from 'mbp-logger';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { useSelector, useDispatch } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import BannerGeneric from '../../ContentTypeComponents/GenericBanner/GenericBanner';
import NUpFeature from '../../ContentTypeComponents/NUp/NUp';
import NUpSimple from '../../ContentTypeComponents/NupSimple/NupSimple';
import LinkList from '../../ContentTypeComponents/LinkList/LinkList';
import WyngModule from '../../ContentTypeComponents/Wyng/Wyng';
import SeoBlock from '../../ContentTypeComponents/SeoBlock/SeoBlock';
import VideoModule from '../../ContentTypeComponents/Video/Video';
import SubCollection from '../../ContentTypeComponents/SubCollection/SubCollection';
import GraphqlSeoFooter from '../GraphqlHomePage/Partials/GraphqlSeoFooter/GraphqlSeoFooter';
import MuiTabMenu from '../../ContentTypeComponents/MuiTabMenu/MuiTabMenu';
import LinkBlock from '../../ContentTypeComponents/LinkBlock/LinkBlock';
import ThreeUpFeature from '../../ContentTypeComponents/ThreeUp/ThreeUp';
import ContentPageBlockStyle from './style/ContentPageBlockStyle';
import QaListContainer from '../../ContentTypeComponents/QaList/QaListContainer';
import ConnectedGraphqlFoodFindPerfect from '../GraphqlHomePage/Partials/GraphqlFindAGiftFast/GraphqlFoodGiftFinder';
import ShareMoreBlock from '../GraphqlCommonComponents/ShareMore/ShareMoreBlock';
// helpers
import isEmptyReference from '../../../helpers/contentstack/isEmptyReference';
import HostedDinnerBlock from '../GraphqlCommonComponents/HostedDinner/HostedDinnerBlock';
import ContentBottomBanner from './Partials/ContentBottomBanner';
import NewsMediaPage from '../GraphqlCommonComponents/NewsMediaConatiner/NewsMediaPage';
import HeroSingleV2 from '../../ContentTypeComponents/HeroSingle_V2/HeroSingle_V2_Container';
import ConnectedBannerV2 from '../../ContentTypeComponents/BannerV2/BannerV2';
import TwoColumnCopy from '../BecomeAnAffiliate/TwoColumnCopy';
import ContactUsAffiliate from '../BecomeAnAffiliate/ContactUsAffiliate';
import Imoc from '../GraphqlCategoryPage/Partials/GraphqlCatHeaderContainer/Partials/GraphqlImoc/GraphqlImoc';
import { getPresentationFamily, getFeatureFlags, getCurrentBrandId } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getOperatingSystem } from '../../../../state/ducks/App/ducks/Config/Config-Helpers';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { determineChatlink } from '../../MultiBrandChatController/Global_chat_helpers';
import triggerPopChatEvent, { RenderChatAsLink } from '../../MultiBrandChatController/AigoChatWidgetWrapperV2/popOpenChat';
import BlockHeading from '../../ContentTypeComponents/BlockHeading';
import { nodeToString } from '../../../helpers/common/helper';
import isHtml from '../../../helpers/contentstack/isHtml';

const styles = (theme) => ContentPageBlockStyle(theme);

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const ContentPageBlock = ({
    block, classes, identifyWidthObj, classOverride, brand, ssrDeviceType, isChatWrapperExcluded,
}) => {
    const dispatch = useDispatch();
    const operatingSystem = getOperatingSystem().toLowerCase();
    const currentBrand = useSelector((state) => getCurrentBrandId(state));
    const featureFlags = useSelector((state) => getFeatureFlags(state));
    const customerData = useSelector((state) => state?.member);
    const isMobileChatEnabled = ((featureFlags['is-gbm-in-pwa-enabled'] && operatingSystem === 'android') || (featureFlags['is-abc-in-pwa-enabled'] && operatingSystem === 'ios'));
    const desktopPopChat = () => {
        if (operatingSystem !== 'android' && operatingSystem !== 'ios') {
            triggerPopChatEvent({});
        }
    };
    if (!block) {
        mbpLogger.logDebug({
            appName: process.env.npm_package_name,
            message: 'There do not seem to be any blocks passed into ContentPageBlock',
        });
        return null;
    }
    const presentationFamily = useSelector(getPresentationFamily);
    const loadNavigation = (data) => {
        if (data?.reference?.[0]) {
            const referenceData = data.reference[0];
            const presentation = referenceData.presentation || '';
            switch (presentation) {
                case 'Tab':
                    return <MuiTabMenu data={referenceData} />;
                default:
                    return <LinkList data={{ link_list: data }} />;
            }
        }
        return <></>;
    };

    const LinkOrReference = ({ href, children }) => {
        if ((featureFlags['is-cs-page-chat-enabled'] && href === ('https://bcrw.apple.com/urn:biz:9ab55a12-ccb6-458d-8c6a-758cbf5ec242') && (!['ios', 'android'].includes(operatingSystem) || isMobileChatEnabled))) {
            return (
                <a
                    onClick={() => {
                        dispatch(trackEvent({
                            eventCategory: 'Content Page Customer Service',
                            eventAction: 'Chat opened',
                            eventLabel: 'Chat',
                        }));
                        desktopPopChat();
                    }}
                    onKeyDown={() => {}}
                    href={determineChatlink(operatingSystem, currentBrand, featureFlags, customerData) || null}
                    aria-hidden="true"
                    tabIndex="0"
                    alt="chat"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                    <strong>{children}</strong>
                </a>
            );
        }
        if (href !== ('https://bcrw.apple.com/urn:biz:9ab55a12-ccb6-458d-8c6a-758cbf5ec242')) {
            return <a href={href} aria-hidden="true" tabIndex="-1">{children}</a>;
        }
        return '';
    };

    LinkOrReference.propTypes = {
        href: string.isRequired,
        children: node.isRequired,
    };

    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];

    // Run a check to see that a block's references are deployed to the same environment
    if (isEmptyReference(blockObj, blockKey, 'ContentPageBlock.js')) return null;
    let copy_style = {};
    const imageStyling = {};
    const imageAlignment = {};

    // set up the image styling
    const imageStyleCase = (ImageCaseSyling) => {
        if (ImageCaseSyling?.length > 0) {
            ImageCaseSyling?.map((list) => {
                const blockData = Object.keys(list)[0];
                switch (blockData) {
                    case 'width': {
                        const widthPersnt = list?.width?.dimension === 'Percentage' ? '%' : 'px';
                        imageStyling.width = `${list?.width?.width}${widthPersnt}`;
                        break;
                    }
                    case 'justify':
                        imageAlignment.justifyContent = list?.justify?.value;
                        break;
                    default:
                        break;
                }
            });
        }
    };

    useEffect(() => {
        // Scrolled the page to particular ID based on URL HashElement
        if (typeof window !== 'undefined' && window?.location?.hash) {
            const hashElement = document.getElementById(`${window.location.hash}`.slice(1));
            if (hashElement) {
                const mobileTopPosition = presentationFamily === 'food' ? 115 : 60;
                const headerOffset = ssrDeviceType === 'mobile' ? mobileTopPosition : 0;
                const targetElementPosition = hashElement.getBoundingClientRect().top;
                const offsetPosition = targetElementPosition + window.pageYOffset - headerOffset;
                window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
            }
        }
    }, []);
    const transform = (nod) => {
        if (nod?.type === 'tag' && nod?.name === 'p') {
            const value = nod?.children?.map(nodeToString)?.join('');
            if (value && typeof value === 'string' && value.toLowerCase()?.includes('chat')) {
                return <RenderChatAsLink value={value} operatingSystem={operatingSystem} currentBrand={currentBrand} featureFlags={featureFlags} />;
            }
        }
        return undefined;
    };
    switch (blockKey) {
        // vertical menu list for use in Left Column/Blocks
        case 'navigation':
            return loadNavigation(blockObj);

        case 'image':
            if (blockObj?.link_img) {
                return (
                    <Link to={blockObj.link_img?.href} title={blockObj.link_img?.title}>
                        <img src={blockObj.image?.url} alt={blockObj.image?.title} />
                    </Link>
                );
            }
            imageStyleCase(blockObj?.styling);
            return (
                blockObj?.image?.url && (
                    <Grid container style={imageAlignment} className={classes.setMainImageConatiner}>
                        <img style={imageStyling} className={classes.imageStyle} src={blockObj?.image?.url} alt={blockObj?.image?.description || 'Harvest Report Banner'} />
                    </Grid>
                )
            );

        case 'copy':
            if (blockObj?.copy) {
                copy_style = {
                    textAlign: blockObj?.copy_style?.text_alignment,
                    color: blockObj?.copy_style?.copy_color?.color,
                    fontFamily: parseFont(blockObj?.copy_style?.font_family),
                    fontSize: blockObj?.copy_style?.font_size,
                };
                if (blockObj?.copy_style?.show_border) {
                    copy_style.borderBottom = `${blockObj?.copy_style?.border_bottom || 0}px ${blockObj?.copy_style?.border_style || 'solid'} ${blockObj?.copy_style?.border_color?.color || '#333'}`;
                    copy_style.borderTop = `${blockObj?.copy_style?.border_top}px ${blockObj?.copy_style?.border_style || 'solid'} ${blockObj?.copy_style?.border_color?.color || '#333'}`;
                    copy_style.paddingBottom = 10;
                }
                let contentCopy;
                if (isHtml(blockObj.copy)) {
                    const parseOptions = isChatWrapperExcluded ? undefined : { transform };
                    contentCopy = ReactHtmlParser(blockObj.copy, parseOptions);
                } else {
                    contentCopy = (
                        <ReactMarkdown
                            source={blockObj.copy}
                            renderers={{
                                linkReference: LinkOrReference,
                                text: ({ value }) => {
                                    if (!isChatWrapperExcluded && typeof value === 'string' && value?.toLowerCase()?.includes('chat')) {
                                        return <RenderChatAsLink value={value} operatingSystem={operatingSystem} currentBrand={currentBrand} featureFlags={featureFlags} customerData={customerData} />;
                                    }
                                    return value;
                                },
                            }}
                            className={`${classes.markdown} ${classes.pageBreak}`}
                            escapeHtml={false}
                        />
                    );
                }
                return (
                    <div
                        role="region"
                        aria-label="Copytext"
                        className={classes.mainCopyContainer}
                        style={copy_style}
                    >
                        {contentCopy}
                    </div>
                );
            }
            return null;
        case 'link_block':
            // if this block is for live chat, render accordingly
            return (blockObj?.title && blockObj.title.toLowerCase()?.includes('chat')
                ? (featureFlags['is-cs-page-chat-enabled'] && (!['ios', 'android'].includes(operatingSystem) || isMobileChatEnabled)) && (
                    <a
                        onClick={() => {
                            dispatch(trackEvent({
                                eventCategory: 'Content Page Customer Service',
                                eventAction: 'Chat opened',
                                eventLabel: 'Chat',
                            }));
                            desktopPopChat();
                        }}
                        href={determineChatlink(operatingSystem, currentBrand, featureFlags, customerData) || null}
                        aria-hidden="true"
                        tabIndex="-1"
                        alt="chat"
                        className={classes.liveChat}
                        style={{ textDecoration: 'none' }}
                    >
                        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.5 9.08217C0.5 6.00188 0.5 4.46174 1.09946 3.28522C1.62677 2.25033 2.46816 1.40894 3.50305 0.881634C4.67957 0.282169 6.21971 0.282169 9.3 0.282169H18.7C21.7803 0.282169 23.3204 0.282169 24.4969 0.881634C25.5318 1.40894 26.3732 2.25033 26.9005 3.28522C27.5 4.46174 27.5 6.00188 27.5 9.08217V9.98217C27.5 13.0625 27.5 14.6026 26.9005 15.7791C26.3732 16.814 25.5318 17.6554 24.4969 18.1827C23.3204 18.7822 21.7803 18.7822 18.7 18.7822H12.2488C12.1413 18.8909 12.0234 19.0088 11.8951 19.1371L8.30447 22.7277C7.6603 23.3719 7.33822 23.694 7.06211 23.7155C6.82254 23.7342 6.58852 23.637 6.43272 23.4541C6.25316 23.2432 6.25409 22.7877 6.25594 21.8767L6.26211 18.8481C6.26218 18.8115 6.26226 18.7766 6.26239 18.7436C5.04339 18.6858 4.20795 18.5419 3.50305 18.1827C2.46816 17.6554 1.62677 16.814 1.09946 15.7791C0.5 14.6026 0.5 13.0625 0.5 9.98217V9.08217ZM8.125 11.5322C9.16053 11.5322 10 10.6927 10 9.65717C10 8.62164 9.16053 7.78217 8.125 7.78217C7.08947 7.78217 6.25 8.62164 6.25 9.65717C6.25 10.6927 7.08947 11.5322 8.125 11.5322ZM16 9.65717C16 10.6927 15.1605 11.5322 14.125 11.5322C13.0895 11.5322 12.25 10.6927 12.25 9.65717C12.25 8.62164 13.0895 7.78217 14.125 7.78217C15.1605 7.78217 16 8.62164 16 9.65717ZM20.125 11.5322C21.1605 11.5322 22 10.6927 22 9.65717C22 8.62164 21.1605 7.78217 20.125 7.78217C19.0895 7.78217 18.25 8.62164 18.25 9.65717C18.25 10.6927 19.0895 11.5322 20.125 11.5322Z" />
                        </svg>
                        <div>
                            <strong>{blockObj?.title}</strong>
                            <span>{blockObj?.message}</span>
                        </div>
                        <strong className={classes.arrow}>&#x3E;</strong>
                    </a>
                )
                : <LinkBlock data={blockObj} commonClasses={classes} />
            );
        case 'qa_list':
            return <QaListContainer data={(blockObj?.reference?.[0] || {})} identifyWidthObj={identifyWidthObj} />;

        case 'generic_banner':
            return <BannerGeneric data={blockObj} />;

        case 'banner_v2':
            return <ConnectedBannerV2 data={blockObj} />;

        case 'wyng':
            return <WyngModule data={blockObj} />;

        case 'tabular_data':
            return <ReactMarkdown source={blockObj.table} className={`${classes.markdown} ${classes.markdownTable} ${classes.pageBreak}`} escapeHtml={false} />;

        case 'subcollection':
            return (
                <>
                    {blockObj?.heading && <BlockHeading data={blockObj} />}
                    <SubCollection data={blockObj} placementPage="content-page" />
                </>
            );

        case 'page_break':
            // will need logic to handle decorative images as page breaks
            return <hr style={{ margin: '40px auto' }} />;

        case 'n_up':
            return (
                <>
                    {blockObj?.heading && <BlockHeading data={blockObj} />}
                    <NUpFeature data={blockObj} />
                </>
            );

        case 'n_up_simple':
            return (
                <>
                    {blockObj?.heading && <BlockHeading data={blockObj} />}
                    <NUpSimple data={blockObj} />
                </>
            );

        case 'seo':
            return <GraphqlSeoFooter data={blockObj} />;

        case 'video_module':
            return <VideoModule data={blockObj} />;

        case 'fixed_width':
            return null; // just style info passing through

        case 'seo_copy': // GFB
            return <SeoBlock data={blockObj} />;

        case 'gift_finder_gfb':
            return <ConnectedGraphqlFoodFindPerfect data={blockObj} />;

        case 'share_more_block':
            return <ShareMoreBlock data={blockObj} />;

        case '3_up':
            return <ThreeUpFeature data={blockObj} />;

        case 'hosted_dinners_block':
            return <HostedDinnerBlock classOverride={classOverride} data={blockObj} />;
        case 'bottom_banner':
            return <ContentBottomBanner data={blockObj} />;
        case 'news_media':
            return <NewsMediaPage data={blockObj} />;
        case 'herosingle_v2':
            return <HeroSingleV2 reference={blockObj} />;
        case 'two_column_copy':
            return <TwoColumnCopy data={blockObj} brand={brand} />;
        case 'contact_us':
            // TODO: PWA-4890 remove this component and use NUPsimples instead
            return <ContactUsAffiliate data={blockObj} />;
        case 'imoc':
            return (
                <Grid container className={classes.imocMainContainer}>
                    <Imoc brand={brand?.domain} imocBlock={blockObj} />
                </Grid>
            );
        default:
            mbpLogger.logDebug({
                appName: process.env.npm_package_name,
                message: `Unknown ContentPage block type "${blockKey}" in ContentPageBlock.js`,
            });
            return <div>Temp div</div>;
    }
};

ContentPageBlock.propTypes = {
    block: object.isRequired,
    classes: object.isRequired,
    classOverride: object.isRequired,
    brand: object.isRequired,
    identifyWidthObj: shape({
        isMobile: bool.isRequired,
        isDesktop: bool.isRequired,
    }).isRequired,
    ssrDeviceType: string.isRequired,
    isChatWrapperExcluded: bool.isRequired,
};

export default withStyles(styles, { withTheme: true })(ContentPageBlock);

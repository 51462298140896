/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, string, shape, func,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

const styles = (theme) => ({
    backGroundContainer: {
        backgroundColor: theme.palette.cms?.hostedHeaderBgColor || '#EBEBDF',
        padding: 16,
    },
    headerText: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '1.5em',
        },
    },
    heading: {
        '& h1,h2,h3,h4': {
            fontSize: 28,
            margin: '15px 0 5px 0',
            [theme.breakpoints.down('xs')]: {
                fontSize: 22,
            },
        },
    },
    subHeadingText: {
        width: '70%',
        margin: '0 auto',
        fontSize: 15,
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
        '& p': {
            margin: '5px 0 5px 0',
        },
    },
});

const HostedDinnerHeader = ({
    classes, headerData, classOverride, setFontFamily,
}) => {
    const imageHeader = headerData?.image?.url || '';
    const heading = headerData?.heading || '';
    const subHeading = headerData?.sub_heading || '';
    const fontString = headerData?.font_family || 'LatoRegular, Arial, Verdana, sans-serif';

    return (
        <Grid container className={classes.backGroundContainer} style={setFontFamily(fontString)}>
            <Grid item xs={12} lg={6}>
                <div className={classOverride.headerLogoImage}>
                    <img src={imageHeader} alt={heading} />
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className={classes.headerText}>
                    <div className={classes.heading}>
                        <ReactMarkdown source={heading} escapeHtml={false} />
                    </div>
                    <div className={classes.subHeadingText}>
                        <ReactMarkdown source={subHeading} />
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

HostedDinnerHeader.propTypes = {
    classes: object.isRequired,
    setFontFamily: func.isRequired,
    classOverride: object.isRequired,
    headerData: shape({
        heading: string,
        sub_heading: string,
        image: shape({
            title: string,
            url: string,
        }),
    }),
};
HostedDinnerHeader.defaultProps = {
    headerData: {},
};
export default withStyles(styles)(HostedDinnerHeader);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';

import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import { string } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { GRAPHQL_ENV } from '../../../gql';
import Lander from './Lander';
import Event from './Event';

const HostedDinners = ({ path }) => {
    if (path.match('/hosted-dinners/')?.length) {
        return <Event path={path} />;
    }

    const HOSTEDDINNER_QUERY = gql`
        query findContent {
            findContent(brand: "harryanddavid", contentType: "hosted_dinners_event", environment: "${GRAPHQL_ENV}", locale: "en-us") {
                content
            }
        }
    `;

    return (
        <Query query={HOSTEDDINNER_QUERY}>
            {({
                loading, error, data,
            }) => {
                if (loading) {
                    return <></>; // skeleton component
                }
                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: HOSTEDDINNER_QUERY,
                        component: 'HostedDinners.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });

                    return null;
                }
                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: HOSTEDDINNER_QUERY,
                        component: 'HostedDinners.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                return <Lander data={data} />;
            }}
        </Query>
    );
};

HostedDinners.propTypes = {
    path: string.isRequired,
};

export default HostedDinners;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    arrayOf, string, shape, bool,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import NewsMediaBannerBlock from './Partials/NewsMediaBannerBlock';
import NewsMediaCopy from './Partials/NewsMediaCopy';
import NewsMediaLogo from './Partials/NewsMediaLogo';
import NewsMediaSlider from './Partials/NewsMediaSlider';

const NewsandMediaBlock = ({
    block, videoBlock, bannerLeftText,
}) => {
    if (!block) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            message: 'No block types have been passed into NewsandMediaBlock.js',
        });
        return null;
    }
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];

    switch (blockKey) {
        case 'news_media_banner' || 'media_block':
            return <NewsMediaBannerBlock videoBlock={videoBlock} newsBannerText={bannerLeftText} />;
        case 'copy':
            return <NewsMediaCopy blockData={blockObj} />;
        case 'slider':
            return <NewsMediaSlider blockData={blockObj} />;
        case 'media_logo':
            return <NewsMediaLogo blockData={blockObj} />;
        default:
            mbpLogger.logWarning({
                appName: process.env.npm_package_name,
                message: `Unknown NewsMedia block type "${blockKey}" in NewsandMediaBlock.js`,
            });
            return null;
    }
};

NewsandMediaBlock.propTypes = {
    block: shape({
        copy: shape({
            background_color: shape({
                color: string,
            }),
            copy_block: arrayOf(shape({
                heading: string,
                sub_heading: string,
            })),
        }),
        media_logo: shape({
            title: string,
            media_group_logo: arrayOf(shape({
                link: shape({
                    href: string,
                    title: string,
                }),
                image: shape({
                    url: string,
                    title: string,
                }),
            })),
        }),
    }),
    videoBlock: shape({
        autoplay: bool,
        heading: bool,
        video_references: arrayOf(shape({
            video_id: string,
            show_controls: bool,
        })),
    }),
    bannerLeftText: shape({
        news_block: shape({
            heading: string,
            copy: string,
            sub_heading: string,
        }),
    }),
};
NewsandMediaBlock.defaultProps = {
    block: {},
    videoBlock: {},
    bannerLeftText: {},
};
export default NewsandMediaBlock;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    bool, arrayOf,
    shape, string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';
import HeroVerticallySplit from './ContentHeroesLayoutVersions/HeroVerticallySplit';
import HeroWithSvg from './ContentHeroesLayoutVersions/HeroWithSvg';
import HeroStandard from './ContentHeroesLayoutVersions/HeroStandard';

const useStyles = makeStyles((theme) => ({
    topBannerContainer: {
        margin: '10px auto',
        maxWidth: '1440px',
        textAlign: 'center',
        '& img': {
            maxWidth: '25%',
            maxHeight: '100px',
        },
        [theme.breakpoints.down(600)]: {
            '& img': {
                maxWidth: '55%',
                maxHeight: '80px',
            },
        },
    },
    topBannerContainerFullWidth: {
        margin: '10px auto',
        textAlign: 'center',
        '& img': {
            maxWidth: '100%',
            maxHeight: '100px',
        },
        [theme.breakpoints.down(600)]: {
            maxHeight: '80px',
        },
    },
}));

const ContentHero = ({ ssrDeviceType, data }) => {
    const isMobile = ssrDeviceType === 'mobile';

    const blockData = data?.reference?.[0];

    const classes = useStyles();

    const renderHeroBasedOnLayout = (block) => {
        switch (block['layout_type']) {
            case 'vertically split':
                return (
                    <HeroVerticallySplit data={block} />
                );
            case 'standard':
                return (
                    <HeroStandard data={block} />
                );
            case 'with svg logo':
                return (
                    <HeroWithSvg data={block} />
                );
            default:
                return null;
        }
    };

    const renderTopBanner = (bannerData) => (
        <div className={bannerData?.is_full_width ? classes.topBannerContainerFullWidth : classes.topBannerContainer}>
            <img alt={isMobile ? bannerData?.mobile_image?.title : bannerData?.image?.title} src={isMobile ? bannerData?.mobile_image?.url : bannerData?.image?.url} />
        </div>
    );

    return (
        <div>
            {(blockData?.banner?.image?.url || blockData?.banner?.mobile_image?.url) && (
                <>{renderTopBanner(blockData?.banner)}</>
            )}

            {renderHeroBasedOnLayout(blockData)}
        </div>
    );
};

ContentHero.propTypes = {
    ssrDeviceType: bool.isRequired,
    data: shape({
        reference: arrayOf(shape({
            title: string.isRequired,
            layout_type: string.isRequired,
            banner: {
                copy: string.isRequired,
                is_full_width: bool.isRequired,
                image: {
                    url: string.isRequired,
                    title: string.isRequired,
                },
                mobile_image: {
                    url: string.isRequired,
                    title: string.isRequired,
                },
            },
            main_images: {
                image: {
                    url: string.isRequired,
                    title: string.isRequired,
                },
                mobile_image: {
                    url: string.isRequired,
                    title: string.isRequired,
                },
            },
            main_copy: {
                copy_background_color: {
                    color: string.isRequired,
                },
                svg: {
                    url: string.isRequired,
                    title: string.isRequired,
                },
                mobile_svg: {
                    url: string.isRequired,
                    title: string.isRequired,
                },
                position_top: string.isRequired,
                title: string.isRequired,
                title_font_size: string.isRequired,
                title_color: shape({
                    color: string.isRequired,
                }),
                title_letter_spacing: string.isRequired,
                subtitle: string.isRequired,
                subtitle_font_size: string.isRequired,
                subtitle_color: shape({
                    color: string.isRequired,
                }),
                cta: shape({
                    is_ga_trackevent_enabled: bool.isRequired,
                    link: shape({
                        title: string.isRequired,
                        href: string.isRequired,
                    }),
                    font_size: string.isRequired,
                    font_weight: string.isRequired,
                    text_decoration: string.isRequired,
                    text_transform: string.isRequired,
                    copy_color: shape({
                        color: string.isRequired,
                    }),
                    background_color: shape({
                        color: string.isRequired,
                    }),
                }),
            },
        })),
    }),
};

ContentHero.defaultProps = {
    data: {},
};

const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
});

export default connect(mapStateToProps, null)(ContentHero);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import { object, shape, string } from 'prop-types';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { GRAPHQL_ENV } from '../../../gql';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import HeroSingle from './Responsive/HeroSingle_V2';

// THIS PART OF THE COMPONENT IS UNUSED AT THIS TIME UNTIL findContentByUID IS DEPLOYED TO ALL ENVS

// const contType = 'herosingle_v2';

// eslint-disable-next-line react/prefer-stateless-function
class HeroSingle_V2_Container extends Component {
    render() {
        const { reference, brand } = this.props;
        // const { title } = reference; //, query:"{\\"title\\" : \\"${title}\\"}")
        const contentType = reference[0]?.['_content_type_uid'];

        // TODO: use findContentByUID instead of findContent
        const PROMO_QUERY = gql`{
            findContent(brand: "${brand.domain}", environment: "${GRAPHQL_ENV}", contentType: "${contentType}")
            { content }
        }  
        `;
        return (
            <Query query={PROMO_QUERY}>
                {({ loading, error, data }) => {
                    if (loading) {
                        // TODO: Add spinner graphic
                        return <ReactMarkdown source="loading..." />;
                    }
                    if (error) {
                        return <>data error</>;
                    }
                    if (data) {
                        return <HeroSingle source={data} />;
                    }
                    return <>loading...</>;
                }}

            </Query>
        );
    }
}
const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

HeroSingle_V2_Container.propTypes = {
    reference: shape({
        title: string.isRequired,
    }).isRequired,
    brand: object.isRequired,
};

export default connect(mapStateToProps)(HeroSingle_V2_Container);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const isHtml = (str) => {
    const substantialHtmlRegex = /<(?!br|hr)(\/?[a-z][\s\S]*?)>/i;
    const markdownLinkRegex = /\[\d+]:\s*[^)]+/;
    const markdownHeadingRegex = /^\s*(#{1,6})\s+(.*)$/m;
    return substantialHtmlRegex?.test(str) && !markdownLinkRegex?.test(str) && !markdownHeadingRegex?.test(str);
};

export default isHtml;

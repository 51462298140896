/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    arrayOf,
    bool,
    number,
    object,
    string,
    shape,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import Slider from 'react-slick';
import { useUIDSeed } from 'react-uid';
import ReactMarkdown from 'react-markdown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LinkOrATag from '../../LinkOrATag/LinkOrATag';

const styles = (theme) => ({
    mainContainerMediaSlider: {
        margin: '0px 0px 30px 0px',
    },
    leftSliderData: {
        textAlign: 'center',
        padding: '0 6em',
        [theme.breakpoints.down(1024)]: {
            padding: '0 1em',
        },
    },
    rightImageContainer: {
        '& img': {
            width: '100%',
        },
    },
    mainSliderConatiner: {
        margin: '0 auto',
    },
    innerSliderContainer: {
        padding: 10,
    },
    featuredHeading: {
        fontWeight: 600,
        fontSize: 18,
    },
    featuredSubHeading: {
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: '2px 0',
            fontSize: 16,
        },
    },
    productButtonLink: {
        textDecoration: 'none',
    },
    buttonProduct: {
        '& a': {
            display: 'block',
            padding: 8,
            marginTop: 20,
            borderRadius: 5,
            fontSize: 18,
        },
    },
    sliderContainer: {
        position: 'relative',
        maxWidth: 1400,
        margin: 'auto',
        '& .slick-list': {
            overflow: 'hidden',
        },
        '& .slick-slide': {
            display: 'table-cell',
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
        '& .slick-current': {
            paddingRight: 5,
        },
        '& .slick-prev, & .slick-next': {
            transform: 'translate(0,-50%)',
            cursor: 'pointer',
            border: 'none',
            outline: 0,
            fontSize: 0,
            lineHeight: 0,
            top: '42%',
            width: 30,
            height: 30,
            position: 'absolute',
        },
        '& .slick-prev': {
            left: 58,
            background: theme.palette.cms?.white || '#fff',
            opacity: 0.9,
            zIndex: 1,
        },
        '& .slick-next': {
            right: 58,
            background: theme.palette.cms?.white || '#fff',
            opacity: 0.9,
        },
        '& .slick-dots': {
            listStyle: 'none',
            display: 'flex !important',
            padding: '0',
            justifyContent: 'center',
            margin: '12px 0px 0px 0px',
            '& li': {
                lineHeight: 1,
            },
            '& .slick-active': {
                '& div': {
                    background: theme.palette.cms?.white || 'rgb(47, 47, 47)',
                },
            },
            '& .slick-current': {
                '& div': {
                    paddingRight: 5,
                },
            },
        },
    },
    dotNavigation: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        background: theme.palette.cms?.white || '#fff',
        border: `${'1px solid'} ${theme.palette.cms?.white || 'rgb(47, 47, 47)'}`,
        marginRight: 5,
    },
});

const NewsMediaSlider = ({ classes, blockData }) => {
    const newsSliderData = blockData?.slider || [];
    const showSliderBorder = blockData?.show_border_on_slider;
    let sliderBorderStyling = {};
    if (showSliderBorder) {
        sliderBorderStyling = {
            border: `${blockData?.slider_border_width || 30}px solid ${blockData?.slider_border_color?.color || '#ebebeb'}`,
        };
    }
    const seed = useUIDSeed();
    return (
        <Grid container className={classes.mainContainerMediaSlider}>
            <Grid item xs={12} lg={12}>
                <div className={classes.newsMediaSlider}>
                    <Slider
                        dots={!!blockData?.slider_dots}
                        infinite
                        autoplaySpeed={blockData?.autoplay_speed || 3000}
                        arrows={!!blockData?.slider_arrow}
                        autoplay={!!blockData?.autoplay}
                        fade
                        cssEase="ease-in-out"
                        slidesToShow={1}
                        slidesToScroll={1}
                        dotsClass="slick-dots slick-thumb"
                        nextArrow={<ArrowForwardIosIcon />}
                        prevArrow={<ArrowBackIosIcon />}
                        className={`carousel-container ${classes.sliderContainer}`}
                        customPaging={() => <div className={classes.dotNavigation} />}
                    >
                        {newsSliderData?.map((item) => {
                            let subHeadingStyle = {};
                            let productButtonLinkStyle = {};
                            subHeadingStyle = {
                                color: item?.sub_heading_color?.color || '#999999',
                                fontSize: item?.sub_heading_font_size || 18,
                            };
                            productButtonLinkStyle = {
                                backgroundColor: item?.button_background_color?.color || 'transparent',
                                border: `2px solid ${item?.button_border_color?.color || '#dd3337'}`,
                                color: item?.button_text_color?.color || '#dd3337',
                            };
                            return (
                                <Grid key={seed(item)} container>
                                    <Grid item xs={10} className={classes.mainSliderConatiner}>
                                        <Grid container style={sliderBorderStyling} className={classes.innerSliderContainer}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className={classes.leftSliderData}>
                                                    <div className={classes.headingImage}>
                                                        <img src={item?.heading_image?.url} alt={item?.heading_image?.title} />
                                                    </div>
                                                    <div style={subHeadingStyle} className={classes.subHeadingtext}>
                                                        <ReactMarkdown source={item?.sub_heading} escapeHtml={false} />
                                                    </div>
                                                    <div className={classes.featuredHeading}>
                                                        {item?.featured_heading}
                                                    </div>
                                                    <div className={classes.featuredSubHeading}>
                                                        <ReactMarkdown source={item?.featured_sub_heading} escapeHtml={false} />
                                                    </div>
                                                    <div className={classes.buttonProduct}>
                                                        <LinkOrATag style={productButtonLinkStyle} className={classes.productButtonLink} href={item?.button?.href}>{item?.button?.title}</LinkOrATag>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className={classes.rightImageContainer}>
                                                    <img src={item?.slider_image?.url} alt={item?.slider_image?.title} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Slider>
                </div>
            </Grid>
        </Grid>
    );
};

NewsMediaSlider.propTypes = {
    classes: object.isRequired,
    blockData: shape({
        autoplay: bool,
        autoplay_speed: number,
        show_border_on_slider: bool,
        slider: arrayOf(shape({
            sub_heading: string,
            featured_heading: string,
            featured_sub_heading: string,
            slider_image: shape({
                url: string,
                title: string,
            }),
            sub_heading_font_size: number,
            button_text_color: shape({
                color: string,
            }),
        })),
    }),
};
NewsMediaSlider.defaultProps = {
    blockData: {},
};
export default withStyles(styles)(NewsMediaSlider);

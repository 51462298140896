/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import MarketplaceLPFAQs from '../../../MarketplacePage/Partials/Sellers/MarketplaceLPFAQs';

const FAQsTabPanel = ({ data }) => {
    const styles = {
        padding: '5% 0',
        backgroundColor: '#F7F7F7',
        fontFamily: 'PlayfairDisplayRegular, serif',
        margin: '0 auto',
    };

    return (
        <MarketplaceLPFAQs block={data?.marketplace_lp_faqs} style={styles} />
    );
};

FAQsTabPanel.propTypes = {
    data: object,
};

FAQsTabPanel.defaultProps = {
    data: {},
};

export default FAQsTabPanel;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { string, shape } from 'prop-types';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    messageContainer: {
        maxWidth: '980px',
        width: '85%',
        margin: '50px auto 0',
        fontSize: '18px',
        [theme.breakpoints.only('xs')]: {
            width: '95%',
            marginTop: '30px',
        },
    },
    messageImageContainer: {
        textAlign: 'center',
        width: '100%',
    },
    mainMessageTextImg: {
        margin: '0 auto',
    },
    mainMsgHeadingTxt: {
        textAlign: 'center',
        fontFamily: 'Alisha, Dancing, Crimson, Serif',
        fontSize: '46px',
        margin: '0',
    },
    msgParagraph: {
        '& h1,h2,h3,h4': {
            fontSize: 28,
            margin: '15px 0 5px 0',
            [theme.breakpoints.down('xs')]: {
                fontSize: 22,
            },
        },
        textAlign: 'center',
    },
}));

const ShareMoreMainMessage = ({ data }) => {
    const classes = useStyles();
    const imageHeaderUrl = data.heading_image?.url || '';
    const HeadingText = data.heading_text || '';
    const paragraph = data.paragraph || '';
    return (
        <div className={classes.messageContainer}>
            {imageHeaderUrl && (
                <div className={classes.messageImageContainer}>
                    <img className={classes.mainMessageTextImg} src={imageHeaderUrl} alt="heading" style={{ marginBottom: '0', maxWidth: data.image_max_width || '600px' }} />
                </div>
            )}
            {HeadingText && <h2 className={classes.mainMsgHeadingTxt}>{HeadingText}</h2>}
            {paragraph && <div className={classes.msgParagraph}><ReactMarkdown source={paragraph} escapeHtml={false} /></div>}
        </div>
    );
};

ShareMoreMainMessage.propTypes = {
    // classOverride: object.isRequired,
    data: shape({
        heading_image: shape({
            title: string,
            url: string,
        }),
        heading_text: string,
        paragraph: string,
    }),
};
ShareMoreMainMessage.defaultProps = {
    data: {},
};
export default (ShareMoreMainMessage);

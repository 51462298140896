/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Copy from '../../Copy';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import ResponsiveImage from '../../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';

const preStyle = {
    test: {
        transition: 'all .3s',
        '&:hover': {
            opacity: '.9',
        },
    },
};

const styles = (theme) => ({
    test: preStyle.test,
    threeUp: {
        margin: '2-0px auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    panel: {
        height: '500px',
        width: '100%',
        textDecoration: 'none',
    },
    headline: {
        margin: `0 auto ${theme?.spacing(3)}px auto`,
        color: theme?.palette?.common?.black, // TODO: Font color should come from CMS
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    Even: {
        '& > a': {
            width: '100%',
            minHeight: '300px',
        },
        '& > a:nth-child(even)': {
            margin: '18px 0',
        },
    },
    mobileRoot: {
        display: 'block',
        margin: '15px auto',
    },
    bannerImage: {
        width: '100%',
        height: 'auto',
    },
});

const ThreeUpMobile = ({ classes, data, trackEvent }) => {
    if (data?.reference?.[0]) {
        const banner = data.reference[0];
        if (banner.reference?.[0]?.image_mobile) {
            return (
                <Link
                    to={banner.reference[0].linking?.link?.href}
                    title={banner.reference[0].linking?.link?.title}
                    className={classes.mobileRoot}

                >
                    <ResponsiveImage className={classes.bannerImage} path={banner.reference[0].image_mobile?.url} alt={banner.reference[0].linking?.link?.description || 'Product Image'} params={{}} />
                </Link>
            );
        }
        if (banner.panel_row) {
            return (
                <div className={`${classes.threeUp} ${classes.Even}`}>
                    {
                        banner.panel_row[0]?.panel?.reference?.map((panelItem) => (
                            <Link
                                key={panelItem?.linking?.link?.href}
                                to={panelItem?.linking?.link?.href}
                                title={panelItem?.linking?.link?.title}
                                className={classes.panel}
                                style={{
                                    backgroundImage: `url(${panelItem?.image?.url}?auto=webp)`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                    backgroundSize: 'cover',
                                    padding: '20px',
                                    boxSizing: 'border-box',

                                }}
                                data-ga-category={panelItem?.linking?.tracking_event_category || ''}
                                data-ga-action={panelItem?.linking?.tracking_event_action || ''}
                                data-ga-label={panelItem?.linking?.tracking_event_label || ''}
                                onClick={() => {
                                    trackEvent({
                                        eventCategory: panelItem?.linking?.tracking_event_category || '',
                                        eventAction: panelItem?.linking?.tracking_event_action || '',
                                        eventLabel: panelItem?.linking?.tracking_event_label || '',
                                    });
                                }}
                            >
                                <Copy className={classes.headline} data={panelItem?.message_collection} styling={!panelItem?.message_styling ? panelItem?.message_styling : ['']} />
                            </Link>
                        ))
                    }
                </div>
            );
        }
    }
    return <></>;
};

ThreeUpMobile.propTypes = {
    data: object.isRequired,
    classes: object.isRequired,
    trackEvent: func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(withStyles(styles)(ThreeUpMobile));

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { uid } from 'react-uid';
import {
    shape, string, arrayOf,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';

const useStyles = makeStyles((theme) => ({
    benefitsAndCommunityContainer: {
        backgroundColor: '#cbbdd8',
        margin: '0 auto',
        maxWidth: '100%',
        paddingBottom: '50px',
        [theme.breakpoints.down(700)]: {
            textAlign: 'center',
        },
    },
    bandcTitleWrapper: {
        margin: '0 auto',
        maxWidth: '1440px',
        [theme.breakpoints.down(700)]: {
            maxWidth: '700px',
        },
    },
    bandcTitleContainer: {
        margin: '0 auto 0 5%',
        maxWidth: '1440px',
        padding: '60px 0 10px',
        '& p': {
            fontSize: '24px',
            maxWidth: '55%',
        },
        '& p:nth-child(1)': {
            fontFamily: 'latobold, serif',
            color: '#fff',
            textTransform: 'uppercase',
            textDecoration: 'underline',
        },
        '& p:nth-child(2)': {
            fontFamily: 'PlayfairDisplay, serif',
        },
        [theme.breakpoints.down(700)]: {
            margin: '0 auto',
            maxWidth: '80%',
            paddingTop: '310px',
            '& p': {
                fontSize: '16px',
                maxWidth: '100%',
            },
            '& p:nth-child(1)': {
                fontSize: '20px',
            },
        },
    },
    bandcList: {
        margin: '0 auto 0 5%',
        maxWidth: '1440px',
        paddingBottom: '50px',
        display: 'flex',
        justifyContent: 'start',
        [theme.breakpoints.down(700)]: {
            flexWrap: 'wrap',
            margin: '0 auto',
            maxWidth: '90%',
            paddingBottom: '80px',
            justifyContent: 'space-evenly',

        },
    },
    bandcListItem: {
        flex: '0 0 23%',
        padding: '0 2% 0 0',
        textAlign: 'left',
        '& p:nth-child(1)': {
            fontSize: '24px',
            fontFamily: 'PlayfairDisplayBold, serif',
        },
        '& p:nth-child(2)': {
            fontSize: '16px',
            fontFamily: 'latolight, serif',
        },
        [theme.breakpoints.down(700)]: {
            flex: '0 0 45%',
            padding: '0 1%',
            textAlign: 'center',
            '& p:nth-child(1)': {
                fontSize: '17px',
                fontFamily: 'PlayfairDisplayBold, serif',
            },
            '& p:nth-child(2)': {
                fontSize: '14px',
                fontFamily: 'latolight, serif',
            },
        },
    },
    bandCopy: {
        marginTop: 0,
    },
    bandTitle: {
        marginBottom: 0,
    },
}));

const MarketplaceLPBenefits = ({ block }) => {
    const isMobile = useSelector(getSSRDeviceType) === 'mobile';

    const mobileBenefitsAndCommunityList = block?.list?.slice() || [];
    if (isMobile && mobileBenefitsAndCommunityList && mobileBenefitsAndCommunityList.length > 0) {
        [mobileBenefitsAndCommunityList[1], mobileBenefitsAndCommunityList[3]] = [mobileBenefitsAndCommunityList[3], mobileBenefitsAndCommunityList[1]];
    }

    const classes = useStyles();

    return (
        <div className={classes.benefitsAndCommunityContainer}>
            <div className={classes.bandcTitleWrapper}>
                <div className={classes.bandcTitleContainer}>
                    <p>{block?.title}</p>
                    <p>{block?.subtitle}</p>
                </div>
                <div className={classes.bandcList}>
                    {isMobile ? (mobileBenefitsAndCommunityList.map((item, index) => {
                        const { title, copy } = item;
                        return (
                            <div className={classes.bandcListItem} key={uid(item, index)}>
                                <p className={classes.bandTitle}>{title}</p>
                                <p className={classes.bandCopy}>{copy}</p>
                            </div>
                        );
                    })) : (block?.list?.map((item, index) => {
                        const { title, copy } = item;
                        return (
                            <div className={classes.bandcListItem} key={uid(item, index)}>
                                <p className={classes.bandTitle}>{title}</p>
                                <p className={classes.bandCopy}>{copy}</p>
                            </div>
                        );
                    }))}
                </div>
            </div>
        </div>
    );
};

MarketplaceLPBenefits.propTypes = {
    block: shape({
        title: string,
        subtitle: string,
        list: arrayOf(shape({
            title: string,
            copy: string,
        })),
    }).isRequired,
};

export default MarketplaceLPBenefits;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import  Grid from '@material-ui/core/Grid';
import {
    shape, string, bool, number, object, arrayOf,
} from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    container: ({ data }) => ({
        maxWidth: 1400,
        margin: '0 auto',
        borderTop: data.needs_top_border ? `8px solid ${theme.palette.common?.green}` : '',
        backgroundColor: data.background_color?.color || '#fff',
    }),
    columnContainerBorderTop: {
        margin: '20px auto',
        borderTop: `2px solid ${theme.palette.crDividerColor}`,
    },
    columnContainerBorderBottom: {
        margin: '20px auto',
        borderTop: `2px solid ${theme.palette.crDividerColor}`,
    },
    mainTitle: ({ data }) => ({
        display: data.title ? 'block' : 'none',
        margin: '25px 0px 35px 0',
        textAlign: 'center',
        fontWeight: 400,
        color: data.copy_color?.color || '#000',
    }),
    columnContainer: ({ data }) => ({
        '& > div:first-child': {
            // eslint-disable-next-line no-nested-ternary
            borderRight: data.needs_vertical_border ? data.title === 'Frequently Asked Questions' ? `1px dotted ${theme.palette.common?.white}` : `2px solid ${theme.palette.crDividerColor}` : '',
        },
        paddingBottom: data.title === 'Frequently Asked Questions' ? '15px' : '',
        color: data.copy_color?.color || '#000',
        textAlign: 'center',
        '& img': {
            maxWidth: '60px',
            padding: '10px 0',
        },
    }),
    column: ({ data }) => ({
        padding: '0 5%',

        '& li': {
            textAlign: data.description === 'Bry About the Program' ? 'left' : 'center',
            fontSize: '14px !important',
        },
    }),
    header: {
        '& h1, h2, h3, h4, h5, h6, p': {
            margin: '0 auto 20px',
            fontSize: '28px !important',
            textAlign: 'center',
            background: theme.palette.cms?.primary || theme.palette.colorPrimary,
            borderTop: `8px solid ${theme.palette.common?.green}`,
            color: theme.palette.common.white,
            padding: '5px 0',
        },
    },
    title: {
        fontWeight: 'bold',
        paddingBottom: '5px',
    },
    description: {
        textAlign: 'center',
        '& p': {
            fontSize: 14,
            lineHeight: '1.4',
        },
    },
    checkBoxMainContainer: {
        display: 'flex',
    },
    button: {
        textAlign: 'center',
        paddingBottom: '20px',
        '& a': {
            color: theme.palette.common?.white,
            background: theme.palette.common?.green,
            padding: '8px 25px',
            display: 'inline-block',
            textDecoration: 'none',
            textTransform: 'uppercase',
            fontSize: 14,
            margin: '10px 0',
        },
    },
    rightCopy: {
        '& a': {
            color: '#000',
            fontWeight: 'bold',
        },
    },
    leftCopy: {
        '& a': {
            color: theme.palette?.primary?.main || '#000000',
            fontWeight: 'bold',
            textDecoration: 'underline',
        },
    },
    aboutUsLeft: ({ brand }) => ({
        '& > div:first-child': {
            padding: '0 40px 0 0',
        },
        '& a': {
            color: theme.palette?.primary?.main || '#000000',
            fontWeight: (brand.domain === 'berries') ? 'normal' : 'bold',
            textDecoration: 'underline',
        },
    }),
    aboutUsRight: ({ data }) => ({
        background: data.right.background_color.color || theme.palette.colorNeutral40,
        padding: 20,
        textAlign: 'center',
        '& span': {
            display: 'block',
            fontSize: 14,
            padding: '4px 3px',
        },
    }),
    borderRight: {
        borderRight: `2px solid ${theme.palette.crDividerColor}`,
    },
    benefitsBlock: {
        textAlign: 'left',
        maxWidth: '60%',
        margin: 'auto',
        '& svg': {
            fontSize: 18,
            verticalAlign: 'text-top',
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: '100%',
        },
    },
}));

const TwoColumnCopy = ({ data, brand }) => {
    if (Object.entries(data).length === 0) {
        return null;
    }
    const classes = useStyles({ data, brand });

    return (
        <Grid container className={classes.container}>
            {data.header?.copy
                && <Grid className={classes.header} item xs={12}><ReactMarkdown source={data.header?.copy} escapeHtml={false} /></Grid>}
            {data.needs_dividing_borders
                && <Grid className={classes.columnContainerBorderTop} item xs={12} />}
            {data.title
                && <Grid item xs={12}><div className={classes.mainTitle}>{data.title}</div></Grid>}
            <Grid container className={classes.columnContainer}>
                <Grid item xs={data.left?.grid_size} className={data.description === 'Affiliate About Us' ? classes.aboutUsLeft : classes.column}>
                    <div className={classes.description}>
                        <div className={classes.title}>{data.left?.title}</div>
                        {data.left?.image
                            && <img src={data.left?.image?.url} alt={data.left?.image?.title} />}
                        <ReactMarkdown key={data.left?.copy} source={data.left?.copy} escapeHtml={false} className={classes.leftCopy} />
                    </div>
                </Grid>
                <Grid item xs={data.right?.grid_size} className={data.description === 'Affiliate About Us' ? classes.aboutUsRight : classes.column}>
                    <div className={classes.description}>
                        <div className={classes.title}>{data.right?.title}</div>
                        {data.right?.image
                            && <img src={data.right?.image?.url} alt={data.right?.image?.title} />}
                        <ReactMarkdown key={data.right?.copy} source={data.right?.copy} escapeHtml={false} className={classes.rightCopy} />
                        {data.right?.cta?.link?.href
                            && <div className={classes.button}> <a href={data.right?.cta?.link?.href}>{data.right?.cta?.link?.title}</a></div>}
                        {data.right?.bottom_title
                            && <div className={classes.title}>{data.right?.bottom_title}</div>}
                        {data.right?.checkmarks
                            && <div className={classes.benefitsBlock}>  {data.right?.checkmarks?.map((item) => (<div key={item.copy} className={classes.checkBoxMainContainer}><span> <CheckIcon /></span><span>{item.copy}</span></div>))}</div>}
                    </div>
                </Grid>
            </Grid>
            {data.needs_dividing_borders
                && <Grid className={classes.columnContainerBorderBottom} item xs={12} />}
        </Grid>
    );
};

TwoColumnCopy.propTypes = {
    data: shape({
        header: shape({
            copy: string.isRequired,
            top_border_color: shape({
                color: string.isRequired,
            }),
            background_color: shape({
                color: string.isRequired,
            }),
        }).isRequired,
        title: string.isRequired,
        description: string.isRequired,
        background_color: shape({
            color: string.isRequired,
        }),
        copy_color: shape({
            color: string.isRequired,
        }),
        needs_dividing_borders: bool.isRequired,
        needs_top_border: bool.isRequired,
        needs_vertical_border: bool.isRequired,
        left: shape({
            grid_size: number.isRequired,
            title: string.isRequired,
            image: shape({
                url: string.isRequired,
            }),
            copy: string.isRequired,
        }).isRequired,
        right: shape({
            grid_size: number.isRequired,
            title: string.isRequired,
            image: shape({
                url: string.isRequired,
            }),
            copy: string.isRequired,
            background_color: shape({
                color: string.isRequired,
            }),
            bottom_title: string.isRequired,
            checkmarks: arrayOf(shape({
                copy: string.isRequired,
            })),
        }).isRequired,
    }),
    brand: object.isRequired,
};

TwoColumnCopy.defaultProps = {
    data: {},
};

export default TwoColumnCopy;

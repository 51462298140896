/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import  Grid from '@material-ui/core/Grid';
import {
    shape, string, arrayOf,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    contactUs: {
        textAlign: 'center',
        '& p': {
            fontSize: 14,
        },
    },
    title: {
        fontWeight: 600,
        margin: '25px 0 10px',
    },
    contactUsSubtitle: {
        fontSize: '14px',
        fontWeight: 600,
    },
    contactUsInner: {
        maxWidth: '90%',
        margin: 'auto',
        '& a': {
            color: theme.palette.cms?.primary || theme.palette.colorPrimary,
            fontSize: 14,
        },
        '& span': {
            fontSize: 14,
        },
        [theme.breakpoints.down(600)]: {
            wordWrap: 'break-word',
        },
    },
    contactUsTop: {
        borderBottom: `1px dotted ${theme.palette.common?.black}`,
    },
    setBoderOnContactus: {
        borderLeft: `1px dotted ${theme.palette.common?.black}`,
        margin: '20px 0',
        '&:first-child': {
            borderLeft: 'none',
        },
    },
}));

const ContactUsAffiliate = ({ data }) => {
    if (Object.entries(data).length === 0) {
        return null;
    }
    const classes = useStyles();

    return (
        <Grid container className={classes.contactUs}>
            <Grid item xs={12} className={classes.title}><span>{data.title}</span></Grid>
            <Grid container className={classes.contactUsTop}>
                {data.top_block.map((info) => (
                    <Grid item xs={4} key={info.item.item_title} className={classes.setBoderOnContactus}>
                        <div className={classes.contactUsInner}>
                            <span className={classes.contactUsSubtitle}> {info.item.item_title}</span>
                            <ReactMarkdown key={info.item.title} source={info.item.item_description} escapeHtml={false} />
                        </div>
                    </Grid>
                ))}
            </Grid>
            <Grid container>
                {data.bottom_block.map((info) => (
                    <Grid item xs={4} key={info.item.item_title} className={classes.setBoderOnContactus}>
                        <div className={classes.contactUsInner}>
                            <span className={classes.contactUsSubtitle}>  {info.item.item_title}</span>
                            <ReactMarkdown key={info.item.item_title} source={info.item.item_description} escapeHtml={false} />
                        </div>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

ContactUsAffiliate.propTypes = {
    data: shape({
        title: string.isRequired,
        top_block: arrayOf(shape({
            item: shape({
                item_title: string.isRequired,
                item_description: string.isRequired,
            }),
        })),
        bottom_block: arrayOf(shape({
            item: shape({
                item_title: string.isRequired,
                item_description: string.isRequired,
            }),
        })),
    }),
};

ContactUsAffiliate.defaultProps = {
    data: {},
};

export default ContactUsAffiliate;

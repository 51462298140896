/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object, arrayOf, shape, string,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// redux, hooks, helpers
import { Grid } from '@material-ui/core';
import HostedDinnerHeader from './Partials/HostedDinnerHeader';
import HostedDinnerEvents from './Partials/HostedDinnerEvents';
import HostedDinnerBanners from './Partials/HostedDinnerBanners';
import HostedDinnerTestimonials from './Partials/HostedDinnerTestimonials';
import HostedDinnerImageGallery from './Partials/HostedDinnerImageGallery';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '10px auto',
            padding: 5,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '10px auto',
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1440,
            margin: '10px auto',
            padding: 5,
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1280,
            margin: '10px auto',
            padding: 5,
        },
    },
});

const HostedDinnerBlock = ({ data, classes, classOverride }) => {
    const block = data?.optional_reference?.[0] || {};
    if (Object.entries(block).length === 0) {
        return null;
    }
    const header = block?.header || {};
    const events = block?.events || {};
    const banners = block?.banners || [];
    const testimonials = block?.testimonials || [];
    const gallery = block?.gallery || {};

    // regex remove text "font-family:" from string
    const setFontFamily = (fontData) => {
        let getFont = {};
        if (fontData) {
            getFont = {
                fontFamily: fontData?.replace(/font-family: |;/g, ''),
            };
        }
        return getFont;
    };

    const backGroundColorContainer = {
        backgroundColor: block?.background_color?.color || '#404251',
    };

    return (
        <Grid container className={`${classes.container} ${classOverride.hostedMainConatinerDiv}`} style={backGroundColorContainer}>
            {Object.keys(header).length !== 0 && <HostedDinnerHeader classOverride={classOverride} headerData={header} setFontFamily={setFontFamily} />}
            {Object.keys(events).length !== 0 && <HostedDinnerEvents eventData={events} setFontFamily={setFontFamily}  />}
            {Object.keys(banners).length !== 0 && <HostedDinnerBanners bannerData={banners} setFontFamily={setFontFamily}  />}
            {Object.keys(testimonials).length !== 0 && <HostedDinnerTestimonials testimonialData={testimonials} setFontFamily={setFontFamily}  />}
            {Object.keys(gallery).length !== 0 && <HostedDinnerImageGallery classOverride={classOverride} imageGallery={gallery} setFontFamily={setFontFamily}  />}
        </Grid>
    );
};
HostedDinnerBlock.propTypes = {
    classes: object.isRequired,
    classOverride: object.isRequired,
    data: shape({
        optional_reference: arrayOf(shape({
            heading: string,
            events: shape({
                event: arrayOf(shape({
                    sub_title: string,
                    title: string,
                    event_image: shape({
                        title: string,
                        url: string,
                    }),
                    link: shape({
                        link: shape({
                            href: string,
                            title: string,
                        }),
                    }),
                    logo: shape({
                        title: string,
                        url: string,
                    }),
                })),
            }),
        })),
    }),
};
HostedDinnerBlock.defaultProps = {
    data: {},
};

export default withStyles(styles)(HostedDinnerBlock);

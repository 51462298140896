/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    func,
    object,
    string,
} from 'prop-types';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactMarkdown from 'react-markdown';
import useSSRMediaQuery from '../../../../helpers/hooks/useSSRMediaQuery';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { ifExternalUrl } from '../../NUp/helper';
import { openPassportSideBar } from '../../../../../state/ducks/Passport/Passport-Action';
import { getFeatureFlags } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import CTA from '../../Buttons/CTA';
import replaceSpecialChar from '../../../../helpers/replaceSpecialChar';

// match css value between "font-family:" and ";"
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const themes = {
    rootmain: {
        variant_a: {
            display: 'flex',
            backgroundColor: '#f9f8f2',
            flexDirection: 'row-reverse',
            height: 'auto',
            position: 'relative',
            marginBottom: '0px',
        },
    },
    rootMainMobile: {
        variant_a: {
            position: 'relative',
        },
    },
    rootMaiCopy: {
        default: {
            position: 'relative',
        },
        variant_a: {
            position: 'absolute',
            padding: '0px',
            top: '15%',
            color: '#ffffff',
            marginTop: '0px',
            right: '0',
            width: '84%',
            margin: '0 auto',
        },
    },
    secondaryVariant: {
        default: {
            bottom: '-1px',
            right: '-10px',
            width: '530px',
            height: '260px',
            backgroundColor: '#fff',
            border: 'solid 0 #fff',
            padding: '50px 20px 30px 50px',
            color: '#000000',
        },
        variant_a: {
            position: 'absolute',
            fontSize: '1.3em',
            width: '30.88%',
            borderStyle: 'solid',
            borderImage: 'initial',
            bottom: '82px',
            height: '200px',
            right: '30px',
            padding: '22px 0px 0px 30px',
            border: 'none',
            backgroundColor: 'transparent',
            color: '#ffffff',
        },
    },
    primaryVariant: {
        default:
        { background: '#fff' },
        variant_a: {
            height: 'auto',
            background: 'none',
        },
    },
    messagePrimaryBtn: {
        variant_a: {
            backgroundColor: '#2f2f2f',
            color: '#ffffff',
            textDecoration: 'none',
            textAlign: 'center',
            padding: '14px 0px',
            margin: '16px 0px',
            position: 'relative',
            zIndex: '1',
            width: '42%',
            paddingBottom: '0px',
            fontSize: '2.5em',
            paddingLeft: '35px',
            lineHeight: '1.22em',
            borderRadius: '5px',
        },
    },
    messagePrimarySvgBtn: {
        variant_a: {
            fontSize: '0px',
        },
    },
    messagePrimaryCopy: {
        variant_a: {
            padding: '20px',
            paddingBottom: '0px',
            lineHeight: '1.5em',
            minWidth: '305px',
        },
    },
    copyVariant: {
        variant_a: {
            width: '65%',
        },
    },
    copyVariantResponsive: {
        variant_a: {
            width: '100%',
        },
    },
    messageSecondaryImage: {
        variant_a: {
            height: 'auto',
            bottom: '0px',
            padding: '6.5vw 5vw',
            width: '80%',
            margin: '30px auto 0px',
        },
    },
    messageSecondaryImageResponsive: {
        variant_a: {
            fontSize: '1em',
            bottom: '20px !important',
        },
    },
    secondaryImageResponsive: {
        variant_a: {
            bottom: '50px',
            padding: '20px',
        },
    },
    secondaryImageTab: {
        variant_a: {
            width: '450px',
            right: '0',
            lineHeight: '1em',
            height: '260px',
        },
    },
    messagePrimaryParagraph: {
        variant_a: {
            width: '83%',
            lineHeight: '1.5em',
        },
    },
    messagePrimaryResponsive: {
        variant_a: {
            lineHeight: '1em',
            fontSize: '2.4em',
            padding: '30px 0px 18px 10px',
        },
    },
    tabletResponsive: {
        variant_a: {
            width: '38vw',
            height: 'auto',
            minWidth: '340px',
            paddingBottom: '10vw',
            backgroundColor: '#ffffff',
        },
    },
    tabletResponsiveSecondary: {
        variant_a: {
            width: '39%',
            height: '330px',
            alignSelf: 'flex-end',
            borderWidth: '10px 10px 0px',
            fontSize: '2.3em',
            padding: '20px 20px 30px 25px',
            borderStyle: 'solid',
            borderColor: ' rgb(255, 255, 255)',
        },
    },
    tabletResponsiveSecondaryImage: {
        variant_a: {
            width: '390px',
        },
    },
    backgroundImage: {
        variant_a: {
            backgroundSize: 'cover',
            marginLeft: '0px',
        },
    },
};

const useStyles = makeStyles((theme) => ({
    root: (heroData) => ({
        display: themes.rootmain[heroData?.presentation_style]?.display || 'block',
        flexDirection: themes.rootmain[heroData?.presentation_style]?.flexDirection || '',
        position: themes.rootmain[heroData?.presentation_style]?.position || 'relative',
        height: themes.rootmain[heroData?.presentation_style]?.height || '775px',
        backgroundColor: themes.rootmain[heroData?.presentation_style]?.backgroundColor || '',
        width: 'auto',
        maxWidth: '1400px',
        margin: themes.rootmain[heroData?.presentation_style]?.margin || '0 auto 64px',
        '& *': { transition: 'all .3s' },
        '@media screen and (max-width: 1150px)': {
            marginBottom: '128px',
        },
        '@media screen and (max-width: 900px)': {
            display: 'flex',
            flexDirection: themes.rootmain[heroData?.presentation_style]?.flexDirection || 'column',
            marginBottom: '64px',
        },
        '@media screen and (max-width: 768px) and (min-width:601px)': {
            height: '775px',
            background: 'none',
            marginBottom: themes.rootmain[heroData?.presentation_style]?.marginBottom || '',
        },
        '@media screen and (max-width: 600px)': {
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            background: 'none !important',
            marginTop: '20px',
            marginBottom: '42px',
            position: themes.rootMainMobile[heroData?.presentation_style]?.position || '',
            '& br': {
                display: 'none',
            },
        },
    }),

    backgroundImage: (heroData) => ({
        height: '775px',
        width: '1480px',
        background: `url(${heroData?.main_background_image?.desktop?.url}?auto=webp) no-repeat center center`,
        backgroundSize: 'cover',
        '@media screen and (max-width: 1440px)': {
            width: '100%',
        },
        '@media screen and (max-width: 1024px)': {
            height: '550px',
            width: 'auto',
            background: `url(${heroData?.main_background_image?.tablet?.url ? heroData?.main_background_image?.tablet?.url : heroData?.main_background_image?.tablet?.url || heroData?.main_background_image?.desktop?.url}?auto=webp) no-repeat right center`,
        },
        '@media screen and (max-width: 600px)': {
            marginLeft: themes.backgroundImage[heroData?.presentation_style]?.marginLeft || '25px',
            background: `url(${heroData?.main_background_image?.mobile?.url ? heroData?.main_background_image?.mobile?.url : heroData?.main_background_image?.tablet?.url || heroData?.main_background_image?.desktop?.url}?auto=webp) no-repeat right center`,
            backgroundSize: 'cover',
        },
    }),
    messagePrimary: (heroData) => ({
        textDecoration: 'none',
        width: '520px',
        height: themes.primaryVariant[heroData?.presentation_style]?.height || '520px',
        top: '-1px',
        left: '-1px',
        background: themes.primaryVariant[heroData?.presentation_style]?.background || '#fff',
        backgroundSize: 'cover',
        backgroundImage: heroData.message?.[0].background_image?.url && `url(${heroData.message?.[0].background_image?.url}?auto=webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        padding: '90px 20px 30px 0',
        color: heroData.message?.[0].copy_color?.color || theme.palette.cms?.primary || theme.palette.colorPrimary,
        '& button': {
            borderRadius: themes.messagePrimaryBtn[heroData?.presentation_style]?.color || '',
            color: themes.messagePrimaryBtn[heroData?.presentation_style]?.color || '#333333',
            backgroundColor: themes.messagePrimaryBtn[heroData?.presentation_style]?.backgroundColor || '#ffffff',
            '& svg': {
                fontSize: themes.messagePrimarySvgBtn[heroData?.presentation_style]?.fontSize || '',
            },
        },
        '& h2, & h3': {
            margin: '0',
            lineHeight: themes.messagePrimaryBtn[heroData?.presentation_style]?.lineHeight || '1em',
            fontSize: '4em',
            fontWeight: 'normal',
            fontFamily: `${parseFont(heroData.message?.[0].heading_font)}` || theme.typography.body1,
            '@media screen and (max-width: 1100px) and (min-width:600px)': {
                fontSize: themes.messagePrimaryBtn[heroData?.presentation_style]?.fontSize || '',
                lineHeight: themes.messagePrimaryResponsive[heroData?.presentation_style]?.lineHeight || '',
            },
        },
        '& p': {
            fontFamily: `${parseFont(heroData.message?.[0].copy_font)} ` || theme.typography.body1,
            width: themes.messagePrimaryParagraph[heroData?.presentation_style]?.width || '',
            lineHeight: themes.messagePrimaryParagraph[heroData?.presentation_style]?.lineHeight || '',
        },
        '@media screen and (max-width: 2600px) and (min-width:768px)': {
            paddingLeft: themes.messagePrimaryBtn[heroData?.presentation_style]?.paddingLeft || '',
            paddingBottom: themes.messagePrimaryBtn[heroData?.presentation_style]?.paddingBottom || '',
        },
        '@media screen and (max-width: 1024px)': {
            width: '420px',
            height: '420px',
            backgroundImage: heroData.message?.[0].background_image?.url ? `url(${heroData.message?.[0].background_image_tablet?.url || heroData.message?.[0].background_image?.url}?auto=webp)` : '',
            padding: '50px 35px 20px 35px',
            '& h2, & h3': {
                fontSize: '3em',
            },
        },
        '@media screen and (max-width: 900px)': {
            width: '38vw',
            minWidth: themes.messagePrimaryCopy[heroData?.presentation_style]?.minWidth || '360px',
            height: 'auto',
            padding: themes.messagePrimaryCopy[heroData?.presentation_style]?.padding || '',
            paddingBottom: themes.messagePrimaryCopy[heroData?.presentation_style]?.paddingBottom || '10vw',
            '& p': {
                fontSize: '.9em',
            },
        },
        '@media screen and (max-width: 768px) and (min-width:601px)': {
            height: themes.tabletResponsive[heroData?.presentation_style]?.height || '',
            width: themes.tabletResponsive[heroData?.presentation_style]?.width || '',
            minWidth: themes.tabletResponsive[heroData?.presentation_style]?.minWidth || '',
            paddingBottom: themes.tabletResponsive[heroData?.presentation_style]?.paddingBottom || '',
            backgroundColor: themes.tabletResponsive[heroData?.presentation_style]?.backgroundColor || '',
        },
        '@media screen and (max-width: 600px)': {
            right: themes.rootMaiCopy[heroData?.presentation_style]?.right || '',
            width: themes.rootMaiCopy[heroData?.presentation_style]?.width || '85vw',
            margin: themes.rootMaiCopy[heroData?.presentation_style]?.margin || '',
            position: themes.rootMaiCopy[heroData?.presentation_style]?.position || 'relative',
            height: 'auto',
            padding: themes.rootMaiCopy[heroData?.presentation_style]?.padding || '30px 20px 20px 15px',
            top: themes.rootMaiCopy[heroData?.presentation_style]?.top || '',
            color: themes.rootMaiCopy[heroData?.presentation_style]?.color || '',
            marginTop: themes.rootMaiCopy[heroData?.presentation_style]?.marginTop || '-55vw',
            '& h2, & h3': {
                fontSize: themes.messagePrimaryResponsive[heroData?.presentation_style]?.fontSize || '3em',
                lineHeight: themes.secondaryImageTab[heroData?.presentation_style]?.lineHeight || '',
            },
            '& p': {
                fontSize: '1.2em',
            },
            backgroundImage: heroData.message?.[0].background_image?.url ? `url(${heroData.message?.[0].background_image_mobile?.url || heroData.message?.[0].background_image?.url}?auto=webp)` : '',
        },
        '@media screen and (max-width: 374px)': {
            padding: themes.messagePrimaryResponsive[heroData?.presentation_style]?.padding || '',
        },
    }),
    message: () => ({
        position: 'absolute',
        textDecoration: 'none',
    }),
    messageSecondary: (heroData) => ({
        textDecoration: 'none',
        position: themes.secondaryVariant[heroData?.presentation_style]?.position || '',
        bottom: themes.secondaryVariant[heroData?.presentation_style]?.bottom || '-1px',
        right: themes.secondaryVariant[heroData?.presentation_style]?.right || '-10px',
        width: themes.secondaryVariant[heroData?.presentation_style]?.width || '530px',
        height: themes.secondaryVariant[heroData?.presentation_style]?.height || '260px',
        backgroundColor: themes.secondaryVariant[heroData?.presentation_style]?.backgroundColor || '#fff',
        backgroundSize: 'cover',
        backgroundImage: `url(${heroData.message?.[1].background_image?.url}?auto=webp)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        border: themes.secondaryVariant[heroData?.presentation_style]?.border || 'solid 0 #fff',
        borderWidth: '10px 10px 1px 10px',
        padding: themes.secondaryVariant[heroData?.presentation_style]?.padding || '50px 20px 30px 50px',
        color: themes.secondaryVariant[heroData?.presentation_style]?.color || heroData.message?.[1].copy_color?.color || theme.palette.cms?.primary || theme.palette.colorPrimary,
        '& button': {
            borderRadius: themes.messagePrimaryBtn[heroData?.presentation_style]?.color || '',
            '& svg': {
                fontSize: themes.messagePrimarySvgBtn[heroData?.presentation_style]?.fontSize || '',
            },
        },
        '& h2, & h3': {
            margin: '0',
            fontSize: themes.secondaryVariant[heroData?.presentation_style]?.fontSize || '2.3em',
            lineHeight: '1em',
            fontWeight: 'normal',
            fontFamily: `${parseFont(heroData.message?.[1].heading_font)}` || theme.typography.body1,
            '@media screen and (max-width: 768px) and (min-width:600px)': {
                fontSize: themes.tabletResponsiveSecondary[heroData?.presentation_style]?.fontSize || '',
            },
        },
        '& p': {
            width: themes.copyVariant[heroData?.presentation_style]?.width || '',
            fontFamily: `${parseFont(heroData.message?.[1].copy_font)}` || theme.typography.body1,
            '@media screen and (max-width: 1100px) and (min-width:769px)': {
                fontSize: themes.messageSecondaryImageResponsive[heroData?.presentation_style]?.fontSize || '',
                width: themes.copyVariantResponsive[heroData?.presentation_style]?.width || '',
            },

        },
        '@media screen and (max-width: 2560px)and (min-width:1024px)': {
            width: themes.tabletResponsiveSecondaryImage[heroData?.presentation_style]?.width || '',
        },
        '@media screen and (max-width: 1150px)': {
            bottom: themes.messageSecondaryImageResponsive[heroData?.presentation_style]?.bottom || '-25px',
        },
        '@media screen and (max-width: 1024px)': {
            alignSelf: 'flex-end',
            height: themes.messageSecondaryImage[heroData?.presentation_style]?.height || '330px',
            bottom: themes.secondaryImageResponsive[heroData?.presentation_style]?.bottom || '',
            padding: themes.secondaryImageResponsive[heroData?.presentation_style]?.padding || '',
            borderWidth: '10px 10px 0',
            backgroundImage: heroData.message?.[1].background_image?.url ? `url(${heroData.message?.[1].background_image_tablet?.url || heroData.message?.[1].background_image.url}?auto=webp)` : '',
            '& p': {
                fontSize: '1.2em',
            },
        },
        '@media screen and (max-width: 855px) and (min-width:769px)': {
            width: themes.tabletResponsiveSecondary[heroData?.presentation_style]?.width || '50%',
        },
        '@media screen and (max-width: 768px) and (min-width:580px)': {
            borderStyle: themes.tabletResponsiveSecondary[heroData?.presentation_style]?.borderStyle || '',
            borderColor: themes.tabletResponsiveSecondary[heroData?.presentation_style]?.borderColor || '',
            padding: themes.tabletResponsiveSecondary[heroData?.presentation_style]?.padding || '',
            height: themes.tabletResponsiveSecondary[heroData?.presentation_style]?.height || '',
            alignSelf: themes.tabletResponsiveSecondary[heroData?.presentation_style]?.alignSelf || '',
            borderWidth: themes.tabletResponsiveSecondary[heroData?.presentation_style]?.borderWidth || '',
        },
        '@media screen and (max-width: 768px)': {
            height: themes.secondaryImageTab[heroData?.presentation_style]?.height || '',
            right: themes.secondaryImageTab[heroData?.presentation_style]?.right || '',
            width: themes.secondaryImageTab[heroData?.presentation_style]?.width || '',
        },
        '@media screen and (max-width: 600px)': {
            position: 'relative',
            alignSelf: 'center',
            margin: themes.messageSecondaryImage[heroData?.presentation_style]?.margin || '45px auto 32px',
            width: 'auto',
            height: themes.messageSecondaryImage[heroData?.presentation_style]?.height || '110vw',
            maxHeight: '330px',
            padding: themes.messageSecondaryImage[heroData?.presentation_style]?.padding || '10vw 8vw',
            right: 'auto',
            left: 'auto',
            top: 'auto',
            bottom: 'auto',
            backgroundImage: heroData.message?.[1].background_image?.url ? `url(${heroData.message?.[1].background_image_mobile?.url || heroData.message?.[1].background_image.url}?auto=webp)` : '',
            '& p': {
                width: themes.messageSecondaryImage[heroData?.presentation_style]?.width || '',
                fontSize: '1.2em',
            },
        },
    }),

    imageVariantA: {
        flexBasis: '55%',
        '@media screen and (max-width: 768px) and (min-width: 580px)': {
            flexBasis: 'auto',
            width: '100%',
        },
        '& img': {
            width: '100%',
        },
    },
    mainDesktopImage: {
        width: '100%',
        '& img': {
            width: '100%',
        },
    },
    mainMobileImage: {
        width: '100%',
        '& img': {
            width: '100%',
        },
    },
}));

const HeroSingleResponsive = ({
    data, isBot, trackEvent, // TODO- get isBot data
}) => {
    if (!data?.reference?.[0]) {
        return null;
    }
    const heroData = data.reference[0];
    const imageStyle = heroData.presentation_style  === 'variant_a';
    const classes = useStyles(heroData);
    if (!heroData?.message?.length) return <></>;
    const device = useSSRMediaQuery();
    return (
        <div
            className={classes.root}
            data-testid="hero-single-v2"
        >
            {isBot && <img src={`${heroData.main_background_image?.desktop?.url}?format=webp`} alt={heroData?.main_background_image?.desktop.title} />}
            {imageStyle ? (
                <div className={classes.imageVariantA}>
                    <Media query="(max-width: 600px)" defaultMatches={device === 'mobile'}>
                        {(matches) => (
                            matches ? (
                                <div className={classes.mainMobileImage}>
                                    <div className={classes.backgroundImage} />
                                </div>
                            ) : (
                                <div className={classes.mainDesktopImage}>
                                    <picture>
                                        <source srcSet={`${heroData.main_background_image?.desktop?.url}?auto=webp`} />
                                        <source srcSet={`${heroData.main_background_image?.desktop?.url}?auto=pjpg`} />
                                        <img src={`${heroData.main_background_image?.desktop?.url}?format=webp`} alt={heroData.main_background_image?.desktop?.title} />
                                    </picture>
                                </div>
                            )
                        )}
                    </Media>
                </div>
            )  : (
                <div className={classes.backgroundImage} />
            )}

            {heroData?.message?.map((message, i) => (
                <Link
                    data-ga-category={message.link.tracking_event_category || ''}
                    data-ga-action={message.link.tracking_event_action || ''}
                    data-ga-label={message.link.tracking_event_label || ''}
                    onClick={(e) => {
                        trackEvent({
                            eventCategory: message.link.tracking_event_category || '',
                            eventAction: message.link.tracking_event_action || '',
                            eventLabel: message.link.tracking_event_label || '',
                        });
                        ifExternalUrl(e, message.link.link.href, true);
                    }}
                    to={message.link.link.href}
                    title={message.link.link.title}
                    className={`${classes.message} ${!i ? classes.messagePrimary : classes.messageSecondary}`}
                >
                    <div className={classes.contentSection}>
                        <ReactMarkdown source={replaceSpecialChar(message.heading)} escapeHtml={false} />
                        {message.copy && <ReactMarkdown source={message.copy} escapeHtml={false} />}
                        {message.cta_copy && (
                            <CTA
                                backgroundColor={message?.cta_background_color?.color}
                                border={message.cta_border}
                                className={classes.ctaButton}
                                copy={message.cta_copy}
                            />
                        )}
                    </div>
                </Link>
            ))}
        </div>
    );
    // return <>TODO: This block implementation needs the includerefs in the YML or the findContentByUID which is still in progress</>;
};

HeroSingleResponsive.propTypes = {
    data: object.isRequired,
    trackEvent: func.isRequired,
    isBot: string.isRequired,
    // restrict_message_margins: bool,
};
// HeroSingleResponsive.defaultProps = {
//     isBot: false,
//     tabletbg: '',
//     panels: [],
//     message_styling: [],
//     tabletMobImage: false,
//     featureFlags: {},
//     passportOpenSideBar: () => {},
//     // restrict_message_margins: false,
// };

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),

});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeroSingleResponsive);

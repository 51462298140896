/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { string, shape, arrayOf } from 'prop-types';
import ChevronRight from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
    imageLinksContainer: {
        margin: '40px auto',
        fontSize: '18px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: '980px',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '760px',
            margin: '0 auto 2rem',
        },
    },
    heading: {
        textAlign: 'center',
        fontFamily: 'Crimson, Garamond, Times, serif',
        fontSize: '35px',
        fontWeight: '400',
        [theme.breakpoints.down('md')]: {
            marginBottom: '10px',
        },
    },
    blurb: {
        textAlign: 'center',
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto 40px',
    },
    section: {
        display: 'flex',
        '&:nth-child(even)': {
            flexDirection: 'row-reverse',
        },
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            '& img': {
                width: '100%',
            },
        },
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30px',
        },
    },
    copyContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            height: '90%',
        },
    },
    sectionTitle: {
        fontSize: '2rem',
        fontWeight: '400',
        width: '75%',
        fontFamily: 'crimson_textitalic',
        lineHeight: '42px',
        margin: '0 auto 10px',
        [theme.breakpoints.only('xs')]: {
            width: '90%',
        },
    },
    link: {
        fontWeight: '700',
        textDecoration: 'none',
        fontSize: '22px',
        textTransform: 'uppercase',
        fontFamily: 'sans-serif',
        color: theme.palette.black,
        '&::after': {
            content: '',
        },
    },
    iconStyle: {
        position: 'relative',
        top: '5px',
    },
    alternatingImage: {
        width: '100%',
        objectFit: 'cover',
        aspectRatio: '3 / 2',
        marginBottom: '5px',
    },
}));

const AlternatingImagesShowcase = ({ data }) => {
    const classes = useStyles();
    const { blurb, heading } = data;
    const imageSection = data.image_section || [];
    return (
        <div className={classes.imageLinksContainer}>
            {heading && <h2 className={classes.heading}>{heading}</h2>}
            {blurb && <div className={classes.blurb}>{blurb}</div>}
            {imageSection.length && imageSection.map((sect, index) => (
                <Grid
                    container
                    direction="row"
                    className={classes.section}
                    /* eslint-disable react/no-array-index-key */
                    key={index.toString()}
                >
                    <Grid item xs={12} sm={7}>
                        {sect.image && <img src={sect.image.url} alt={sect.section_title} className={classes.alternatingImage} />}
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <div className={classes.copyContainer}>
                            <div className={classes.sectionTitle}>{sect.section_title}</div>
                            <a
                                className={classes.link}
                                target="_blank"
                                rel="noreferrer"
                                href={sect.link?.href}
                                title={sect.link?.title}
                            >{sect.link?.title} <ChevronRight className={classes.iconStyle} />
                            </a>
                        </div>
                    </Grid>
                </Grid>
            ))}
        </div>
    );
};

AlternatingImagesShowcase.propTypes = {
    data: shape({
        blurb: string,
        heading: string,
        image_section: arrayOf(shape({
            image: shape({
                url: string,
            }),
            link: shape({
                href: string,
                title: string,
            }),
        })),
    }),
};
AlternatingImagesShowcase.defaultProps = {
    data: {},
};
export default (AlternatingImagesShowcase);

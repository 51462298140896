/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';
import WeddingsBlogDesktop from './WeddingsBlogViews/WeddingsBlogDesktop';
import WeddingsBlogMobile from './WeddingsBlogViews/WeddingsBlogMobile';

const WeddingsBlog = ({ data }) => (
    <DesktopMobile
        desktop={() => <WeddingsBlogDesktop data={data?.blogs} />}
        mobile={() =>  <WeddingsBlogMobile data={data?.blogs} />}
    />
);

WeddingsBlog.propTypes = {
    data: object,
};

WeddingsBlog.defaultProps = {
    data: {},
};

export default WeddingsBlog;

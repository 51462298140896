/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @description find product details on a list of part numbers
 * @param {string} domain - brand.domain
 * @param {array} partNumbers - array of SKU's to be fetched
 * @returns {object} data found on findProductByPartNumbers
 */

const findProductsByPartNumber = (domain, partNumbers) => {
    if (!partNumbers || !Array.isArray(partNumbers)) {
        console.error('findProductsByPartNumbers did not recieve valid partNumbers. partNumbers recieved:', partNumbers);
        return null;
    }

    // convert to valid GQL params
    const arrPartNumbers = `["${partNumbers.join('","')}"]`;

    return (
        gql`{findProductByPartNumbers(brand: "${domain}", environment: "${GRAPHQL_ENV}", partNumbers: ${arrPartNumbers}) {
              brand
              brandId
              id
              partNumber
              name
              isSmartgiftEnabled
              availability {
                deliveryMessage
                __typename
              }
              productSkus {
                isInternational
              }
              image {
                altText
                name
                path
                snipe
                snipeImagePath
                __typename
              }
              seo {
                url
              }
              skuPriceRange {
                sale {
                  value
                }
                retail {
                  value
                }
                __typename
              }
              __typename
            }
          }`);
};

export default findProductsByPartNumber;

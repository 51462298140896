/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object, arrayOf, shape, string, array,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ShareMoreHeader from './Partials/ShareMoreHeader';
import VideoModule from '../../../ContentTypeComponents/Video/Video';
import ShareMoreMainMessage from './Partials/ShareMoreMainMessage';
import AlternatingImagesShowcase from './Partials/ShareMoreAlternatingImagesShowcase';
import ShareMoreFooter from './Partials/ShareMoreFooter';
import NUp from '../../../ContentTypeComponents/NUp/NUp';
import WyngModule from '../../../ContentTypeComponents/Wyng/Wyng';
import BlockHeading from '../../../ContentTypeComponents/BlockHeading';
// import ShareMoreGallery from './Partials/ShareMoreGallery';

const styles = (theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '10px auto',
            padding: 5,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '1400px',
            margin: '10px auto',
            padding: 5,
        },
    },
    vidContainer: {
        margin: '0 auto',
    },
    videoHeader: {
        padding: '10px 15px',
        top: '40px',
        width: '300px',
        margin: '0 auto',
        display: 'block',
        position: 'relative',
        background: '#fff',
        textTransform: 'uppercase',
        fontSize: '32px',
        fontFamily: 'Caslon, Georgia, serif',
        fontSmoothing: 'antialiased',
        fontWeight: '300',
    },
    videoHeaderRule: {
        border: `1px solid ${theme.palette.buttonBackgroundColor}`,
        marginBottom: '30px',
    },
});

const ShareMoreBlock = ({ data, classes }) => {
    const block = data?.share_more_reference?.[0] || {};
    if (Object.entries(block).length === 0) {
        return null;
    }
    const header = block.header || {};
    const mainVideoSection = block.main_video_section || {};
    const mainMessage = block.main_message || {};
    const alternatingImagesShowcase = block.alternating_images_showcase || [];
    const hostedDinners = { reference: block.hosted_dinners_nup } || {};
    const recipeVideos = block.recipe_videos || {};
    const wyngGallery =  { reference: block?.wyng_gallery } || {}; // Use until gallery no longer WIP
    const footer = block?.footer || {};
    // const gallery = block?.gallery || {}; // WIP

    if (Object.keys(block).length > 0) {
        return (
            <Grid container className={classes.container}>
                {Object.keys(header).length !== 0 && <ShareMoreHeader headerData={header} />}
                {Object.keys(mainVideoSection).length !== 0 && <VideoModule data={mainVideoSection} />}
                {Object.keys(mainMessage).length !== 0 && <ShareMoreMainMessage data={mainMessage} escapeHtml={false} />            }
                {Object.keys(alternatingImagesShowcase).length !== 0 && <AlternatingImagesShowcase data={alternatingImagesShowcase} />}
                {hostedDinners?.heading && <BlockHeading data={hostedDinners} />}
                <NUp data={hostedDinners} />
                {Object.keys(recipeVideos).length !== 0 && (
                    <div className={classes.vidContainer}>
                        <h2 className={classes.videoHeader}>{recipeVideos.title}</h2>
                        <hr className={classes.videoHeaderRule} />
                        <VideoModule data={recipeVideos} />
                    </div>
                )}

                {Object.keys(wyngGallery).length !== 0 && <WyngModule data={wyngGallery} />}
                {/* Uncomment the implementation of the gallery below this line once it's built out to handle images with instagram info */}
                {/* {Object.keys(gallery).length !== 0 && <ShareMoreGallery imageGallery={gallery} />} */}

                {Object.keys(footer).length !== 0 && <ShareMoreFooter data={footer} />}

            </Grid>
        );
    }
    return <></>;
};
ShareMoreBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        optional_reference: arrayOf(shape({
            header: shape({
                header_paragraph: string,
                header_logo: string,
            }),
            footer: shape({
                copy: string,
                social_links_share_more: object,
            }),
            alternating_images_showcase: shape({
                heading: string,
                blurb: string,
                image_section: array,
            }),
            hosted_dinners_nup: shape({
                title: string,
                reference: array,
            }),
            main_message: shape({
                heading_image: object,
                heading_text: string,
                image_max_width: string,
                paragraph: string,
            }),
            main_video_section: object,
            recipe_videos: object,
            // gallery is WIP
            gallery: shape({
                heading: string,
                images: array,
                font_family: string,
            }),
            // Use wyng_gallery until gallery no longer WIP
            wyng_gallery: shape({
                reference: shape({
                    wyng_id: string,
                    wyng_token: string,
                }),
            }),
        })),
    }),
};
ShareMoreBlock.defaultProps = {
    data: {},
};

export default withStyles(styles)(ShareMoreBlock);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, shape, number,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import LinkOrATag from '../../../GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const styles = () => ({
    header: {
        display: 'block',
        position: 'relative',
        '& p,h1,h2,h3,h4': {
            margin: '5px 0px',
        },
        // TODO: Add decorative border, but at higher level
    },
    background_stroke: {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        '& p': {
            margin: '0 auto',
            padding: '0 .5em',
            textTransform: 'uppercase',
            fontSize: '1.1em',
            fontWeight: 400,
            lineHeight: '2.3em',
            display: 'inline-block',
            background: '#fff',
        },
        '&::after': {
            content: '""',
            position: 'relative',
            borderBottom: 'solid 1px #2f2f2f',
            display: 'block',
            width: '100%',
            top: '-1.37em',
            zIndex: '-1',
        },
    },
    VideoMainContainer: {
        '& a': {
            display: 'block',
            width: '100%',
            textDecoration: 'none',
        },
    },
});

const Header = ({
    classes, data,
}) => {
    const headingTitle = data?.heading_link;
    const titleFontStyle = data?.title_font_style || {};
    // regex remove text "font-family:" from string
    const setFontFamily = (fontData) => {
        let getFont = '';
        if (fontData) {
            getFont = fontData?.replace(/font-family: |;/g, '');
        }
        return getFont;
    };
    // use for heading style from CMS
    let headerFontStyle = {};
    headerFontStyle = {
        fontSize: titleFontStyle?.font_size || '1.3em',
        fontWeight: titleFontStyle?.font_weight || '300',
        color: titleFontStyle?.font_color?.color,
        fontFamily: setFontFamily(titleFontStyle?.font_family),
        textAlign: titleFontStyle?.text_alignment || 'center',
    };
    return (
        <div className={classes.VideoMainContainer}>
            {data?.show_as_heading_link ? (
                <LinkOrATag
                    style={headerFontStyle}
                    href={headingTitle?.href}
                >
                    {headingTitle?.title}
                </LinkOrATag>
            ) : (
                <div className={classes.headerContainer} style={headerFontStyle}>
                    <ReactMarkdown
                        className={`${classes.header} ${data?.background_stroke && classes.background_stroke}`}
                        source={data?.title}
                        escapeHtml={false}
                    />
                </div>
            )}
        </div>
    );
};

Header.propTypes = {
    data: shape({
        title: string,
        title_font_style: shape({
            font_family: string.isRequired,
            fontSize: number.isRequired,
            font_weight: string.isRequired,
            text_alignment: string.isRequired,
            font_color: shape({
                color: string.isRequired,
            }),
        }),
    }).isRequired,
    classes: object.isRequired,
};

export default withStyles(styles)(Header);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    object, string, shape, arrayOf, bool, number,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { useUIDSeed } from 'react-uid';

const styles = (theme) => ({
    bottomMainSection: {
        margin: '3em 0em 0em 0em',
        padding: '3em 0',
    },
    bottomInnerSection: {
        paddingBottom: '1.5em',
    },
    imageContainer: {
        '& img': {
            width: '100%',
            paddingRight: 15,
        },
    },
    copyContainer: {
        paddingLeft: 15,
        '& h1,h2,h3,h4,h5,h6': {
            margin: 0,
            fontSize: 24,
        },
        '& p': {
            margin: '6px 0px',
        },
        '& a': {
            color: theme.palette.cms?.linkTextColor || '#dd3337',
        },
    },
});

const ContentBottomBanner = ({
    classes, data,
}) => {
    const bottomSectionData = data?.bottom_section_banner || [];
    const borderData = data?.border_styling || {};
    const isBorder = data?.border_styling?.showing_border || false;
    const seed = useUIDSeed();

    // conatiner border styling
    let borderStylingData = {};
    if (isBorder) {
        borderStylingData = {
            borderBottom: `${borderData?.border_bottom || 1}px solid ${borderData?.border_color?.color || '#dedede'}`,
            borderTop: `${borderData?.border_top || 1}px solid ${borderData?.border_color?.color || '#dedede'}`,
        };
    }

    // image alignment showing left or right
    const imageAlignmentData = (imageUrl, altText) => (
        <Grid item xs={12} md={3}>
            <div className={classes.imageContainer}>
                <img src={imageUrl} alt={altText} />
            </div>
        </Grid>
    );

    return (
        <Grid container>
            <Grid item xs={12} style={borderStylingData} className={classes.bottomMainSection}>
                { bottomSectionData?.map((item) => (
                    <Grid container key={seed(item)} className={classes.bottomInnerSection}>
                        {!item?.bottom_section_styling?.image_on_right_place && imageAlignmentData(item?.image?.url, item?.image?.title)}
                        <Grid item xs={12} md={9}>
                            <div className={classes.copyContainer}>
                                <ReactMarkdown source={item?.copy_text} escapeHtml={false} />
                            </div>
                        </Grid>
                        {item?.bottom_section_styling?.image_on_right_place && imageAlignmentData(item?.image?.url, item?.image?.title)}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

ContentBottomBanner.propTypes = {
    classes: object.isRequired,
    data: shape({
        title: string,
        bottom_section_banner: arrayOf(shape({
            copy_text: string,
            image: shape({
                url: string,
                title: string,
            }),
            bottom_section_styling: shape({
                image_on_right_place: bool,
            }),
        })),
        border_styling: shape({
            border_bottom: number,
            border_top: number,
            showing_border: bool,
        }),
    }),
};
ContentBottomBanner.defaultProps = {
    data: {},
};

export default withStyles(styles)(ContentBottomBanner);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, bool, shape, array, object, number,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { useUIDSeed } from 'react-uid';
import IframeVideoModule from './IframeVideo';
import isIframeVideo from './isIframeVideo';
import HTML5 from './HTML5Video';

const styles = (theme) => ({
    setPaddingVideo: {
        paddingRight: '20px',
        [theme.breakpoints.down(680)]: {
            padding: 2,
        },

    },
    bottomHeadingText: {
        '& h1,h2,h3,h4,h5,h6': {
            margin: '4px 0px',
            fontSize: 24,
        },
        '& p': {
            fontSize: 14,
            margin: '2px 0px',
        },
    },
});

export function VideoInlineBlock({ classes, data, bottomText }) {
    if (data) {
        const width = data?.width ? `${data?.width}` : '100%';
        const height = data?.height ? `${data?.height}` : '100%';
        let videoArray = [];
        const seed = useUIDSeed();
        videoArray = data?.video_references;
        const setGridContainer = videoArray.length;
        // set the grid structure
        const gridContainer = () => {
            switch (setGridContainer) {
                case 1:
                    return 12;
                case 2:
                    return 6;
                default:
                    return 4;
            }
        };

        return (
            <Grid container>
                {videoArray?.map((videoData, key) => (
                    <Grid key={seed(videoData)} item xs={12} md={gridContainer()} className={classes.setPaddingVideo}>
                        { isIframeVideo(videoData)
                            ? (
                                <>
                                    <IframeVideoModule data={videoArray[key]} width={width} height={height} play_location={data?.play_location} autoplay={data?.autoplay} />
                                    {bottomText && (
                                        <div className={classes.bottomHeadingText}>
                                            <ReactMarkdown source={videoData?.video_text} escapeHtml={false} />
                                        </div>
                                    )}
                                </>
                            )
                            :  (
                                <>
                                    <HTML5 tracking={data?.tracking || {}} data={videoArray[key]} width={width} height={height} play_location={data?.play_location} autoplay={data?.autoplay} />
                                    {bottomText && (
                                        <div className={classes.bottomHeadingText}>
                                            <ReactMarkdown source={videoData?.video_text} escapeHtml={false} />
                                        </div>
                                    )}
                                </>
                            )}
                    </Grid>
                ))}
            </Grid>
        );
    }
}

VideoInlineBlock.propTypes = {
    data: shape({
        autoplay: bool,
        height: number,
        play_location: string,
        title: string,
        optional_reference: array,
        optional_panel_side: string,
        width: number,
        video_references: array,
    }).isRequired,
    bottomText: bool.isRequired,
    classes: object.isRequired,
};
export default withStyles(styles)(VideoInlineBlock);

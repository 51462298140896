/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape,
    string, arrayOf,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const useStyles = makeStyles(() => ({
    categoriesRoot: {
        width: '100%',
        maxWidth: 1440,
        margin: '20px auto',
    },
    imageBlock: {
        '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    contentBlock: {
        maxWidth: 400,
    },
    contentBlockMain: {
        padding: '0 0 0 40px',
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        fontSize: 32,
        textDecoration: 'underline',
        textUnderlineOffset: '6px',

    },
    subTitle: {
        fontSize: 14,
        padding: '15px 0px 30px',
    },
    ctaBtn: {
        padding: '5px 25px',
        textDecoration: 'none',
        fontSize: 14,
    },

}));

const ConsumerLPBlogDesktop = ({ data }) => {
    if (!data) return null;
    const classes = useStyles();
    let styleOverrides = {};
    let ctaStyle = {};
    const seed = useUIDSeed();
    return (
        <>
            {
                data?.map((blog, index) => {
                    styleOverrides = {
                        background: blog?.background_color?.color,
                    };
                    ctaStyle = {
                        background: blog?.cta_background_color?.color,
                        color: blog?.cta_text_color?.color,

                    };
                    const isOdd  = index % 2 !== 0;
                    return (
                        <Grid key={seed(blog)} direction={isOdd && 'row-reverse'} container className={classes.categoriesRoot} style={styleOverrides}>
                            <Grid item xs={12} md={6} className={classes.contentBlockMain}>
                                <div>
                                    <div className={classes.contentBlock}>
                                        <div className={classes.title}>{blog?.title}</div>
                                        <div className={classes.subTitle}>{blog?.sub_title}</div>
                                        <LinkOrATag style={ctaStyle} href={blog?.cta_button?.href} className={classes.ctaBtn} isTrackEventEnabled trackingEventLabel={blog?.cta_button?.title} ariaLabel={`Click to ${blog?.cta_button?.title}`}>
                                            {blog?.cta_button?.title}
                                        </LinkOrATag>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.imageBlock}>
                                <ResponsiveImage
                                    path={blog?.desktop_image?.url}
                                    alt={blog?.title}
                                    params={{}}
                                />
                            </Grid>
                        </Grid>
                    );
                })
            }
        </>
    );
};

ConsumerLPBlogDesktop.propTypes = {
    data: arrayOf(shape({
        cta_button: shape({
            href: string,
            title: string,
        }),
        desktop_image: shape({
            url: string,
        }),
        title: string,
        sub_title: string,
    })).isRequired,
};

export default ConsumerLPBlogDesktop;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, bool, shape,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { useMediaQuery } from '@material-ui/core';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import MobileHeroAccordion from './MobileHeroAccordion';
import { parseFont } from '../../../../../../helpers/common/helper';
import DesktopHeroLinklist from './DesktopHeroLinklist';
import getBackgroundColor from '../../../../../../helpers/getBackgroundColor';

const useStyles = makeStyles((theme) => ({
    hsWrapper: {
        maxWidth: '1600px',
        margin: '0 auto',
        display: 'block',
    },
    responsiveHeroImage: {
        width: '100%',
        display: 'block',
    },
    hsHero: {
        width: '100%',
    },
    hsGridContainer: {
        margin: '0px auto',
        display: 'block',
        position: 'relative',
    },
    hsCopyContainer: ({ copyData }) => ({
        textAlign: 'center',
        transform: 'translate(-50%, -50%)',
        top: copyData?.position_top || '50%',
        left: '50%',
        position: 'absolute',
        width: '70%',
        margin: `${`${copyData?.margin_desktop?.top}%`} ${`${copyData?.margin_desktop?.right}%`} ${`${copyData?.margin_desktop?.bottom}%`} ${`${copyData?.margin_desktop?.left}%`}`,
        [theme.breakpoints.down(600)]: {
            width: '90%',
            margin: '0 auto',
            top: `${copyData?.margin_mobile?.top ? `${copyData?.margin_mobile?.top}%` : 'auto'}`,
            right: `${copyData?.margin_mobile?.right ? `${copyData?.margin_mobile?.right}%` : '0'}`,
            left: `${copyData?.margin_mobile?.left ? `${copyData?.margin_mobile?.left}%` : '0'}`,
            bottom: `${copyData?.margin_mobile?.bottom ? `${copyData?.margin_mobile?.bottom}%` : 'auto'}`,
            transform: 'none',
        },
        '@media (max-width: 1024px) and (min-width:600px)': {
            width: '40%',
        },
    }),
    hsCopyTitle: ({ copyData, linkList }) => ({
        minWidth: '300px',
        color: copyData?.title_color?.color || '#000000',
        fontSize: copyData?.title_font_size || '2.2em',
        letterSpacing: copyData?.title_letter_spacing || '1px',
        fontFamily: `${parseFont(copyData?.title_font_family) || 'Playfair, serif'}`,
        '& p,h1,h2,h3': {
            margin: '5px auto',
            [theme.breakpoints.down(600)]: {
                margin: '0px',
            },
        },
        [theme.breakpoints.down(1200)]: {
            fontSize: '1.3em',
        },
        [theme.breakpoints.down(950)]: {
            fontSize: '1em',
        },
        [theme.breakpoints.down(850)]: {
            fontSize: '0.8em',
        },
        [theme.breakpoints.down(600)]: {
            fontSize: copyData?.mobile_font_size || '0.9em',
        },
        '@media (max-width: 1200px) and (min-width:600px)': {
            fontSize: linkList?.show_on_right && '1em',
        },
    }),
    hsCopySubtitle: ({ copyData }) => ({
        minWidth: '300px',
        width: '55%',
        color: copyData?.subtitle_color?.color || '#000000',
        fontSize: copyData?.subtitle_font_size || '0.9em',
        fontFamily: `${parseFont(copyData?.subtitle_font_family) || 'Playfair, serif'}`,
        margin: '0 auto',
        [theme.breakpoints.down(1000)]: {
            width: '45%',
            fontSize: '1.1em',
        },
        [theme.breakpoints.down(600)]: {
            width: '100%',
        },
        '& p': {
            margin: '0px',
        },
    }),
    bannerCTA: {
        border: 0,
        margin: 0,
        padding: 0,
        textDecoration: 'none',
    },
    hsCopyCTA: ({ copyData }) => ({
        display: 'inline-block',
        backgroundColor: getBackgroundColor(copyData?.cta?.background_color) || 'transparent',
        color: getBackgroundColor(copyData?.cta?.copy_color) || '#000000',
        border: copyData?.cta?.border?.border || 'none',
        borderRadius: copyData?.cta?.border?.border_radius || '0px',
        padding: '1% 5%',
        margin: '20px auto 0',
        letterSpacing: '2px',
        textDecoration: copyData?.cta?.text_decoration || 'none',
        textTransform: copyData?.cta?.text_transform || 'uppercase',
        fontFamily: `${parseFont(copyData?.cta?.cta_font_family) || 'Playfair, serif'}`,
        fontSize: copyData?.cta?.font_size || '1rem',
        fontWeight: copyData?.cta?.font_weight,
    }),
    subtitleHeading: ({ copyData }) => ({
        textAlign: 'center',
        '& h1, h2, & h3, & h4, & h5, & h6': {
            fontSize: '1rem',
            textTransform: 'uppercase',
            lineHeight: '16px',
            letterSpacing: '3px',
            zIndex: '9',
            width: '75%',
            color: getBackgroundColor(copyData.subtitle_heading_color) || '#000000',
            background: 'inherit',
            padding: '0 10px',
            margin: '0px auto 10px',
            [theme.breakpoints.down(850)]: {
                width: '100%',
            },
            [theme.breakpoints.down(600)]: {
                letterSpacing: '1.5px',
                fontSize: '0.813rem',
                margin: '0px auto 7px',
            },
            '&::before,&::after': {
                display: 'inline-block',
                content: '""',
                background: getBackgroundColor(copyData.subtitle_heading_color) || '#000000',
                height: '1px',
                width: '12%',
                verticalAlign: '25%',
                zIndex: '1',
                margin: '0 10px',
                [theme.breakpoints.down(600)]: {
                    width: '10%',
                    margin: '0 5px',
                },
            },
        },
    }),
}));

const HeroStandard = ({ data }) => {
    if (!data) {
        return null;
    }
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isDesktopDevice = useMediaQuery('(min-width: 1024px)');
    const copyData = data?.main_copy || {};
    const linkList = data?.link_list || {};
    const classes = useStyles({ copyData, linkList });
    const imageData = isMobile ? data?.main_images.mobile_image : data?.main_images?.image;
    const SEOAltText = data?.main_images?.seo_alt_text || imageData?.title;

    const CTAData = data?.main_copy?.cta;

    const fullBannerLink = CTAData?.is_banner_clickable || false;

    const LoadBanner = () => (
        <>
            {imageData && (
                <ResponsiveImage
                    className={classes.responsiveHeroImage}
                    path={imageData?.url}
                    alt={CTAData?.link?.title}
                />
            )}
            {copyData?.title && (
                <div className={classes.hsCopyContainer}>
                    <ReactMarkdown className={classes.hsCopyTitle} source={copyData.title} escapeHtml={false} />
                    {copyData?.subtitle_heading && <div className={classes.subtitleHeading}><h2>{copyData.subtitle_heading}</h2></div>}
                    {copyData?.subtitle && <ReactMarkdown className={classes.hsCopySubtitle} source={copyData.subtitle} escapeHtml={false} />}
                    {CTAData?.link?.title && fullBannerLink && (
                        <span className={classes.hsCopyCTA}>
                            <strong>{CTAData.link.title}</strong>
                        </span>
                    )}
                    {CTAData?.link?.title && !fullBannerLink && (
                        <LinkOrATag href={CTAData.link?.href} trackingEventLabel={CTAData.link.title} isTrackEventEnabled={CTAData?.is_ga_trackevent_enabled} className={classes.hsCopyCTA} ariaLabel={CTAData.link.title}>
                            <strong>{CTAData.link.title}</strong>
                        </LinkOrATag>
                    )}
                </div>
            )}
        </>
    );
    return (
        <div className={classes.hsWrapper}>
            <span title={SEOAltText} className={classes.hsHero}>
                <Grid container className={classes.hsGridContainer}>
                    {fullBannerLink ? (
                        <LinkOrATag href={CTAData.link?.href} trackingEventLabel={CTAData.link.title} isTrackEventEnabled={CTAData?.is_ga_trackevent_enabled} className={classes.bannerCTA} ariaLabel={CTAData.link.title}>
                            <LoadBanner />
                        </LinkOrATag>
                    ) : (<LoadBanner />)}
                    {linkList?.link?.length > 0 && isDesktopDevice
                        && (<DesktopHeroLinklist data={linkList} />)}
                </Grid>
            </span>
            {linkList?.link?.length > 0 && !isDesktopDevice
                && (<MobileHeroAccordion data={linkList} />)}
        </div>
    );
};

HeroStandard.propTypes = {
    data: shape({
        main_images: {
            image: {
                url: string,
                title: string,
            },
            mobile_image: {
                url: string,
                title: string,
            },
        },
        main_copy: {
            copy_background_color: {
                color: string,
            },
            svg: {
                url: string,
                title: string,
            },
            mobile_svg: {
                url: string,
                title: string,
            },
            position_top: string,
            title: string,
            title_font_size: string,
            title_color: shape({
                color: string,
            }),
            title_letter_spacing: string,
            subtitle: string,
            subtitle_font_size: string,
            subtitle_color: shape({
                color: string,
            }),
            cta: shape({
                is_ga_trackevent_enabled: bool,
                link: shape({
                    title: string,
                    href: string,
                }),
                font_size: string,
                font_weight: string,
                text_decoration: string,
                text_transform: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
            }),
        },
    }),
};

HeroStandard.defaultProps = {
    data: {},
};

export default HeroStandard;

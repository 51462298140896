/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import {
    array, shape, bool, string, number, object,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import HTML5 from './HTML5Video';
import IframeVideoModule from './IframeVideo';
import isIframeVideo from './isIframeVideo';
import Header from './Partials/Header';
import MultiVideoBlock from './MultiVideo';
import Panel from '../Panel';
import VideoInLineGrid from './VideoInlineBlock';
import useUIDQueryConditionally from '../../../helpers/hooks/useUIDQueryConditionally';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import GenericSkeleton from '../../GraphqlComponents/GraphqlSkeletonComponents/GenericSkeleton';

// Render Video content on the page
// Accepts content hosted by YouTube and alt-hosted HTML5 video.
const useStyles = (theme) => ({
    spacing: {
        position: 'relative',
        margin: '0 auto 32px',
        [theme.breakpoints.down(1024)]: {
            margin: '0 auto 16px',
        },
    },
    leftFlex: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    rightFlex: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row-reverse',
        flexWrap: 'wrap',
    },
    panelStyles: {
        width: '40%',
    },
    subTitleText: {
        textAlign: 'center',
        fontSize: '1em',
        fontWeight: 'bold',
        padding: '10px 0px 15px 0px',
    },
});
const Video = ({ data: blockData, classes }) => {
    const { data, loading } = useUIDQueryConditionally({
        data: blockData,
        newDataSelector: (dta) => ({ ...blockData, optional_reference: [dta?.findContentByUID?.content?.entry] }) || data,
        evaluateCondition: (dta) => (Object.keys(dta?.optional_reference?.[0] || {}).length === 2),
        uidSelector: (dta) => dta?.optional_reference?.[0]?.uid,
        contentTypeSelector: (dta) => dta?.optional_reference?.[0]?._content_type_uid,
    });
    if (loading) {
        return (
            <DesktopMobile
                desktop={() => <GenericSkeleton height={blockData?.skeleton_height || blockData?.height} />}
                mobile={() => <GenericSkeleton height={blockData?.mobile_skeleton_height || blockData?.height} />}
            />
        );
    }
    let videoArray = [];
    if (data) {
        const width = data.width ? `${data.width}` : '100%';
        const height = data.height ? `${data.height}` : '100%';
        const hasPanel = !!(data.optional_reference?.length > 0);
        const optionalPanelData = hasPanel ? data.optional_reference[0] : '';
        videoArray = data.video_references;
        const flexClass = data.optional_panel_side?.toLowerCase() === 'right' ? classes.rightFlex : classes.leftFlex;
        const tracking = data?.tracking || {};
        const heading = () => {
            if (data.heading) return <Header data={data} />;
            return null;
        };
        const subHeading = () => {
            if (data?.sub_title) return <div className={classes.subTitleText}>{data?.sub_title}</div>;
            return null;
        };
        const optionalPanelBackground = optionalPanelData.use_image_as_background ? { background: `url(${optionalPanelData.image.url}) no-repeat center center` } : {};
        const optionalPanel = hasPanel && <div dataID="VideoPanel" className={classes.panelStyles} style={optionalPanelBackground}><Panel data={data.optional_reference?.[0]} /></div>;
        const showingGridInline = data?.video_showing_in_grid?.showing_video_as_inline_grid || false;
        const showingBottomText = data?.video_showing_in_grid?.showing_video_as_inline_grid || false;
        if (showingGridInline && videoArray?.length >= 1) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        { heading() }
                        <VideoInLineGrid bottomText={showingBottomText} data={data} />
                    </Grid>
                </Grid>
            );
        }
        if (videoArray?.length > 1) {
            return (
                <div className={classes.spacing}>
                    { heading() }
                    <div className={flexClass}>
                        {optionalPanel}
                        <MultiVideoBlock data={data} />
                    </div>
                </div>
            );
        }
        if (videoArray?.length < 2) {
            if (isIframeVideo(videoArray?.[0])) {
                return (
                    <div className={classes.spacing}>
                        { heading() }
                        <div className={flexClass}>
                            {optionalPanel}
                            <IframeVideoModule trackingData={tracking} data={videoArray[0]} width={width} height={height} play_location={data.play_location} autoplay={data.autoplay} />
                        </div>
                    </div>
                );
            }
            if (videoArray?.[0]?.type.toLowerCase() === 'other') {
                return (
                    <div className={classes.spacing}>
                        { heading() }
                        { subHeading() }
                        <div className={flexClass}>
                            {optionalPanel}
                            <HTML5 tracking={data?.tracking || {}} data={videoArray[0]} width={width} height={height} play_location={data.play_location} autoplay={data.autoplay} />
                        </div>
                    </div>
                );
            }
        }
    }
    return <></>;
};

Video.propTypes = {
    data: shape({
        heading: bool,
        autoplay: bool,
        height: number,
        play_location: string,
        title: string,
        optional_reference: array,
        optional_panel_side: string,
        width: number,
        video_references: array,
    }).isRequired,
    classes: object.isRequired,
};

export default withStyles(useStyles)(Video);

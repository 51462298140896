/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { shape, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme) => ({
    galleryContainer: {
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
        maxWidth: 1440,
        '& p': {
            margin: '5px 0px',
        },
    },
    galleryHeader: {
        fontFamily: 'PlayfairDisplay, serif',
        fontSize: '2em',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5em',
        },
    },
}));
const CustomerReviewsGallery = ({ block }) => {
    if (!block || !block?.gallery_id) return null;
    const classes = useStyles();
    return (
        <div className={classes.galleryContainer}>
            <div className={classes.galleryHeader}>{block?.heading}</div>
            <ReactMarkdown source={block?.sub_heading} escapeHtml={false} />
            <div className="yotpo yotpo-pictures-widget" data-gallery-id={block?.gallery_id} />
        </div>
    );
};

CustomerReviewsGallery.propTypes = {
    block: shape({
        gallery_id: string,
        heading: string,
        sub_heading: string,
    }).isRequired,
};

export default CustomerReviewsGallery;

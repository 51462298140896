/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import {
    arrayOf,  shape, string,
} from 'prop-types';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import { parseFont } from '../../../../../../helpers/common/helper';

const useStyles = makeStyles((theme) => ({
    mobileListBlock: {
        width: '100%',
        '& a': {
            textDecoration: 'none',
            display: 'block',
            color: theme.palette.cms?.black || '#000000',
            '@media (max-width: 1024px) and (min-width:600px)': {
                flexBasis: '50%',
                flexGrow: '1',
                border: '1px solid rgba(174,174,174,0.6)',
            },
        },
        '& ul': {
            padding: 0,
            margin: 0,
            '@media (max-width: 1024px) and (min-width:600px)': {
                display: 'flex',
                flexWrap: 'wrap',
            },
        },
        '& li': {
            listStyle: 'none',
            textAlign: 'center',
            padding: '4px 0px',
            fontWeight: '600',
            fontSize: '16px',
            '@media (max-width: 599px)': {
                borderTop: '1px solid rgba(174,174,174,0.6)',
            },
        },
    },
    mobileLink: ({ styles }) => ({
        fontFamily: parseFont(styles.listFontFamily),
    }),
}));
const MobileHeroAccordionLinkList = ({ linkList }) => {
    const styles = {
        listFontFamily: linkList?.font_family,
    };
    const classes = useStyles({ styles });
    const seed = useUIDSeed();
    return (
        <div className={classes.mobileListBlock}>
            <ul>
                {linkList?.link?.map((item) => (
                    <LinkOrATag className={classes.mobileLink} key={seed(item)} href={item?.href}>
                        <li>
                            {item?.title}
                        </li>
                    </LinkOrATag>
                ))}
            </ul>
        </div>
    );
};
MobileHeroAccordionLinkList.propTypes = {
    linkList: shape({
        font_family: string.isRequired,
        link: arrayOf(shape({
            title: string.isRequired,
            href: string.isRequired,
        })).isRequired,
    }).isRequired,
};

export default MobileHeroAccordionLinkList;

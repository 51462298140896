/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, shape, arrayOf, bool,
} from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'react-html-parser';
import { uid } from 'react-uid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import MuiTabMenuStyle from './MuiTabMenuStyle';

const useStyles = makeStyles((theme) => MuiTabMenuStyle(theme));

const MuiTabMenu = ({ data }) => {
    if (!data) return null;
    const classes = useStyles();
    const links =  (data.links?.length) ? data.links : [];
    const headTitle = (data.title || '');
    const history = useHistory();
    const dispatch = useDispatch();
    const  getActivePath = () => {
        const location = history?.location || {};
        return `${(location.pathname || '')}${(location.hash || '')}`;
    };

    const  getMenuItem = (menuItem, index) => {
        if (!menuItem?.link?.link) return null;
        const linkData = menuItem.link.link;
        return (
            <Tab
                key={uid(menuItem, index)}
                onClick={() => {
                    dispatch(trackEvent({
                        eventCategory: menuItem?.link?.tracking_event_category || '',
                        eventAction: menuItem?.link?.tracking_event_action || '',
                        eventLabel: menuItem?.link?.tracking_event_label || '',
                    }));
                }}
                label={ReactHtmlParser(linkData?.title)}
                tabIndex="0"
                id={`vertical-tab-${index}`}
                aria-controls={`vertical-tab-${index}`}
                value={linkData?.href}
            />
        );
    };

    const handleSubMenuChange = (event, newValue) => {
        if (newValue) {
            history.push(newValue);
        }
    };

    return (
        <Grid item xs={12} md={12}>
            <p className={classes.headTitle}>{headTitle}</p>
            <Tabs
                orientation="vertical"
                value={getActivePath()}
                onChange={handleSubMenuChange}
                aria-label="Customer Service Sub Menu"
                className={classes.tabs}
            >
                {links.map((menuItem, index) => (
                    getMenuItem(menuItem, index)
                ))}
            </Tabs>
        </Grid>
    );
};

MuiTabMenu.propTypes = {
    data: shape({
        links: arrayOf(shape({
            link: shape({
                add_icon: bool.isRequired,
                is_child: bool.isRequired,
                link: shape({
                    href: string.isRequired,
                    title: string.isRequired,
                }).isRequired,
                tracking_event_label: string.isRequired,
            }).isRequired,
        })).isRequired,
        locale: string.isRequired,
        orientation: string.isRequired,
        presentation: string.isRequired,
        styles: arrayOf(shape({
            color: shape({
                background_color: shape({
                    color: string.isRequired,
                    colorDescription: string.isRequired,
                    colorTheme: string.isRequired,
                    colorTransparency: string.isRequired,
                    version: string,
                }).isRequired,
                copy_color: shape({
                    color: string.isRequired,
                    colorDescription: string.isRequired,
                    colorTheme: string.isRequired,
                    colorTransparency: string.isRequired,
                    version: string,
                }).isRequired,
                copy_hover_color: shape({
                    color: string.isRequired,
                    colorDescription: string.isRequired,
                    colorTheme: string.isRequired,
                    colorTransparency: string.isRequired,
                    version: string,
                }).isRequired,
            }).isRequired,
        })).isRequired,
        title: string.isRequired,
        uid: string.isRequired,
        _content_type_uid: string.isRequired,
    }).isRequired,
};

export default MuiTabMenu;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    shape, object, arrayOf, string, func,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useUIDSeed } from 'react-uid';

const styles = (theme) => ({
    mainContainerGallery: {
        marginTop: 8,
    },
    sliderContainer: {
        position: 'relative',
        maxWidth: '1400px',
        margin: 'auto',
        '& .slick-list': {
            overflow: 'hidden',
        },
        '& .slick-slide': {
            display: 'inline-block',
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
        '& .slick-current': {
            paddingRight: 5,
        },
        '& .slick-prev, & .slick-next': {
            transform: 'translate(0,-50%)',
            cursor: 'pointer',
            border: 'none',
            outline: 0,
            fontSize: 0,
            lineHeight: 0,
            top: '42%',
            width: 30,
            height: 30,
            position: 'absolute',
        },
        '& .slick-prev': {
            left: 0,
            background: theme.palette.cms?.white || '#fff',
            opacity: 0.9,
            zIndex: 1,
        },
        '& .slick-next': {
            right: 0,
            background: theme.palette.cms?.white || '#fff',
            opacity: 0.9,
        },
    },
    galleryHeader: {
        backgroundColor: theme.palette.cms?.hostedHeaderBgColor || '#EBEBDF',
        padding: '8px 0',
        textAlign: 'center',
        marginBottom: 8,
        textTransform: 'uppercase',
        '& span': {
            fontSize: 22,
        },
        '& p': {
            fontSize: 14,
            textTransform: 'none',
            margin: 4,
        },
    },
    authorName: {
        width: '86%',
        margin: '0 auto',
        textAlign: 'center',
        '& img': {
            width: '75%',
        },
    },
    closeIconMain: {
        padding: '6px 8px',
        textAlign: 'right',
    },
    closeIcon: {
        cursor: 'pointer',
    },
    sliderMainTextDiv: {
        '& img': {
            cursor: 'pointer',
        },
    },
});

const HostedDinnerImageGallery = ({
    classes, imageGallery, classOverride, setFontFamily,
}) => {
    const [showModal, setShowModal] = useState({
        isShowModel: false,
        key: 0,
    });
    const { isShowModel, key } = showModal;
    const galleryHeading = imageGallery?.heading || '';
    const sub_heading = imageGallery?.sub_heading || '';
    const galleryData = imageGallery?.image_gallery;
    const fontString = imageGallery?.font_family || 'LatoRegular, Arial, Verdana, sans-serif';
    const seed = useUIDSeed();

    const sliderForGallery = (popSlide = false) => (
        <Slider
            dots={false}
            speed={500}
            slidesToShow={popSlide ? 1 : 4}
            slidesToScroll={1}
            initialSlide={isShowModel ? key  : 0}
            nextArrow={<ArrowForwardIosIcon />}
            prevArrow={<ArrowBackIosIcon />}
            className={`carousel-container ${classes.sliderContainer}`}
            responsive={[
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 599,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ]}
        >
            {galleryData?.map((item, keyData) => (
                <div key={seed(item)} className={classes.sliderMainTextDiv}>
                    <div
                        className={popSlide ? classes.authorName : classOverride.mainPageSlider}
                        role="presentation"
                        onClick={!popSlide ? () => setShowModal({
                            isShowModel: true,
                            key: keyData,
                        }) : null}
                    >
                        <img src={item?.image?.url} alt={item?.image?.title} />
                    </div>
                </div>
            ))}
        </Slider>
    );

    return (
        <>
            <Grid container className={classes.mainContainerGallery} style={setFontFamily(fontString)}>
                <Grid item xs={12} lg={12}>
                    <div className={classes.galleryHeader}>
                        <span className={classes.galleryHeading}>{galleryHeading}</span>
                        <ReactMarkdown source={sub_heading} escapeHtml={false} />
                    </div>
                    <div>
                        {sliderForGallery()}
                    </div>
                </Grid>
            </Grid>
            <>
                <Dialog
                    open={isShowModel}
                    maxWidth="md"
                >
                    <DialogTitle className={classes.closeIconMain}>
                        <CloseIcon
                            data-testid="close-upsell-modal"
                            onClick={() => {
                                setShowModal({
                                    isShowModel: false,
                                    key: 0,
                                });
                            }}
                            className={classes.closeIcon}
                        />
                    </DialogTitle>
                    <DialogContent>
                        {sliderForGallery(true)}
                    </DialogContent>
                </Dialog>
            </>
        </>
    );
};

HostedDinnerImageGallery.propTypes = {
    classes: object.isRequired,
    setFontFamily: func.isRequired,
    classOverride: object.isRequired,
    imageGallery: shape({
        heading: string,
        sub_heading: string,
        image_gallery: arrayOf(shape({
            image: shape({
                title: string,
                url: string,
            }),
        })),
    }),
};
HostedDinnerImageGallery.defaultProps = {
    imageGallery: {},
};
export default withStyles(styles)(HostedDinnerImageGallery);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        maxWidth: '1440px',
        margin: '80px auto',
        textAlign: 'center',
        '& p': {
            margin: '0 auto 10px',
            fontFamily: 'PlayfairDisplayBold, serif',
            fontSize: '32px',
        },
        '& a': {
            display: 'inline-block',
            textDecoration: 'none',
        },
        '& div': {
            padding: '10px 15px',
            fontFamily: 'latobold, serif',
            fontSize: '16px',
            textTransform: 'uppercase',
            letterSpacing: '2px',
            color: '#000',
            backgroundColor: '#e3f3e3',
        },
        [theme.breakpoints.down(700)]: {
            margin: '30px auto',
            '& p': {
                fontSize: '18px',
            },
        },
    },
}));

const MarketplaceLPFooter = ({ block }) => {
    const classes = useStyles();

    return (
        <div className={classes.footerContainer}>
            <p>{block?.title}</p>
            <a href={block?.cta?.link}><div>{block?.cta?.copy}</div></a>
        </div>
    );
};

MarketplaceLPFooter.propTypes = {
    block: shape({
        title: string,
        cta: shape({
            copy: string,
            link: string,
        }),
    }).isRequired,
};

export default MarketplaceLPFooter;

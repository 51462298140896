/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import {
    shape, string, arrayOf,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';

const useStyles = makeStyles((theme) => ({
    shareContainer: {
        margin: '0 auto',
        maxWidth: '1440px',
        [theme.breakpoints.down(700)]: {
            margin: '0 auto',
        },
    },
    shareTop: {
        display: 'flex',
        [theme.breakpoints.down(700)]: {
            justifyContent: 'space-between',
            '& div:nth-child(1)': {
                margin: '-80px auto 0',
                '& img': {
                    flex: '0 0 55%',
                },
            },
            '& div:nth-child(2)': {
                margin: 'auto',
                '& img': {
                    flex: '0 0 45%',
                },
            },
        },
    },
    shareMainImage: {
        marginLeft: '15px',
        objectFit: 'contain',
        flex: '0 0 35%',
        '& img': {
            width: '100%',
            marginTop: '-70px',
        },
        [theme.breakpoints.down(900)]: {
            flex: '0 0 45%',
        },
    },
    shareCopy: {
        flex: '0 0 65%',
        maxWidth: '40%',
        margin: '2% auto 0',
        '& p:nth-child(1)': {
            fontSize: '24px',
            fontFamily: 'latobold, serif',
            textTransform: 'uppercase',
            textDecoration: 'underline',
        },
        '& p:nth-child(2)': {
            fontSize: '22px',
            fontFamily: 'PlayfairDisplay, serif',
        },
        '& a': {
            textDecoration: 'none',
            display: 'inline-block',
        },
        '& div': {
            maxWidth: '200px',
            margin: '0 auto',
            fontFamily: 'LatoBold, sans-serif',
            textTransform: 'uppercase',
            letterSpacing: '2px',
            fontSize: '16px',
            color: '#000',
            backgroundColor: '#e3f3e3',
            padding: '10px 25px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '20px',
            '& p:nth-child(1)': {
                fontSize: '20px',
            },
            '& p:nth-child(2)': {
                fontSize: '18px',
            },
        },
        [theme.breakpoints.down(1000)]: {
            fontSize: '18px',
            '& p:nth-child(1)': {
                fontSize: '18px',
            },
            '& p:nth-child(2)': {
                fontSize: '16px',
            },
        },
        [theme.breakpoints.down(700)]: {
            paddingTop: '0',
            display: 'block',
            maxWidth: '80%',
            textAlign: 'center',
            '& a': {
                color: '#000',
            },
        },
    },
    shareBottom: {
        margin: '50px auto auto',
        display: 'flex',
        textAlign: 'center',
        '& img': {
            width: '100%',
        },
        [theme.breakpoints.down(700)]: {
            justifyContent: 'space-between',
            '& img': {
                width: '80%',
            },
        },
    },
    shareBottomLeft: {
        flex: '0 0 35%',
        margin: '60px auto auto',
        [theme.breakpoints.down(700)]: {
            margin: '0 auto',
            flex: '0 0 40%',
        },
    },
    shareBottomMiddle: {
        flex: '0 0 20%',
        margin: '0 auto auto',
    },
    shareBottomRight: {
        flex: '0 0 30%',
        margin: '-150px auto auto',
        [theme.breakpoints.down('md')]: {
            margin: '-45px auto auto',
        },
        [theme.breakpoints.down(700)]: {
            paddingTop: '50px',
            margin: '0 auto -70px',
            flex: '0 0 60%',

        },
    },
}));

const MarketplaceLPShare = ({ block }) => {
    const isMobile = useSelector(getSSRDeviceType) === 'mobile';

    const classes = useStyles();

    return (
        <>
            {isMobile ? (
                <div className={classes.shareContainer}>
                    <div className={classes.shareTop}>
                        <div className={classes.shareTopLeft}>
                            <img src={block?.share_images_mobile?.[0]?.image?.url} alt={block?.title} />
                        </div>
                        <div className={classes.shareTopRight}>
                            <img src={block?.share_images_mobile?.[1]?.image?.url} alt={block?.title} />
                        </div>
                    </div>
                    <div className={classes.shareCopy}>
                        <p>{block?.title}</p>
                        <p>{block?.description}</p>
                        <a href={block?.prompt?.link}><div>{block?.prompt?.copy}</div></a>
                    </div>
                    <div className={classes.shareBottom}>
                        <div className={classes.shareBottomLeft}>
                            <img src={block?.share_images_mobile?.[2]?.image?.url} alt={block?.title} />
                        </div>
                        <div className={classes.shareBottomRight}>
                            <img src={block?.share_images_mobile?.[3]?.image?.url} alt={block?.title} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classes.shareContainer}>
                    <div className={classes.shareTop}>
                        <div className={classes.shareMainImage}>
                            <img src={block?.share_images_desktop?.[0]?.image?.url} alt={block?.title} />
                        </div>
                        <div className={classes.shareCopy}>
                            <p>{block?.title}</p>
                            <p>{block?.description}</p>
                            <a href={block?.prompt?.link}><div>{block?.prompt?.copy}</div></a>
                        </div>
                    </div>
                    <div className={classes.shareBottom}>
                        <div className={classes.shareBottomLeft}>
                            <img src={block?.share_images_desktop?.[1]?.image?.url} alt={block?.title} />
                        </div>
                        <div className={classes.shareBottomMiddle}>
                            <img src={block?.share_images_desktop?.[2]?.image?.url} alt={block?.title} />
                        </div>
                        <div className={classes.shareBottomRight}>
                            <img src={block?.share_images_desktop?.[3]?.image?.url} alt={block?.title} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

MarketplaceLPShare.propTypes = {
    block: shape({
        title: string,
        description: string,
        prompt: shape({
            link: string,
            copy: string,
        }),
        share_images_mobile: arrayOf(shape({
            image: shape({
                url: string,
            }),
        })),
        share_images_desktop: arrayOf(shape({
            image: shape({
                url: string,
            }),
        })),
    }).isRequired,
};

export default MarketplaceLPShare;

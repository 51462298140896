/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import Joi from 'joi-browser';

// Used in MarketplaceFormBlock.js
export const formObj = {
    firstName: '',
    lastName: '',
    yourRole: '',
    emailAddress: '',
    companyName: '',
    numberOfEmployees: '',
    location: '',
    website: '',
    howDidYouHearAboutUs: '',
    whatProductsAreYouBestKnownFor: '',
    doYouCurrentlyDropShip: '',
    tellUsYourStoryAndALittleBitMoreAboutYourCompany: '',
    haveYouGottenAnyPress: '',
    whyShouldYouSellWithUs: '',
    numberOfInstagramfollowers: '',
    numberOfFacebookFans: '',
    twitterHandle: '',
    facebookHandle: '',
    instagramHandle: '',
};

// Used in MarketplaceFormBlock.js
export const schema = {
    firstName: Joi.string()
        .trim()
        .max(20)
        .required()
        .label('First Name'),
    lastName: Joi.string()
        .trim()
        .max(20)
        .required()
        .label('Last Name'),
    yourRole: Joi.string()
        .trim()
        .max(20)
        .required()
        .label('Your Role'),
    emailAddress: Joi.string()
        .email()
        .trim()
        .max(20)
        .required()
        .label('Email Address'),
    companyName: Joi.string()
        .trim()
        .required()
        .label('Company Name'),
    numberOfEmployees: Joi.string()
        .trim()
        .required()
        .label('Number Of Employees'),
    location: Joi.string()
        .trim()
        .required()
        .label('Location'),
    website: Joi.string()
        .trim()
        .required()
        .label('Website'),
    howDidYouHearAboutUs: Joi.string()
        .trim()
        .required()
        .label('How Did You Hear About Us'),
    whatProductsAreYouBestKnownFor: Joi.string()
        .trim()
        .required()
        .label('What Products Are You Best Known For'),
    doYouCurrentlyDropShip: Joi.string()
        .trim()
        .required()
        .label('Do You Currently Drop Ship'),
    tellUsYourStoryAndALittleBitMoreAboutYourCompany: Joi.string()
        .trim()
        .required()
        .label('Tell Us Your Story And A Little Bit More About Your Company'),
    haveYouGottenAnyPress: Joi.string()
        .trim()
        .required()
        .label('Have You Gotten Any Press'),
    whyShouldYouSellWithUs: Joi.string()
        .trim()
        .optional()
        .allow('')
        .label('Why Should You Sell With Us'),
    numberOfInstagramfollowers: Joi.string()
        .trim()
        .optional()
        .allow('')
        .label('Number Of Instagram Followers'),
    numberOfFacebookFans: Joi.string()
        .trim()
        .optional()
        .allow('')
        .label('Number Of Facebook Fans'),
    twitterHandle: Joi.string()
        .trim()
        .optional()
        .allow('')
        .label('Twitter Handle'),
    facebookHandle: Joi.string()
        .trim()
        .optional()
        .allow('')
        .label('Facebook Handle'),
    instagramHandle: Joi.string()
        .trim()
        .optional()
        .allow('')
        .label('Instagram Hanlde'),
};

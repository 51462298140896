/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { array, object } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ProductUI from '../../../GraphqlCommonComponents/TrendingProducts/Partials/ProductUI';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#F7F7F7',
    },
    innerContainer: {
        display: 'flex',
        maxWidth: '1280px',
        margin: '0 auto',
        [theme.breakpoints.down(600)]: {
            margin: '0 10%',
            display: 'block',
        },
    },
    header: {
        textDecoration: 'underline',
        textTransform: 'uppercase',
        padding: '30px 0px',
        textAlign: 'center',
        margin: 0,
        letterSpacing: 2,
        fontFamily: 'LatoBlack, serif',
    },
    product: {
        width: '23%',
        margin: '0 auto',
        '& $a': {
            width: '100%',
            margin: '0',
            '& img': {
                width: '100%',
            },
        },
        [theme.breakpoints.down(600)]: {
            width: '40%',
            display: 'inline-block',
            margin: '0 5% 5% 5%',
        },
    },
    sellerDetailsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1280px',
        margin: '0 auto',
        padding: '30px 10px 0',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            padding: 0,
        },
    },
    sellerDetails: {
        width: '47%',
        textAlign: 'left',
        margin: '0 auto',
        '& img': {
            width: '100%',
        },
        '& p': {
            fontFamily: 'PlayfairDisplayRegular, serif',
            marginTop: 0,
        },
        '& h3': {
            textAlign: 'left',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            textAlign: 'center',
            paddingTop: 10,
            '& h3': {
                textAlign: 'center',
            },
        },
    },
}));

const MeetYourSellerTabPanel = ({
    recommendedProducts, sellerShopData, sellerTabContent,
}) => {
    if (!recommendedProducts && !sellerShopData && !sellerTabContent) {
        return null;
    }
    const classes = useStyles();

    const sellerShopName = sellerShopData?.shopName || '';
    const sellerShopDescription = sellerShopData?.description || '';
    const sellerShopBanner = sellerTabContent?.seller_banner || {};

    return (
        <div className={classes.container}>
            {sellerShopName && sellerShopDescription && (
                <div className={classes.sellerDetailsContainer}>
                    {sellerShopDescription && (
                        <div className={classes.sellerDetails}>
                            <h3 className={classes.header}>
                                about {sellerShopName}
                            </h3>
                            <p>{sellerShopDescription}</p>
                        </div>
                    )}
                    {sellerShopBanner?.url && (
                        <div className={classes.sellerDetails}>
                            <img src={sellerShopBanner.url} alt={sellerShopBanner?.title || 'sellerBanner'} />
                        </div>
                    )}
                </div>
            )}

            {recommendedProducts?.length ? (
                <>
                    <h3 className={classes.header}>more from this seller</h3>
                    <div className={classes.innerContainer}>
                        {recommendedProducts?.map((product) => (
                            <div className={classes.product}>
                                <ProductUI productData={product} />
                            </div>
                        ))}
                    </div>
                </>
            ) : null}
        </div>
    );
};

MeetYourSellerTabPanel.propTypes = {
    recommendedProducts: array,
    sellerShopData: object,
    sellerTabContent: object,
};

MeetYourSellerTabPanel.defaultProps = {
    recommendedProducts: [],
    sellerShopData: {},
    sellerTabContent: {},
};

export default MeetYourSellerTabPanel;

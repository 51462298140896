/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
// import { uid } from 'react-uid';
import { object, oneOf } from 'prop-types';
import { Grid } from '@material-ui/core';

import Media from 'react-media';
import ContentPageBlock from './ContentPageBlock';
import GraphqlBreadcrumbs from '../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';

import Imoc from '../GraphqlCategoryPage/Partials/GraphqlCatHeaderContainer/Partials/GraphqlImoc/GraphqlImoc';

// eslint-disable-next-line react/prefer-stateless-function
class GraphqlContentPageBody extends Component {
    getLayoutData = (blockData) => {
        if (!blockData?.length) return null;
        const identifyWidthObj = this.identifyWidth();
        return (
            blockData.map((block /* , index */) => (
                <ContentPageBlock
                    block={block}
                    // key={uid(block, index)}
                    // ssrDeviceType={ssrDeviceType}
                    identifyWidthObj={identifyWidthObj}
                />
            ))
        );
    };

    identifyWidth = () => {
        const { width } = this.props;
        const widthResolution = {
            isMobile: false,
            isDesktop: false,
        };
        switch (width) {
            case 'lg':
            case 'md':
            case 'xl':
                widthResolution.isDesktop = true;
                break;
            case 'xs':
            case 'sm':
                widthResolution.isMobile = true;
                break;
            default:
                break;
        }
        return widthResolution;
    };

    checkForFixedWidth = (blocks) => {
        const style = {};
        if (!blocks) return style;
        blocks.forEach((i) => {
            if (Object.keys(i)?.[0]) return style;
            if (Object.keys(i)[0] === 'fixed_width') {
                style.maxWidth = i.fixed_width.max_width;
                style.margin = '0 auto';
            }
            return style;
        });
        return style;
    };

    setContentLayout = (classes, blocks, brand) => {
        if (!blocks) return null;
        const hasLeftContent = (blocks.left_blocks?.length > 0);
        const hasCenterContent = (blocks.center_blocks?.length > 0);
        const hasRightContent = (blocks.right_blocks?.length > 0);
        const imoc = blocks.page_blocks?.[0]?.imoc || {};
        const identifyWidthObj = this.identifyWidth();
        let centerBlockGridValue = 12;    // no left nor right content
        if (hasLeftContent && hasRightContent) {
            centerBlockGridValue = 7;     // left and right content
        } else if (hasLeftContent) {
            centerBlockGridValue = 9;     // left content only
        } else if (hasRightContent) {
            centerBlockGridValue = 10;    // right content only
        }
        return (
            <>
                { imoc.imoc_group
                    ? (
                        <Grid container className={classes.imocContainer}>
                            <Imoc brand={brand?.domain} imocBlock={imoc} />
                        </Grid>
                    ) : null }
                <Grid container className={classes.content}>
                    {hasLeftContent && identifyWidthObj.isDesktop
                    && (
                        <Grid item md={3}>
                            <Grid className={classes.leftBlock}>
                                <Grid container>
                                    {this.getLayoutData(blocks.left_blocks, identifyWidthObj)}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {hasCenterContent
                    && (
                        <Grid item md={centerBlockGridValue} xs={12} sm={12}>
                            <Grid className={classes.centerBlock} style={this.checkForFixedWidth(blocks.center_blocks)}>
                                <Grid container>
                                    {this.getLayoutData(blocks.center_blocks, identifyWidthObj)}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {hasRightContent && identifyWidthObj.isDesktop
                    && (
                        <Grid item md={2}>
                            <Grid className={classes.rightBlock}>
                                <Grid container>
                                    {this.getLayoutData(blocks.right_blocks, identifyWidthObj)}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {hasLeftContent && identifyWidthObj.isMobile
                    && (
                        <Grid item md={12} xs={12} sm={12}>
                            <Grid className={classes.leftBlock}>
                                <Grid container>
                                    {this.getLayoutData(blocks.left_blocks)}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };

    render() {
        const {
            seo, blocks, brand, classes,
        } = this.props;
        return (
            <>
                <Media query="(min-width: 600px)">
                    {seo?.breadcrumb?.length
                    && (
                        <div style={{
                            display: 'block', maxWidth: '1400px', margin: '0 auto', padding: '20px 0 0 20px',
                        }}
                        ><GraphqlBreadcrumbs breadCrumbArray={seo.breadcrumb} />
                        </div>
                    )}
                </Media>
                {this.setContentLayout(classes, blocks, brand)}
                <Media query="(max-width: 599px)">
                    {seo?.breadcrumb?.length
                    && (
                        <div style={{ padding: '20px 0 0 20px' }}>
                            <GraphqlBreadcrumbs breadCrumbArray={seo.breadcrumb} />
                        </div>
                    )}
                </Media>
            </>
        );
    }
}

GraphqlContentPageBody.propTypes = {
    brand: object.isRequired,
    // ssrDeviceType: string.isRequired,
    // untData: object,
    blocks: object,
    seo: object,
    classes: object,
    width: oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
    // presentationFamily: string,
};

GraphqlContentPageBody.defaultProps = {
    // untData: '',
    blocks: {},
    seo: {},
    classes: {},
    // presentationFamily: 'flowers',
};

export default GraphqlContentPageBody;

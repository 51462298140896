/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bool, object } from 'prop-types';

import MarketplaceFormBlock from './Partials/SellersForm/MarketplaceFormBlock';
import MarketplaceLPBrands from './Partials/Sellers/MarketplaceLPBrands';
import MarketplaceLPBenefits from './Partials/Sellers/MarketplaceLPBenefits';
import MarketplaceLPShare from './Partials/Sellers/MarketplaceLPShare';
import MarketplaceLPFAQs from './Partials/Sellers/MarketplaceLPFAQs';
import MarketplaceLPFooter from './Partials/Sellers/MarketplaceLPFooter';
import GraphqlSeoFooter from '../GraphqlHomePage/Partials/GraphqlSeoFooter/GraphqlSeoFooter';
import CustomerReviewsGallery from './Partials/Weddings/CustomerReviewsGallery';
import ContentHero from './Partials/SharedMarketplaceComponents/ContentHero';
import ContentCategories from './Partials/SharedMarketplaceComponents/ContentCategories';
import WeddingsBlog from './Partials/Weddings/WeddingsBlog';
import TileRowWithHeading from '../GraphqlTemplatePage/Partials/TemplateCollectionGiftGuides/Partials/TileRowWithHeading';

const MarketplacePageBlock = ({ block, isBirthdayLPLayout }) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];

    switch (blockKey) {
        case 'hero_block':
            return (
                <ContentHero data={blockObj} />
            );
        case 'category_block':
            return (
                <ContentCategories data={blockObj} isBirthdayLPLayout={isBirthdayLPLayout} />
            );
        case 'consumer_lp_blog':
            return (
                <WeddingsBlog data={blockObj} />
            );
        case 'marketplace_lp_brands_family':
            return (
                <MarketplaceLPBrands block={blockObj} />
            );
        case 'marketplace_lp_benefits_and_community':
            return (
                <MarketplaceLPBenefits block={blockObj} />
            );
        case 'marketplace_lp_share_your_story':
            return (
                <MarketplaceLPShare block={blockObj} />
            );
        case 'marketplace_lp_faqs':
            return (
                <MarketplaceLPFAQs block={blockObj} />
            );
        case 'marketplace_lp_footer':
            return (
                <MarketplaceLPFooter block={blockObj} />
            );
        case 'marketplace_application_form':
            return (
                <MarketplaceFormBlock data={blockObj} />
            );
        case 'customer_reviews_gallery':
            return (
                <CustomerReviewsGallery block={blockObj} />
            );
        case 'footer_seo':
            return (
                <GraphqlSeoFooter data={blockObj} />
            );
        case 'tile_row_with_heading':
            return (
                <TileRowWithHeading data={{ tile_row_with_heading: blockObj }} isBirthdayLPLayout={isBirthdayLPLayout} />
            );
        default:
            return null;
    }
};
MarketplacePageBlock.propTypes = {
    block: object.isRequired,
    isBirthdayLPLayout: bool.isRequired,
};

export default MarketplacePageBlock;

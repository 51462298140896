/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    string, bool, shape, object,
} from 'prop-types';
// import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import VideoModalBlock from './Partials/VideoModal';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = () => ({
    frame_style: {
        width: '100%',
        height: '100%',
    },
});

export function IframeVideo({
    data, width, height, play_location, classes, autoplay, trackingData,
}) {
    const dispatch = useDispatch();
    const iframeLoaded = () => {
        // prefer if we get title from youtube video for label, but reading iframe from cross origin throwing security errors
        const trackingLabel = `${data?.type} | ${data?.video_id}`;
        dispatch(trackEvent({
            eventCategory: trackingData?.tracking_event_category || '',
            eventAction: trackingData?.tracking_event_action || '',
            eventLabel: trackingLabel || '',
            nonInteraction: '1',
        }));
    };
    if (data?.type.toLowerCase() === 'youtube' || /vimeo/i.test(data?.video_id)) {
        const autoplay_val = autoplay ? 1 : 0;
        const mute = autoplay ? 1 : 0;
        const controls_val = data?.show_controls === true ? 1 : 0;
        let src = `https://www.youtube.com/embed/${data?.video_id}?rel=0&autoplay=${autoplay_val}&mute=${mute}&controls=${controls_val}`;
        let dataTestId = 'YouTubeRendered';
        const allowFullScreen = data?.allow_fullscreen ? 'allowFullScreen' : '';

        // Allow for vimeo src
        if (/vimeo/i.test(data?.video_id)) {
            // Replace vimeo with player.vimeo if not already present
            src = `${/player\.vimeo/i.test(data.video_id) ? data.video_id : data.video_id?.replace('vimeo', 'player.vimeo')}?rel=0&autoplay=${autoplay_val}&mute=${mute}&controls=${controls_val}`;
            dataTestId = 'VimeoRendered';
            if (!/com\/video/.test(src)) {
                // Replace .com with .com/video if it is not present
                src = src.replace('.com/', '.com/video/');
            }
        }
        return (
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: `${width}px`,
                    overflow: 'hidden',
                    margin: '7px 0px',
                    maxHeight: `${height}px`,
                }}
            >
                {play_location.toLowerCase() === 'in place'
                    ? (
                        <div style={{
                            position: 'relative',
                            paddingBottom: '56.25%',
                            height: '0',
                            overflow: 'hidden',
                            maxWidth: `${width}px`,
                            marginBottom: '5px',
                        }}
                        >
                            <iframe
                                title="video"
                                data-testid={dataTestId}
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    maxWidth: `${width}px`,
                                    height: '100%',
                                    maxHeight: `${height}px`,
                                }}
                                src={src}
                                frameBorder="0"
                                allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={allowFullScreen}
                                onLoad={iframeLoaded}
                            />
                        </div>
                    )
                    : (
                        <VideoModalBlock image={data?.image}>
                            <iframe
                                title="video"
                                className={classes.frame_style}
                                src={src}
                                frameBorder="0"
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={allowFullScreen}
                                onLoad={iframeLoaded}
                            />
                        </VideoModalBlock>
                    )}
            </div>
        );
    }
    return <></>;
}

IframeVideo.propTypes = {
    data: shape({
        allow_fullscreen: bool,
        show_controls: bool,
        type: string,
        video_id: string,
        image: shape({
            url: string,
            title: string,
        }),
    }).isRequired,
    autoplay: bool,
    play_location: string,
    width: string,
    height: string,
    classes: object.isRequired,
    trackingData: shape({
        tracking_event_action: string,
        tracking_event_category: string,
    }),
};

IframeVideo.defaultProps = {
    play_location: 'In Place',
    autoplay: false,
    width: '100%',
    height: '100%',
    trackingData: {},
};
export default withStyles(styles)(IframeVideo);

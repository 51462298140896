/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { object } from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
    body: {
        background: '#3c3c35',
        width: '100%',
        maxWidth: '1280px',
        margin: '1em auto 2rem',
        padding: '5px',
    },
    hostedIntro: {
        background: '#ebebdf',
        display: 'flex',
        padding: '1rem',
        marginBottom: '5px',
        '@media screen and (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'center',
            '& div': {
                borderLeft: 'none !important',
                width: '100%',
            },
        },
    },
    half: {
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            margin: '1rem auto',
        },
        textAlign: 'center',
        '& h1': {
            fontFamily: 'Caslon, Georgia, serif',
            fontWeight: 400,
            textTransform: 'uppercase',
            fontSize: '1.8rem',
            margin: 0,
        },
        '&+div': {
            borderLeft: 'solid 1px #3c3c35',
        },

    },
    featured: {
        '& img': {
            width: '100%',
        },
    },
    headingBreak: {
        backgroundColor: '#ebebdf',
        color: '#3c3c35',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '0.3rem 0',
        fontSize: '1.4rem',
    },
    hostedLoc: {
        color: '#ebebdf',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '0.3rem 0',
        fontSize: '1.4rem',
    },
    cta: {
        color: '#1f1f1b',
        background: '#fff',
        width: '120px',
        margin: '10px auto',
        padding: '6px',
        borderRadius: '4px',
    },
    eventPanel: {
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '23px',
        width: '250px',
        padding: '20px !important',
        borderRadius: '4px',
        border: 'solid 1px #00000022',
        boxShadow: '0 0 10px #00000022',
        margin: '20px 20px 0 !important',
        transition: 'all .3s',
        '& a': {
            color: '#ebebdf',
            textDecorationColor: '#6f6f6f',
            fontSize: '.8rem',
            textTransform: 'none',
        },
    },

    venueName: {
        fontSize: '1rem',

    },
    hostedRestWrap: {
        display: 'flex',
        justifyContent: 'center',
        '@media screen and (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    about: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        background: 'url(https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blta694077c2fbfa01b/641121522d6b5b6dac835e4b/230303-HD-MainPage-HostedDinners_About_1050x396.jpg) center no-repeat',
        fontSize: '0',
        margin: '50px auto',
        padding: '50px',
        '&>div': {
            padding: '30px',
            width: '400px',
            maxWidth: '80%',
            backgroundColor: '#ebebdf',
            color: '#1f1f1b',
            '& h2': {
                fontFamily: 'Caslon, Georgia, serif',
                fontSize: '1.9rem',
                fontWeight: 400,
                margin: '0 auto',
            },
            '& p': {
                fontSize: '0.8rem',
                lineHeight: '1.3rem',
                fontWeight: '100',
                textTransform: 'none',
            },
        },
    },
    eventCardLogo: {
        width: '100%',
    },
    hostedContact: {
        background: '#ebebde',
        color: '#1f1f1b',
        textAlign: 'center',
        padding: '10px',
        fontWeight: '100',
        fontSize: '14px',
        textTransform: 'none',
        '& span': {
            textTransform: 'uppercase',
            fontSize: '20px',
            fontWeight: '100',
        },
        '& a': {
            color: '#4a8ed1',
        },
    },
    hostedLastEvent: {
        display: 'flex',
        background: '#3c3c35',
        textAlign: 'center',
        color: '#fff',
        '& h2': {
            fontFamily: 'Caslon, Georgia, serif',
            fontWeight: 400,
            textTransform: 'uppercase',
            fontSize: '1.8rem',
            margin: 0,
            color: '#fff !important',
            '& a': {
                color: '#fff !important',
            },
        },
        '& a': {
            textDecoration: 'none',
            color: '#4a8ed1',
        },
        '@media screen and (max-width: 1024px)': {
            flexDirection: 'column',
        },
    },
    hostedLastEventText: {
        padding: '3em 2em',
    },
    hostedReviews: {
        width: '100%',
        background: '#3c3c35',
        margin: '2em auto 1em',
        zIndex: '5',
        position: 'relative',
        '&:after': {
            content: '""',
            borderBottom: '1px solid #fff',
            width: '90%',
            position: 'absolute',
            left: '5%',
            top: '16px',
            zIndex: '0',
        },
    },
    starContainer: {
        background: '#3c3c35',
        position: 'relative',
        width: '230px',
        margin: '0 auto',
        zIndex: 1,
    },
    star: {
        position: 'relative',
        display: 'inline-block',
        width: '0',
        height: '0',
        marginLeft: '.9em',
        marginRight: '.9em',
        marginBottom: '1.2em',
        borderRight: '.3em solid transparent',
        borderBottom: '.7em solid #fff',
        borderLeft: '.3em solid transparent',
        fontSize: '16px',
        '&:before, &:after': {
            content: '""',
            display: 'block',
            width: 0,
            height: 0,
            position: 'absolute',
            top: '.6em',
            left: '-1em',
            borderRight: '1em solid transparent',
            borderBottom: '.7em solid #fff',
            borderLeft: '1em solid transparent',
            WebkitTransform: 'rotate(-35deg)',
            transform: 'rotate(-35deg)',
        },
        '&:after': {
            WebkitTransform: 'rotate(35deg)',
            transform: 'rotate(35deg)',
        },
    },
    quote: {
        textAlign: 'left',
        width: '80%',
        margin: '0 auto',
    },
    user: {
        textAlign: 'right',
        width: '80%',
        margin: '0 auto',
    },
    recipesLink: {
        fontSize: '1.5em',
        margin: '2em',
    },
}));

const Lander = ({ data }) => {
    const classes = useStyles();
    const eventData = data.findContent.content.entries;

    const eventCard = (obj) => (
        <div className={classes.eventPanel} eventpanel="true" key={obj.venue.venue_name}>
            <Link to={obj.url} title={obj.venue.venue_name}>
                <img src={obj.images.logo.url}  alt={`Harry and David Hosted Dinners at ${obj.venue.venue_name}`} className={classes.eventCardLogo} />
                <span className={classes.venueName}>{obj.venue.venue_name}</span><br />
                {obj.venue.venue_location}<br />
                {obj.event_information.event_date}
                <div className={classes.cta}>Reserve Now!</div>
            </Link>
        </div>
    );

    const videoCard = () => (
        <div className={classes.eventPanel} eventpanel="true" key="virtual">
            <Link to="/hosted-dinners/medford-or/virtual-dinner" title="Harry and David Virtual Hosted Dinner">
                <img src="https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/bltdf6728c89a818e50/HD_hostedinner_white.svg" alt="Harry and David Virtual Hosted Dinner" />
                <strong>Experience a Virtual Dinner</strong><br />
                <div className={classes.cta}>WATCH NOW</div>
            </Link>
        </div>
    );

    return (
        <div className={classes.body} data-content="HD_contHostedDinners20200313">
            <div className={classes.hostedIntro}>
                <div className={classes.half}>
                    <img src="https://cdn1.harryanddavid.com/wcsstore/HarryAndDavid/images/hostedDinners/dinners-logo-tm.png" alt="Hosted Dinners" />
                </div>
                <div className={classes.half}>
                    <div className={classes.hostedText}>
                        <h1>Dinner of the Month<sup>®</sup></h1>
                        <p>
                            Enjoy a unique dining experience featuring seasonal
                            <br /> fruits and gourmet foods from Harry &amp; David
                        </p>
                    </div>
                </div>
            </div>

            <div className={classes.featured}>
                <img src="https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/bltf5bd1bccf53a45bd/6411fa657af6422f7a249f87/230303-HD-Hero-HostedDinners_1440x768.jpg" alt="gourmet dinner place setting" />
            </div>

            <div className={classes.hostedLoc}>
                <div className={classes.headingBreak}> Upcoming Events </div>
                {/* {!eventData && <h2 className={classes.hostedLoc}>More events are currently in the works</h2>} */}
                <h2 className={classes.hostedLoc}>More events are currently in the works</h2>

                <div className={classes.hostedRestWrap}>
                    {eventData.map((obj) => (
                        obj.video.video?.url
                            ? videoCard(obj)
                            : eventCard(obj)
                    ))}
                </div>

                <div className={classes.about}>
                    <div className={classes.hostedAboutText}>
                        <h2>ABOUT</h2>
                        <p>
                            Hosted dinners are thoughtfully curated events with custom menus
                            designed by the most talented chefs in the country. Each dinner
                            is an invitation to explore gourmet offerings from Harry &amp; David.
                            We work with carefully chosen partners who share our commitment
                            to excellence and who are as passionate as we are about using
                            quality ingredients and innovative recipes. Together, we create
                            memorable dinners, bringing people together to share their love
                            of good food and good company.
                        </p>
                    </div>

                </div>

                <div className={classes.hostedContact}> <span>For More Information</span>
                    <br />email <a href="mailto:HostedDinners@HarryandDavid.com">HostedDinners@HarryandDavid.com</a>
                </div>

            </div>

            <div className={classes.hostedLastEvent}>
                <div className={classes.hostedLastEventText}>
                    <Link to="/hosted-dinners-galleries">
                        <h2>Be part of the Hosted<br /> Dinner Experience ▸</h2>
                    </Link>
                    <div className={classes.hostedReviews}>
                        <div className={classes.starContainer}> <i className={classes.star} /> <i className={classes.star} /> <i className={classes.star} /> <i className={classes.star} /> <i className={classes.star} /> </div>
                    </div>
                    <div id="Testimonials">
                        <div id="Slideshow">
                            <div className={classes.quote}>&ldquo;My significant other and I decided to purchase tickets to the March 2018
                                dinner in
                                Ashland, OR, and were not disappointed. The food was outstanding and unique to Harry
                                &amp;
                                David while
                                providing a taste experience unlike many others. With the delightful atmosphere and
                                great conversation
                                at the large table setting, we both felt comfortable and fortunate to give this a shot.
                                Overall, I
                                recommend this dinner and venue.&rdquo;
                            </div>
                            <div className={classes.user}><i>- Dustin R.</i></div>
                        </div>
                    </div>
                    <div className={classes.recipesLink}>
                        See <Link to="/hosted-dinners-recipes">Recipes</Link> from our Hosted Dinners
                    </div>
                </div>
                <img src="https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/bltdd7b6077596c6dab/62269494225c6a0d139d8ced/carrots.jpg" alt="" />
            </div>
            <div id="wyng-experience" data-wyng-id="5a9890467981573c2fb2797a" data-wyng-token="qp1JPNrEZW4HPhMpFYZo7wp7aSaMZ7Wt0j89e9VS5a99f030d48c032379d62cf8" />
        </div>
    );
};

Lander.propTypes = {
    data: object.isRequired,
};

export default Lander;

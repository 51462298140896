/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from 'react';
import {
    string, bool, shape, array, object, number,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MovieIcon from '@material-ui/icons/Movie';
import ResponsiveImage from '../../GraphqlComponents/GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import IframeVideoModule from './IframeVideo';
import isIframeVideo from './isIframeVideo';
import HTML5 from './HTML5Video';

const styles = () => ({
    container: {
        width: '100%',
    },
    thumbnail: {
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        height: 'auto',
    },
    thumbnail_button: {
        color: 'rgba(255, 255, 255, .8);',
        '&:hover': {
            color: '#fff',
        },
        backgroundColor: 'unset',
        border: '0',
        opacity: '.9',
        padding: '0',
        cursor: 'pointer',
        '&::after': {
            content: '"\u25B6"',
            position: 'absolute',
            color: 'inherit',
            top: '35%',
            left: '0',
            right: '0',
            margin: '0 auto',
            width: '47px',
            height: '47px',
            border: 'solid 6px',
            borderRadius: '50%',
            fontWeight: '700',
            fontSize: '30px',
            textShadow: '1px 1px 2px rgba(0,0,0,0.4)',
            paddingLeft: '4px',
        },
    },
    fallbackThumb: {
        width: '100%',
        height: 'auto',
        maxHeight: '180px',
        marginBottom: '-3px',
        color: 'rgba(0,0,0,.6)',
    },
    thumbnail_image: {
        width: '100%',
        height: 'auto',
        marginBottom: '-3px',
    },
    thumbnail_button_active: {
        boxShadow: '0 3px 5px rgba(0, 0, 0, .5)',
        backgroundColor: 'unset',
        border: '0',
        padding: '0',
        opacity: '1',
        '&::after': {
            content: '"NOW PLAYING"',
            position: 'absolute',
            top: '50%',
            left: '0',
            right: '0',
            margin: '0 auto',
            fontFamily: 'Verdana, Arial, sans-serif',
            fontWeight: '700',
            fontSize: '1.2em',
            textShadow: '1px 1px 2px rgba(0,0,0,.8)',
            color: '#fff',
            letterSpacing: '1px',
        },
    },
});

export function MultiVideo({ classes, data }) {
    const [activeVid, setActiveVid] = useState(0);

    const toggle = (vid) => {
        if (activeVid  !== vid) setActiveVid(vid);
    };

    let videoArray = [];
    if (data) {
        const width = data.width ? `${data.width}` : '100%';
        const height = data.height ? `${data.height}` : '100%';
        videoArray = data.video_references;
        return (
            <div className={classes.container} style={{ maxWidth: `${width}px` }}>
                <Grid container>
                    <Grid item xs={12}>
                        { isIframeVideo(videoArray[activeVid])
                            ? <IframeVideoModule data={videoArray[activeVid]} width={width} height={height} play_location={data.play_location} autoplay={data.autoplay} />
                            : <HTML5 tracking={data?.tracking || {}} data={videoArray[activeVid]} width={width} height={height} play_location={data.play_location} autoplay={data.autoplay} /> }
                    </Grid>
                </Grid>
                <Grid justify="center" spacing={1} container>
                    {videoArray.map((vid, key) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid item xs={6} sm={3} key={key.toString()}>
                            <div className={classes.thumbnail}>
                                <button type="button" className={activeVid === key ? classes.thumbnail_button_active : classes.thumbnail_button} onClick={() => { toggle(key); }}>
                                    {
                                        vid?.image?.url
                                            ? <ResponsiveImage path={vid?.image?.url} alt={vid?.image?.description || 'Video Image'} className={classes.thumbnail_image} params={{ desktop: 'quality=100', tablet: 'quality=100', mobile: 'quality=100' }} />
                                            : <MovieIcon className={classes.fallbackThumb} />
                                    }
                                </button>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }
}

MultiVideo.propTypes = {
    data: shape({
        autoplay: bool,
        height: number,
        play_location: string,
        title: string,
        optional_reference: array,
        optional_panel_side: string,
        width: number,
        video_references: array,
    }).isRequired,
    classes: object.isRequired,
};
export default withStyles(styles)(MultiVideo);

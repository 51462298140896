/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string } from 'prop-types';

const Eventbrite = ({ url }) => {
    if (!url?.length) return <></>;
    const path = url.split('/').pop();
    const uid = path.split('-').pop();
    return (
        // eslint-disable-next-line react/self-closing-comp, jsx-a11y/iframe-has-title
        <iframe
            width="100%"
            height="100%"
            src={`https://www.eventbrite.com/checkout-external?eid=${uid}&parent=https%3A%2F%2Fwww.eventbrite.com%2Fe%2F${path}`}
            id={`eventbrite-widget-modal-${uid}`}
            frameBorder="0"
            scrolling="auto"
            allowtransparency="true"
            data-automation={`checkout-widget-iframe-${uid}`}
            aria-label="Eventbrite"
        >
        </iframe>
    );
};

Eventbrite.propTypes = {
    url: string.isRequired,
};

export default Eventbrite;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { object, string, shape } from 'prop-types';
import ReactMarkdown from 'react-markdown';

const styles = (theme) => ({
    headerContainer: {
        maxWidth: '980px',
        width: '85%',
        margin: '0 auto',
        fontSize: '18px',
        [theme.breakpoints.only('xs')]: {
            width: '100%',
        },
    },
    logo: {
        '& img': {
            maxWidth: '300px',
            margin: '0 auto',
        },
        textAlign: 'center',
        width: '100%',
    },
    headerParagraph: {
        '& h1,h2,h3,h4': {
            fontSize: 28,
            margin: '15px 0 5px 0',
            [theme.breakpoints.down('xs')]: {
                fontSize: 22,
            },
        },
        textAlign: 'center',
    },
});

const ShareMoreHeader = ({ classes, headerData }) => {
    const imageHeaderUrl = headerData?.header_logo?.url || '';
    const headerParagraph = headerData?.header_paragraph || '';
    return (
        <div className={classes.headerContainer}>
            <div className={classes.logo}>
                <img src={imageHeaderUrl} alt="heading" />
            </div>
            <div className={classes.headerParagraph}>
                <ReactMarkdown source={headerParagraph} escapeHtml={false} />
            </div>
        </div>
    );
};

ShareMoreHeader.propTypes = {
    classes: object.isRequired,
    headerData: shape({
        heading: string,
        image: shape({
            title: string,
            url: string,
        }),
    }),
};
ShareMoreHeader.defaultProps = {
    headerData: {},
};
export default withStyles(styles)(ShareMoreHeader);

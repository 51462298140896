/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Add, Remove } from '@material-ui/icons';
import {
    arrayOf,  shape, string,
} from 'prop-types';
import MobileHeroAccordionLinkList from './MobileHeroAccordionLinkList';
import getBackgroundColor from '../../../../../../helpers/getBackgroundColor';

const useStyles = makeStyles((theme) => ({
    accordion: {
        margin: '0px !important',
        '& svg': {
            color: theme.palette.cms?.white || '#fff',
            width: '20px',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '48px',
        },
    },
    summary: (data) => ({
        '& div': {
            margin: '0px !important',
            flexGrow: 'inherit',
            padding: 0,
        },
        backgroundColor: getBackgroundColor(data?.mobile_header?.background_color) || ' rgb(177, 55, 158)',
    }),
    accordionHeading: (data) => ({
        margin: 0,
        color: getBackgroundColor(data?.mobile_header?.color) || '#fff',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    }),
    accordionDetails: {
        padding: 0,
    },
    expandIcon: (data) => ({
        fill: getBackgroundColor(data?.mobile_header?.color) || '#fff',
    }),
}));
const MobileHeroAccordion = ({ data }) => {
    const [expandedData, setExpand] = useState(true);
    const classes = useStyles(data);
    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                className={classes.summary}
                expandIcon={expandedData ? <Add className={classes.expandIcon} /> : <Remove className={classes.expandIcon} />}
                aria-controls="panelcontent"
                id="panelheader"
                onClick={() => setExpand(!expandedData)}
            >
                <p className={classes.accordionHeading}>{data?.mobile_header?.heading || 'Categories' }</p>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                <MobileHeroAccordionLinkList linkList={data} />
            </AccordionDetails>
        </Accordion>
    );
};
MobileHeroAccordion.propTypes = {
    data: shape({
        font_family: string.isRequired,
        link: arrayOf(shape({
            title: string.isRequired,
            href: string.isRequired,
        })).isRequired,
    }).isRequired,
};

export default MobileHeroAccordion;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import {
    shape, string, arrayOf,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';

const useStyles = makeStyles((theme) => ({
    brands: {
        margin: '50px auto 0',
        maxWidth: '1440px',
        display: 'flex',
        [theme.breakpoints.down(700)]: {
            display: 'block',
            margin: '15px auto -300px',
            textAlign: 'center',
        },
    },
    brandsCopy: {
        flex: '0 0 60%',
        margin: '0 1% 20px 5%',
        textAlign: 'left',
        fontFamily: 'PlayfairDisplay, serif',
        fontSize: '1.5em',
        '& p': {
            margin: '0 auto',
        },
        '& p:nth-child(2)': {
            fontSize: '2em',
            lineHeight: '0.8',
            margin: '10px 0',
        },
        '& p:nth-child(3)': {
            fontSize: '10px',
        },
        objectFit: 'contain',
        '& img': {
            width: '100%',
        },
        [theme.breakpoints.down('md')]: {
            '& p': {
                fontSize: '1em',
            },
            '& p:nth-child(2)': {
                fontSize: '1.25em',
                lineHeight: 'normal',
            },
        },
        [theme.breakpoints.down(700)]: {
            margin: '30px auto',
            maxWidth: '90%',
            textAlign: 'center',
            fontFamily: 'PlayfairDisplay, serif',
            fontSize: '18px',
            '& p:nth-child(2)': {
                fontSize: '1.8em',
                lineHeight: '0.85',
            },
            '& img': {
                margin: '20px auto',
            },
        },
    },
    brandSubtitleAsterisk: {
        verticalAlign: 'top',
        fontSize: '20px',
        [theme.breakpoints.down('md')]: {
            fontSize: '18px',
        },
        [theme.breakpoints.down(700)]: {
            fontSize: '16px',
        },
    },
    brandsDescription: {
        maxWidth: '70%',
        marginLeft: '0 !important',
        [theme.breakpoints.down(900)]: {
            fontSize: '1em',
            lineHeight: '20px',
        },
        [theme.breakpoints.down(700)]: {
            maxWidth: '100%',
        },
    },
    brandSideImage: {
        objectFit: 'contain',
        flex: '0 0 30%',
        margin: '0 4% -200px auto',
        '& img': {
            maxWidth: '100%',
        },
        [theme.breakpoints.down(900)]: {
            '& img': {
                width: '90%',
            },
        },
        [theme.breakpoints.down(700)]: {
            '& img': {
                width: '80%',
            },
        },
    },
}));

const MarketplaceLPBrands = ({ block }) => {
    const isMobile = useSelector(getSSRDeviceType) === 'mobile';

    const classes = useStyles();

    return (
        <div className={classes.brands}>
            <div className={classes.brandsCopy}>
                <p>{block?.title}</p>
                <p>{block?.subtitle}<span className={classes.brandSubtitleAsterisk}>*</span></p>
                <p>{block?.subtitle_note}</p>
                <img src={isMobile ? block?.brands_images_mobile?.[0]?.image.url : block?.brands_images_desktop?.[0]?.image.url} alt={block?.title} />
                <p className={classes.brandsDescription}>{block?.copy}</p>
            </div>
            <div className={classes.brandSideImage}>
                <img src={block?.brand_side_image?.url} alt={block?.title} />
            </div>
        </div>
    );
};

MarketplaceLPBrands.propTypes = {
    block: shape({
        title: string,
        subtitle: string,
        brands_images_mobile: arrayOf(shape({
            image: shape({
                url: string,
            }),
        })),
        brands_images_desktop: arrayOf(shape({
            image: shape({
                url: string,
            }),
        })),
        copy: string,
        brand_side_image: shape({
            url: string,
        }),
    }).isRequired,
};

export default MarketplaceLPBrands;

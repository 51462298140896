/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import {
    string, bool, shape,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getSSRDeviceType } from '../../../../../../../state/ducks/App/App-Selectors';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const useStyles = makeStyles((theme) => ({
    sellersHeroWrapper: {
        maxWidth: '1400px',
        margin: '0 auto',
    },
    sellersHero: {
        maxWidth: '1440px',
        margin: '0 auto',
        display: 'flex',
        textDecoration: 'none',
        [theme.breakpoints.down(600)]: {
            display: 'block',
        },
    },
    sellersImage: {
        objectFit: 'contain',
        flex: '0 0 50%',
        maxWidth: '50%',
        '& img': {
            width: '100%',
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: '100%',
            marginBottom: '-5px',
        },
    },
    sellersCopyContainer: {
        marginBottom: '5px',
        backgroundColor: '#C6E6C5',
        flex: '0 0 50%',
        maxWidth: '50%',
        [theme.breakpoints.down(600)]: {
            padding: '15px 0',
            maxWidth: '100%',
        },
    },
    sellersCopy: ({ styles }) => ({
        margin: '15% auto auto',
        maxWidth: '80%',
        textDecoration: 'none',
        textAlign: 'center',
        fontFamily: 'PlayfairDisplayBold, serif',
        '& p:nth-child(1)': {
            color: styles.titleColor || '#ffffff',
            fontSize: styles.titleFontSize || '3em',
            margin: '0 auto',
        },
        '& p:nth-child(2)': {
            color: styles.subtitleColor || '#000000',
            fontSize: styles.subtitleFontSize || '1.5em',
            margin: '10px auto 30px',
        },
        '& div': {
            color: theme.palette?.black || '#000000',
            textDecoration: styles.ctaTextDecoration || 'none',
            display: 'inline-block',
        },
        '& span': {
            display: 'block',
            maxWidth: '200px',
            margin: '0 auto',
            fontFamily: 'LatoBold, sans-serif',
            textTransform: styles.ctaTextTransform || 'uppercase',
            letterSpacing: '2px',
            fontSize: '16px',
            color: styles.ctaCopyColor || '#000000',
            backgroundColor: styles.ctaBgColor || '#ffffff',
            padding: '10px 25px',
        },
        [theme.breakpoints.down('md')]: {
            margin: '13% auto auto',
            fontSize: '1em',
            '& p:nth-child(1)': {
                fontSize: '2.5em',
            },
            '& p:nth-child(2)': {
                fontSize: '1.3em',
                margin: '10px auto',
            },
        },
        [theme.breakpoints.down(920)]: {
            margin: '11% auto auto',
            '& p:nth-child(1)': {
                fontSize: '2em',
            },
            '& p:nth-child(2)': {
                fontSize: '1.1em',
            },
        },
        [theme.breakpoints.down(800)]: {
            '& p:nth-child(2)': {
                fontSize: '1em',
            },
        },
        [theme.breakpoints.down(700)]: {
            height: '70%',
            margin: '10% auto',
            maxWidth: '80%',
            '& p:nth-child(1)': {
                lineHeight: '34px',
                fontSize: '1.8em',
                margin: '0 5%',
                [theme.breakpoints.down('325')]: {
                    margin: '0 auto',
                    fontSize: '1.65em',
                },
            },
            '& p:nth-child(2)': {
                fontSize: '0.9em',
                margin: '5px auto 10px',
            },
            '& div': {
                maxWidth: '170px',
                letterSpacing: '1px',
                fontSize: '14px',
                color: '#000',
                padding: '5px 15px',
            },
        },
    }),
}));

const HeroVerticallySplit = ({ data, ssrDeviceType }) => {
    if (!data) {
        return null;
    }
    const isMobile = ssrDeviceType === 'mobile';

    const styles = {
        titleColor: data?.main_copy?.title_color?.color,
        titleFontSize: data?.main_copy?.title_font_size,
        subtitleColor: data?.main_copy?.subtitle_color?.color,
        subtitleFontSize: data?.main_copy?.subtitle_font_size,
        ctaCopyColor: data?.main_copy?.cta?.copy_color?.color,
        ctaBgColor: data?.main_copy?.cta?.background_color?.color,
        ctaTextDecoration: data?.main_copy?.cta?.text_decoration,
        ctaTextTransform: data?.main_copy?.cta?.text_transform,
    };
    const classes = useStyles({ styles });

    const imageData = isMobile ? data?.main_images.mobile_image : data?.main_images?.image;
    const SEOAltText = data?.main_images?.seo_alt_text || imageData?.title;

    const copyData = data?.main_copy;
    const CTAData = data?.main_copy?.cta;

    const title = copyData?.title || '';
    const subTitle = copyData?.subtitle || '';
    const linkTitle = CTAData?.link?.title || '';

    const ariaLabelFromTextContent = () => `${title} ${subTitle} ${linkTitle}`;

    return (
        <div className={classes.sellersHeroWrapper}>
            <LinkOrATag href={CTAData?.link?.href} trackingEventLabel={linkTitle} isTrackEventEnabled={CTAData?.is_ga_trackevent_enabled} title={SEOAltText} className={classes.sellersHero} ariaLabel={ariaLabelFromTextContent()}>
                <div className={classes.sellersImage}>
                    <img src={imageData?.url} alt={imageData?.title} />
                </div>
                <div className={classes.sellersCopyContainer}>
                    <div className={classes.sellersCopy}>
                        <p>{title}</p>
                        <p>{subTitle}</p>
                        <div aria-label={linkTitle} className={classes.ctaButton}>
                            <span>{linkTitle}</span>
                        </div>
                    </div>
                </div>
            </LinkOrATag>
        </div>
    );
};

HeroVerticallySplit.propTypes = {
    data: shape({
        main_images: {
            image: {
                url: string,
                title: string,
            },
            mobile_image: {
                url: string,
                title: string,
            },
        },
        main_copy: {
            copy_background_color: {
                color: string,
            },
            svg: {
                url: string,
                title: string,
            },
            mobile_svg: {
                url: string,
                title: string,
            },
            position_top: string,
            title: string,
            title_font_size: string,
            title_color: shape({
                color: string,
            }),
            title_letter_spacing: string,
            subtitle: string,
            subtitle_font_size: string,
            subtitle_color: shape({
                color: string,
            }),
            cta: shape({
                is_ga_trackevent_enabled: bool,
                link: shape({
                    title: string,
                    href: string,
                }),
                font_size: string,
                font_weight: string,
                text_decoration: string,
                text_transform: string,
                copy_color: shape({
                    color: string,
                }),
                background_color: shape({
                    color: string,
                }),
            }),
        },
    }),
    ssrDeviceType: string.isRequired,
};

HeroVerticallySplit.defaultProps = {
    data: {},
};

const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
});

export default connect(mapStateToProps, null)(HeroVerticallySplit);

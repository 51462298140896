/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    arrayOf,
    bool,
    object, shape, string,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import Video from '../../../../ContentTypeComponents/Video/Video';

const styles = (theme) => ({
    mainNewsAndMediaConatiner: {
        width: '100%',
        padding: '0px 0px 30px 0px',
        borderBottom: `1px solid ${theme.palette.cms?.borderColor || '#ccc'}`,
    },
    mainNewsHeading: {
        margin: '5px 0',
    },
    subNewsHeading: {
        fontSize: 16,
        fontWeight: 'bold',
        '& h1,h2,h3,h4,h5,h6,p': {
            margin: '5px 0',
        },
    },
    mainNewsTextConatiner: {
        paddingRight: 46,
    },
    newsCopy: {
        fontSize: 20,
    },

});

const NewsMediaBannerBlock = ({
    classes, videoBlock, newsBannerText,
}) => {
    const newsTextData = newsBannerText?.news_block || {};
    const font_family = newsTextData?.font_family ? newsTextData?.font_family.replace(/font-family: |;/g, '') : null;
    let headingStyle = {};
    let subHeadingStyle = {};
    let mainContainerStyle = {};

    headingStyle = {
        fontSize: newsTextData?.heading_font_size || 38,
        color: newsTextData?.heading_color?.color || '#000000',
    };
    subHeadingStyle = {
        color: newsTextData?.sub_heading_color?.color || '#999999',
    };
    mainContainerStyle = {
        fontFamily: font_family || 'Helvetica, sans-serif',
        textAlign: newsTextData?.text_alignment || 'left',
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                className={classes.mainNewsAndMediaConatiner}
            >
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div style={mainContainerStyle} className={classes.mainNewsTextConatiner}>
                            <h1 style={headingStyle} className={classes.mainNewsHeading}>{newsTextData?.heading}</h1>
                            <div style={subHeadingStyle}><ReactMarkdown className={classes.subNewsHeading} source={newsTextData?.sub_heading} escapeHtml={false} /></div>
                            <div className={classes.newsCopy}>
                                {newsTextData?.copy}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Video data={videoBlock} classes={classes} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

NewsMediaBannerBlock.propTypes = {
    classes: object.isRequired,
    videoBlock: shape({
        autoplay: bool.isRequired,
        heading: bool.isRequired,
        play_location: string.isRequired,
        video_references: arrayOf(shape({
            allow_fullscreen: bool.isRequired,
            video_id: string.isRequired,
            image: shape({
                url: string.isRequired,
                title: string.isRequired,
            }),
        })),
    }),
    newsBannerText: shape({
        news_block: shape({
            copy: string.isRequired,
            title: string.isRequired,
            font_family: string.isRequired,
            heading: string.isRequired,
            heading_color: shape({
                color: string.isRequired,
            }),
            sub_heading: string.isRequired,
        }),
    }),
};
NewsMediaBannerBlock.defaultProps = {
    videoBlock: {},
    newsBannerText: {},
};
export default withStyles(styles)(NewsMediaBannerBlock);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    arrayOf, func, object, shape, string,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { useUIDSeed } from 'react-uid';

const styles = (theme) => ({
    mainEventContainer: {
        margin: '5px 0',
    },
    eventMainHeading: {
        backgroundColor: theme.palette.cms?.hostedHeaderBgColor || '#EBEBDF',
        padding: '8px 0',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 22,
    },
    headerMainCon: {
        margin: '50px 5px 20px 5px',
        [theme.breakpoints.down('xs')]: {
            margin: '30px 5px 20px 5px',
        },
    },
    innerBlock: {
        flex: '0 0 20%',
        maxWidth: '20%',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flex: '0 0 100%',
            maxWidth: '100%',
        },
        '& a': {
            textDecoration: 'none',
            display: 'block',
            '&:hover': {
                opacity: 0.8,
            },
        },
    },
    logo: {
        width: '75%',
        margin: '16px auto 20px',
        minHeight: 68,
        '& img': {
            width: '100%',
        },
    },
    eventImage: {
        position: 'relative',
        '& img': {
            width: '100%',
            minHeight: 244,
            objectFit: 'cover',
            display: 'block',
        },
    },
    imageTextOverlap: {
        position: 'absolute',
        bottom: 0,
        color: theme.palette.cms?.black || '#333',
        left: 0,
        right: 0,
        margin: '0 auto',
        display: 'block',
        width: '80%',
        backgroundColor: theme.palette.cms?.hostedHeaderBgColor || '#EBEBDF',
        opacity: 0.9,
        padding: '8px 0',
        textAlign: 'center',
    },
    subTitle: {
        color: theme.palette.cms?.white || '#fff',
        textAlign: 'center',
        minHeight: 50,
    },
    btnTextTitle: {
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        background: theme.palette.cms?.white || '#fff',
        color: theme.palette.cms?.black || '#333',
        padding: 10,
        marginTop: 10,
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            fontWeight: 600,
            fontSize: 20,
            marginTop: 0,
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            top: -4,
            right: -4,
            bottom: -4,
            left: -4,
            border: `1px solid ${theme.palette.cms?.white || '#fff'}`,
        },
    },
});

const HostedDinnerEvents = ({ classes, eventData, setFontFamily }) => {
    const eventMainHeader = eventData?.heading || '';
    const fontString = eventData?.font_family || 'LatoRegular, Arial, Verdana, sans-serif';
    const seed = useUIDSeed();
    return (
        <Grid container className={classes.mainEventContainer} style={setFontFamily(fontString)}>
            <Grid item xs={12} lg={12}>
                <div className={classes.eventMainHeading}>
                    {eventMainHeader}
                </div>
            </Grid>
            {eventData?.event?.map((item) => (
                <div key={seed(item)} className={classes.innerBlock}>
                    <a href={item?.link?.link?.href}>
                        <div className={classes.headerMainCon}>
                            <div className={classes.logo}>
                                <img src={item?.logo?.url} alt={item?.logo?.title} />
                            </div>
                            <div className={classes.eventImage}>
                                <img src={item?.event_image?.url} alt={item?.event_image?.title} />
                                {item?.title && (
                                    <span className={classes.imageTextOverlap}>
                                        {item?.title}
                                    </span>
                                )}
                            </div>
                            <div className={classes.subTitle}>
                                <ReactMarkdown source={item?.sub_title} escapeHtml={false} />
                            </div>
                            <div className={classes.btnTextTitle}>
                                {item?.link?.link?.title}
                            </div>
                        </div>
                    </a>
                </div>
            ))}
        </Grid>
    );
};

HostedDinnerEvents.propTypes = {
    classes: object.isRequired,
    setFontFamily: func.isRequired,
    eventData: shape({
        heading: string,
        event: arrayOf(shape({
            sub_title: string,
            title: string,
            event_image: shape({
                title: string,
                url: string,
            }),
            link: shape({
                link: shape({
                    href: string,
                    title: string,
                }),
            }),
            logo: shape({
                title: string,
                url: string,
            }),
        })),
    }),
};
HostedDinnerEvents.defaultProps = {
    eventData: {},
};
export default withStyles(styles)(HostedDinnerEvents);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import { useUIDSeed } from 'react-uid';
import {
    shape, string, arrayOf, object,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { Collapse } from '@material-ui/core';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    faqContainer: (style) => ({
        margin: '-50px auto 0',
        maxWidth: '100%',
        padding: style?.padding || '0 0 50px',
        backgroundColor: style?.backgroundColor || '#e3f3e3',
        textAlign: 'left',
        listStyle: 'none',
        [theme.breakpoints.down(700)]: {
            margin: '0 auto',
            padding: '0 0 20px',
        },
    }),
    faqTitleContainer: (style) => ({
        margin: '0 auto',
        paddingTop: '70px',
        maxWidth: '900px',
        '& p:nth-child(1)': {
            margin: '0 1%',
            fontSize: '24px',
            fontFamily: 'latobold, serif',
            textDecoration: 'underline',
            textTransform: 'uppercase',
        },
        '& p:nth-child(2)': {
            margin: '0 1%',
            fontSize: '24px',
            fontFamily: style?.fontFamily || 'PlayfairDisplayBold, serif',
        },
        [theme.breakpoints.down(700)]: {
            maxWidth: '90%',
            margin: style?.margin || '20px auto 0',
            paddingTop: '50px',
        },
    }),
    faqListContainer: {
        maxWidth: '900px',
        margin: '0 auto',
        [theme.breakpoints.down(700)]: {
            maxWidth: '90%',
        },
    },
    faqList: {
        margin: '0 1%',
        listStyle: 'none !important',
        padding: '0',
    },
    faqItem: {
        paddingBottom: '10px',
        borderBottom: '1px solid #000',
        '& p': {
            margin: '0 0 0 auto',
            fontFamily: 'latolight, serif',
        },
    },
    faqQuestion: {
        margin: '20px auto',
        display: 'flex',
        '& p': {
            margin: '0 auto 0 0',
            fontFamily: 'latomedium, serif',
        },
        '& span': {
            margin: '0 0 0 auto',
            cursor: 'pointer',
        },
    },
}));

const MarketplaceLPFAQs = ({ block, style }) => {
    const [faqCollapsedStatus, setFaqCollapsedStatus] = useState({});
    const handleFaqCollapse = (question) => {
        setFaqCollapsedStatus((prevState) => ({ ...prevState, [question]: !prevState[question] }));
    };
    useEffect(() => {
        const faqObject = block.faq_list.reduce((acc, elem) => ({ ...acc, [elem.question]: false }), {});
        setFaqCollapsedStatus(faqObject);
    }, [block]);

    const classes = useStyles(style);
    const seed = useUIDSeed();

    const marketplaceFAQSchema = (faqData) => (
        <Helmet>
            <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [
                        ${faqData && faqData.map((faq) => (`{
                            "@type": "Question",
                            "name": "${faq?.question}",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "${faq?.answer}"
                            }
                        }`))}
                    ]
                }`}
            </script>
        </Helmet>
    );

    return (
        <div className={classes.faqContainer}>
            {marketplaceFAQSchema(block?.faq_list)}
            <div className={classes.faqTitleContainer}>
                <p>{block?.title}</p>
                <p>{block?.subtitle}</p>
            </div>
            <div className={classes.faqListContainer}>
                <ul className={classes.faqList}>
                    {block?.faq_list?.map((item) => (
                        <li className={classes.faqItem} key={seed(item)}>
                            <div className={classes.faqQuestion}>
                                <ReactMarkdown key={item.question} source={item.question} escapeHtml={false} />
                                <span role="button" onKeyPress={() => {}} tabIndex={0} onClick={() => { handleFaqCollapse(item.question); }}>{faqCollapsedStatus[item.question] ? <>&#8212;</> : <>&#65291;</>}</span>
                            </div>
                            <Collapse in={faqCollapsedStatus[item.question]} timeout="auto">
                                <ReactMarkdown key={item.answer} source={item.answer} escapeHtml={false} />
                            </Collapse>

                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

MarketplaceLPFAQs.propTypes = {
    block: shape({
        title: string,
        subtitle: string,
        faq_list: arrayOf(shape({
            question: string,
            answer: string,
        })),
    }).isRequired,
    style: object,
};

MarketplaceLPFAQs.defaultProps = {
    style: {},
};

export default MarketplaceLPFAQs;

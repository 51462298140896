/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    arrayOf, func, object, shape, string,
} from 'prop-types';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import { useUIDSeed } from 'react-uid';

const styles = (theme) => ({
    mainContainerTestimonials: {
        marginTop: 8,
    },
    testimonialsHeader: {
        backgroundColor: theme.palette.cms?.hostedHeaderBgColor || '#EBEBDF',
        padding: '8px 0',
        textAlign: 'center',
        textTransform: 'uppercase',
        '& span': {
            fontSize: 22,
        },
        '& p': {
            fontSize: 14,
            textTransform: 'none',
            margin: 4,
        },
    },
    testimonialsImageBanner: {
        '& img': {
            width: '100%',
            display: 'block',
        },
    },
    testimonialsSlider: {
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
    },
    testimonialSliderText: {
        color: theme.palette.cms?.white || '#ffffff',
        fontSize: 34,
        textAlign: 'center',
        paddingTop: '3em',
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    testimonialStar: {
        fontSize: 42,
        color: theme.palette.cms?.white || '#ffffff',
        '&:before': {
            right: 5,
            width: '30%',
            height: 1,
            content: '""',
            display: 'inline-block',
            position: 'relative',
            marginLeft: '-50%',
            verticalAlign: 'middle',
            backgroundColor: theme.palette.cms?.white || '#ffffff',
        },
        '&:after': {
            left: 5,
            width: '30%',
            height: 1,
            content: '""',
            display: 'inline-block',
            position: 'relative',
            marginRight: '-50%',
            verticalAlign: 'middle',
            backgroundColor: theme.palette.cms?.white || '#ffffff',
        },
        '& span': {
            padding: 4,
        },
    },
    sliderMainTextDiv: {
        color: theme.palette.cms?.white || '#ffffff',
        '&:focus': {
            outline: 'none',
        },
    },
    sliderContainer: {
        position: 'relative',
        maxWidth: 1400,
        margin: 'auto',
        '& .slick-list': {
            overflow: 'hidden',
        },
        '& .slick-slide': {
            display: 'table-cell',
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
        '& .slick-current': {
            paddingRight: 5,
        },
        '& .slick-dots': {
            listStyle: 'none',
            display: 'flex !important',
            padding: '0',
            justifyContent: 'center',
            margin: '0',
            '& li': {
                lineHeight: 1,
            },
            '& .slick-active': {
                '& div': {
                    background: theme.palette.cms?.white || 'rgb(47, 47, 47)',
                },
            },
            '& .slick-current': {
                '& div': {
                    paddingRight: 5,
                },
            },
        },
    },
    dotNavigation: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        background: theme.palette.cms?.white || '#fff',
        border: `${'1px solid'} ${theme.palette.cms?.white || 'rgb(47, 47, 47)'}`,
        marginRight: 5,
    },
    authorName: {
        textAlign: 'right',
    },
});

const HostedDinnerTestimonials = ({ classes, testimonialData, setFontFamily }) => {
    const testimonialHeading = testimonialData?.heading || '';
    const testimonial_subheading = testimonialData?.sub_heading || '';
    const testimonial_image = testimonialData?.image?.url || '';
    const testimonial_imageTitle = testimonialData?.image?.title;
    const testimonial_heading = testimonialData?.testimonial_heading_link?.title || '';
    const testimonials = testimonialData?.testimonials;
    const fontString = testimonialData?.font_family || 'LatoRegular, Arial, Verdana, sans-serif';
    const seed = useUIDSeed();
    return (
        <Grid container className={classes.mainContainerTestimonials} style={setFontFamily(fontString)}>
            <Grid item xs={12} lg={12}>
                <div className={classes.testimonialsHeader}>
                    <span>{testimonialHeading}</span>
                    <ReactMarkdown source={testimonial_subheading} escapeHtml={false} />
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className={classes.testimonialsSlider}>
                    <div className={classes.testimonialSliderText}>
                        {testimonial_heading}
                    </div>
                    <span className={classes.testimonialStar}>
                        <span>&#9733;</span><span>&#9733;</span><span>&#9733;</span><span>&#9733;</span><span>&#9733;</span>
                    </span>
                    <div>
                        <Slider
                            dots={testimonials?.length > 1}
                            infinite
                            autoplaySpeed={2000}
                            arrows={false}
                            autoplay
                            fade
                            cssEase="ease-in-out"
                            slidesToShow={1}
                            slidesToScroll={1}
                            dotsClass="slick-dots slick-thumb"
                            className={`carousel-container ${classes.sliderContainer}`}
                            customPaging={() => <div className={classes.dotNavigation} />}
                        >
                            {testimonials?.map((item) => (
                                <div key={seed(item)} className={classes.sliderMainTextDiv}>
                                    <ReactMarkdown source={item?.text} escapeHtml={false} />
                                    <div className={classes.authorName}>{item?.author_name}</div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} lg={6}>
                <div className={classes.testimonialsImageBanner}>
                    <img src={testimonial_image} alt={testimonial_imageTitle} />
                </div>
            </Grid>
        </Grid>
    );
};

HostedDinnerTestimonials.propTypes = {
    classes: object.isRequired,
    setFontFamily: func.isRequired,
    testimonialData: shape({
        heading: string,
        sub_heading: string,
        image: shape({
            title: string,
            url: string,
        }),
        testimonials: arrayOf(shape({
            author_name: string,
            text: string,
        })),
    }),
};
HostedDinnerTestimonials.defaultProps = {
    testimonialData: {},
};
export default withStyles(styles)(HostedDinnerTestimonials);

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { makeStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import ResponsiveImage from '../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import LinkOrATag from '../../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const useStyles = makeStyles((theme) => ({
    imageBlock: {
        '& img': {
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    title: {
        fontSize: 24,
        textDecoration: 'underline',
        textUnderlineOffset: '6px',
    },
    subTitle: {
        fontSize: 14,
        padding: '15px 0px 30px',
    },
    ctaBtn: {
        padding: '5px 25px',
        textDecoration: 'none',
        fontSize: 14,
    },
    sliderOuterContainer: {
        width: '100%',
        display: 'inline-block',
        flexWrap: 'wrap',
    },
    sliderContainer: {
        position: 'relative',
        maxWidth: '1400px',
        margin: 'auto',
        '& .slick-list': {
            overflow: 'hidden',
            background: theme.palette.cms?.grey || '#EBEBEB',
        },
        '& .slick-slide': {
            display: 'inline-block',
            verticalAlign: 'top',
            overflow: 'hidden',
        },
        '& .slick-dots': {
            listStyle: 'none',
            display: 'flex !important',
            padding: '0px',
            justifyContent: 'center',
            margin: '0',
            bottom: '20px',
            left: 0,
            right: 0,
            position: 'absolute',
            '& li': {
                lineHeight: 1,
                padding: '0px 5px',
            },
            '& .slick-active': {
                '& div': {
                    background: theme.palette.cms?.black || '#000000',
                },
            },
            '& .slick-current': {
                '& div': {
                    paddingRight: 5,
                },
            },
        },
    },
    dotNavigation: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'inline-block',
        background: theme.palette.cms?.grey || 'grey',
        border: `${'1px solid'} ${theme.palette.cms?.grey || 'grey'}`,
        marginRight: 5,
    },
    contentBlock: {
        padding: '25px 25px 55px',
        textAlign: 'center',
    },
}));

const ConsumerLPBlogMobile = ({ data }) => {
    if (!data) return null;
    const classes = useStyles();
    let styleOverrides = {};
    let ctaStyle = {};
    const seed = useUIDSeed();
    return (
        <>
            <div className={classes.sliderOuterContainer}>
                <Slider
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    arrows={false}
                    dots
                    dotsClass="slick-dots slick-thumb"
                    className={`carousel-container ${classes.sliderContainer}`}
                    customPaging={() => <div className={classes.dotNavigation} />}
                >
                    {
                        data?.map((blog) => {
                            styleOverrides = {
                                background: blog?.background_color?.color,
                            };
                            ctaStyle = {
                                background: blog?.cta_background_color?.color,
                                color: blog?.cta_text_color?.color,

                            };
                            return (
                                <div key={seed(blog)}>
                                    <div className={classes.imageBlock}>
                                        <ResponsiveImage
                                            path={blog?.mobile_image?.url}
                                            alt={blog?.title}
                                            params={{}}
                                        />
                                    </div>
                                    <div style={styleOverrides}>
                                        <div className={classes.contentBlock}>
                                            <div className={classes.title}>{blog?.title}</div>
                                            <div className={classes.subTitle}>{blog?.sub_title}</div>
                                            <LinkOrATag ariaLabel={blog?.cta_button?.title} style={ctaStyle} href={blog?.cta_button?.href} className={classes.ctaBtn} isTrackEventEnabled trackingEventLabel={blog?.cta_button?.title}>
                                                {blog?.cta_button?.title}
                                            </LinkOrATag>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </Slider>
            </div>
        </>
    );
};

ConsumerLPBlogMobile.propTypes = {
    data: arrayOf(shape({
        cta_button: shape({
            href: string,
            title: string,
        }),
        mobile_image: shape({
            url: string,
        }),
        title: string,
        sub_title: string,
    })).isRequired,
};

export default ConsumerLPBlogMobile;
